export const sectionUrls = {
  'real-world-effectiveness-of-pharmacologic-treatments-for-bipolar-disorder-2788-5656': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-no-mundo-real-dos-tratamentos-farmacologicos-para-o-transtorno-bipolar/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-en-situation-reelle-des-traitements-pharmacologiques-du-trouble-bipolaire/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-nel-mondo-reale-dei-trattamenti-farmacologici-per-il-disturbo-bipolare/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-pharmakologischer-behandlungen-bei-bipolarer-stoerung-in-der-praxis/',
  },
  'psychiatric-disorders-and-subsequent-risk-of-cardiovascular-disease-2788-5655': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/transtornos-psiquiatricos-e-risco-subsequente-de-doencas-cardiovasculares/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/troubles-psychiatriques-et-risque-ulterieur-de-maladie-cardiovasculaire/',
    it: 'https://it.psychopharmacologyinstitute.com/section/disturbi-psichiatrici-e-conseguente-rischio-di-malattie-cardiovascolari/',
    de: 'https://de.psychopharmacologyinstitute.com/section/psychiatrische-stoerungen-und-spaeteres-risiko-fuer-herz-kreislauf-erkrankungen/',
  },
  'efficacy-of-memantine-in-treating-trichotillomania-and-skin-picking-disorder-2788-5654': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-da-memantina-no-tratamento-da-tricotilomania-e-do-transtorno-de-arrancar-a-pele/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-de-la-memantine-dans-le-traitement-de-la-trichotillomanie-et-du-trouble-de-la-cueillette-de-la-peau/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-della-memantina-nel-trattamento-della-tricotillomania-e-del-disturbo-da-pizzicamento-della-pelle/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-von-memantin-bei-der-behandlung-von-trichotillomanie-und-skin-picking-stoerung/',
  },
  'efficacy-of-low-dose-risperidone-for-postsurgical-delirium-in-elderly-patients-2788-5653': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-da-risperidona-em-baixa-dose-para-delirium-pos-cirurgico-em-pacientes-idosos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-de-la-risperidone-a-faible-dose-pour-le-delirium-post-chirurgical-chez-les-patients-ages/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-del-risperidone-a-basso-dosaggio-per-il-delirium-post-chirurgico-nei-pazienti-anziani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-von-niedrig-dosiertem-risperidon-bei-postoperativem-delirium-bei-aelteren-patienten/',
  },
  'cariprazine-for-the-adjunctive-treatment-of-major-depressive-disorder-2788-5652': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/cariprazina-para-o-tratamento-adjuvante-do-transtorno-depressivo-maior/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/cariprazine-pour-le-traitement-dappoint-du-trouble-depressif-majeur/',
    it: 'https://it.psychopharmacologyinstitute.com/section/cariprazina-per-il-trattamento-aggiuntivo-del-disturbo-depressivo-maggiore/',
    de: 'https://de.psychopharmacologyinstitute.com/section/cariprazin-zur-ergaenzenden-behandlung-von-schweren-depressiven-stoerungen/',
  },
  'prevalence-of-neuroradiological-abnormalities-in-first-episode-psychosis-2778-5627': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/prevalencia-de-anormalidades-neurorradiologicas-na-psicose-de-primeiro-episodio/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/prevalence-des-anomalies-neuroradiologiques-dans-le-premier-episode-de-psychose/',
    it: 'https://it.psychopharmacologyinstitute.com/section/prevalenza-di-anomalie-neuroradiologiche-nella-psicosi-di-primo-episodio/',
    de: 'https://de.psychopharmacologyinstitute.com/section/praevalenz-von-neuroradiologischen-anomalien-bei-psychosen-in-der-ersten-episode/',
  },
  'medical-cannabis-in-the-treatment-of-parkinsons-disease-2778-5626': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/cannabis-medicinal-no-tratamento-da-doenca-de-parkinson/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/le-cannabis-medical-dans-le-traitement-de-la-maladie-de-parkinson/',
    it: 'https://it.psychopharmacologyinstitute.com/section/la-cannabis-medica-nel-trattamento-della-malattia-di-parkinson/',
    de: 'https://de.psychopharmacologyinstitute.com/section/medizinisches-cannabis-bei-der-behandlung-der-parkinson-krankheit/',
  },
  'comparative-safety-of-oral-antipsychotics-for-adverse-events-in-adults-after-surgery-2778-5625':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/seguranca-comparativa-de-antipsicoticos-orais-para-eventos-adversos-em-adultos-apos-a-cirurgia/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/securite-comparative-des-antipsychotiques-oraux-pour-les-evenements-indesirables-chez-les-adultes-apres-une-intervention-chirurgicale/',
      it: 'https://it.psychopharmacologyinstitute.com/section/sicurezza-comparativa-degli-antipsicotici-orali-per-gli-eventi-avversi-negli-adulti-dopo-un-intervento-chirurgico/',
      de: 'https://de.psychopharmacologyinstitute.com/section/vergleichende-sicherheit-von-oralen-antipsychotika-in-bezug-auf-unerwuenschte-ereignisse-bei-erwachsenen-nach-operationen/',
    },
  'long-term-efficacy-of-antipsychotic-drugs-in-adults-with-schizophrenia-2778-5624': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-a-longo-prazo-de-medicamentos-antipsicoticos-em-adultos-com-esquizofrenia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-a-long-terme-des-medicaments-antipsychotiques-chez-les-adultes-atteints-de-schizophrenie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-a-lungo-termine-dei-farmaci-antipsicotici-negli-adulti-con-schizofrenia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/langfristige-wirksamkeit-von-antipsychotika-bei-erwachsenen-mit-schizophrenie/',
  },
  'insomnia-symptoms-and-the-risk-of-stroke-in-the-health-and-retirement-study-2778-5623': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/sintomas-de-insonia-e-o-risco-de-acidente-vascular-cerebral-no-estudo-de-saude-e-aposentadoria/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/symptomes-dinsomnie-et-risque-daccident-vasculaire-cerebral-dans-letude-sur-la-sante-et-la-retraite/',
    it: 'https://it.psychopharmacologyinstitute.com/section/i-sintomi-dellinsonnia-e-il-rischio-di-accidente-cerebrovascolare-nello-studio-sulla-salute-e-la-pensione/',
    de: 'https://de.psychopharmacologyinstitute.com/section/schlaflosigkeitssymptome-und-das-risiko-eines-schlaganfalls-in-der-health-and-retirement-study/',
  },
  'an-international-research-agenda-for-clozapine-resistant-schizophrenia-2777-5622': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uma-agenda-de-pesquisa-internacional-para-a-esquizofrenia-resistente-a-clozapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/un-programme-international-de-recherche-sur-la-schizophrenie-resistante-a-la-clozapine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/unagenda-di-ricerca-internazionale-per-la-schizofrenia-resistente-alla-clozapina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/eine-internationale-forschungsagenda-fuer-clozapin-resistente-schizophrenie/',
  },
  'use-of-daridorexant-for-the-management-of-chronic-insomnia-2777-5621': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uso-do-daridorexant-para-o-tratamento-da-insonia-cronica/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-du-daridorexant-pour-la-prise-en-charge-de-linsomnie-chronique/',
    it: 'https://it.psychopharmacologyinstitute.com/section/uso-di-daridorexant-per-la-gestione-dellinsonnia-cronica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verwendung-von-daridorexant-zur-behandlung-chronischer-schlaflosigkeit/',
  },
  'antidepressant-use-in-patients-with-comorbid-depression-and-medical-illness-2777-5620': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uso-de-antidepressivos-em-pacientes-com-depressao-e-doencas-medicas-comorbidas/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-dantidepresseurs-chez-les-patients-souffrant-de-depression-et-de-maladies-concomitantes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/uso-di-antidepressivi-in-pazienti-con-depressione-e-malattie-mediche-in-comorbilita/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verwendung-von-antidepressiva-bei-patienten-bei-denen-depressionen-und-medizinische-erkrankungen-zusammentreffen/',
  },
  'psychedelics-as-transformative-therapeutics-2777-5619': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/psicodelicos-como-terapeutica-transformadora/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/les-psychedeliques-en-tant-que-therapeutique-transformatrice/',
    it: 'https://it.psychopharmacologyinstitute.com/section/gli-psichedelici-come-terapie-trasformative/',
    de: 'https://de.psychopharmacologyinstitute.com/section/psychedelika-als-transformative-therapeutika/',
  },
  'ssrisnri-concentrations-after-bariatric-surgery-and-effects-on-depressive-symptoms-2777-5618': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/concentracoes-de-isrs-snri-apos-cirurgia-bariatrica-e-efeitos-sobre-sintomas-depressivos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/concentrations-disrs-snri-apres-une-chirurgie-bariatrique-et-effets-sur-les-symptomes-depressifs/',
    it: 'https://it.psychopharmacologyinstitute.com/section/concentrazioni-di-ssri-snri-dopo-la-chirurgia-bariatrica-ed-effetti-sui-sintomi-depressivi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ssri-snri-konzentrationen-nach-bariatrischen-operationen-und-auswirkungen-auf-depressive-symptome/',
  },
  'should-trazodone-be-first-line-therapy-for-insomnia-2776-5595': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/a-trazodona-deve-ser-a-terapia-de-primeira-linha-para-insonia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-trazodone-doit-elle-etre-le-traitement-de-premiere-intention-de-linsomnie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/il-trazodone-dovrebbe-essere-la-terapia-di-prima-linea-per-linsonnia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/sollte-trazodon-die-erstlinientherapie-bei-schlaflosigkeit-sein/',
  },
  'potential-benefits-of-silexan-for-post-covid-19-syndrome-2776-5594': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/beneficios-potenciais-do-silexan-para-a-sindrome-pos-covid-19/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/avantages-potentiels-du-silexan-pour-le-syndrome-post-covid-19/',
    it: 'https://it.psychopharmacologyinstitute.com/section/potenziali-benefici-del-silexan-per-la-sindrome-post-covidica-19/',
    de: 'https://de.psychopharmacologyinstitute.com/section/potenzieller-nutzen-von-silexan-bei-post-covid-19-syndrom/',
  },
  'risk-for-suicide-attempts-and-suicide-associated-with-benzodiazepines-2776-5593': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/risco-de-tentativas-de-suicidio-e-suicidio-associado-a-benzodiazepinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risque-de-tentatives-de-suicide-et-de-suicide-associe-aux-benzodiazepines/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischio-di-tentativi-di-suicidio-e-di-suicidio-associato-alle-benzodiazepine/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiko-fuer-selbstmordversuche-und-selbstmord-in-verbindung-mit-benzodiazepinen/',
  },
  'clozapine-and-its-augmentation-with-ect-in-ultra-treatment-resistant-schizophrenia-2776-5592': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/clozapina-e-seu-reforco-com-ect-na-esquizofrenia-resistente-a-tratamentos-extremos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/clozapine-et-son-augmentation-avec-lect-dans-la-schizophrenie-ultra-resistante-au-traitement/',
    it: 'https://it.psychopharmacologyinstitute.com/section/clozapina-e-il-suo-aumento-con-tec-nella-schizofrenia-ultraresistente-al-trattamento/',
    de: 'https://de.psychopharmacologyinstitute.com/section/clozapin-und-seine-verstaerkung-durch-ekt-bei-extrem-behandlungsresistenter-schizophrenie/',
  },
  'antipsychotic-drugs-and-risk-of-acute-pancreatitis-2776-5591': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/medicamentos-antipsicoticos-e-risco-de-pancreatite-aguda/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/medicaments-antipsychotiques-et-risque-de-pancreatite-aigue/',
    it: 'https://it.psychopharmacologyinstitute.com/section/farmaci-antipsicotici-e-rischio-di-pancreatite-acuta/',
    de: 'https://de.psychopharmacologyinstitute.com/section/antipsychotika-und-das-risiko-einer-akuten-bauchspeicheldruesenentzuendung/',
  },
  'polypharmacy-and-inappropriate-medications-with-antidepressants-in-older-adults-2775-5575': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/polifarmacia-e-medicamentos-inadequados-com-antidepressivos-em-idosos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/polypharmacie-et-medicaments-inappropries-avec-les-antidepresseurs-chez-les-personnes-agees/',
    it: 'https://it.psychopharmacologyinstitute.com/section/polifarmacia-e-farmaci-inappropriati-con-antidepressivi-negli-anziani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/polypharmazie-und-unangemessene-medikation-mit-antidepressiva-bei-aelteren-erwachsenen/',
  },
  'antipsychotic-safety-in-liver-disease-2775-5574': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/seguranca-dos-antipsicoticos-na-doenca-hepatica/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/securite-des-antipsychotiques-en-cas-de-maladie-du-foie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/sicurezza-degli-antipsicotici-nelle-malattie-epatiche/',
    de: 'https://de.psychopharmacologyinstitute.com/section/sicherheit-von-antipsychotika-bei-lebererkrankungen/',
  },
  'lithium-augmentation-for-treating-major-depressive-disorder-2775-5573': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/aumento-do-litio-no-tratamento-do-transtorno-depressivo-maior/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/augmentation-de-la-dose-de-lithium-dans-le-traitement-des-troubles-depressifs-majeurs/',
    it: 'https://it.psychopharmacologyinstitute.com/section/aumento-del-litio-per-il-trattamento-del-disturbo-depressivo-maggiore/',
    de: 'https://de.psychopharmacologyinstitute.com/section/lithium-augmentation-zur-behandlung-von-schweren-depressiven-stoerungen/',
  },
  'antidepressant-discontinuation-during-pregnancy-and-postpartum-psychiatric-outcomes-2775-5572': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/descontinuacao-de-antidepressivos-durante-a-gravidez-e-resultados-psiquiatricos-pos-parto/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/arret-des-antidepresseurs-pendant-la-grossesse-et-consequences-psychiatriques-du-post-partum/',
    it: 'https://it.psychopharmacologyinstitute.com/section/interruzione-degli-antidepressivi-durante-la-gravidanza-ed-esiti-psichiatrici-post-partum/',
    de: 'https://de.psychopharmacologyinstitute.com/section/absetzen-von-antidepressiva-waehrend-der-schwangerschaft-und-postpartale-psychiatrische-ergebnisse/',
  },
  'neonatal-and-pregnancy-complications-following-maternal-depression-or-antidepressant-exposure-2775-5571':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/complicacoes-neonatais-e-na-gravidez-apos-depressao-materna-ou-exposicao-a-antidepressivos/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/complications-neonatales-et-de-grossesse-suite-a-une-depression-maternelle-ou-a-lexposition-a-des-antidepresseurs/',
      it: 'https://it.psychopharmacologyinstitute.com/section/complicazioni-neonatali-e-della-gravidanza-in-seguito-a-depressione-materna-o-esposizione-ad-antidepressivi/',
      de: 'https://de.psychopharmacologyinstitute.com/section/neonatale-und-schwangerschaftsbedingte-komplikationen-nach-muetterlicher-depression-oder-antidepressiva-exposition/',
    },
  'sleep-disorders-in-parkinsons-disease-2774-5570': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/disturbios-do-sono-na-doenca-de-parkinson/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/troubles-du-sommeil-dans-la-maladie-de-parkinson/',
    it: 'https://it.psychopharmacologyinstitute.com/section/disturbi-del-sonno-nella-malattia-di-parkinson/',
    de: 'https://de.psychopharmacologyinstitute.com/section/schlafstoerungen-bei-der-parkinson-krankheit/',
  },
  'delirium-preventive-effects-of-dual-orexin-receptor-antagonists-2774-5569': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/efeitos-preventivos-de-delirium-dos-antagonistas-duplos-dos-receptores-de-orexina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/effets-preventifs-du-delirium-des-antagonistes-du-double-recepteur-de-lorexine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/effetti-preventivi-del-delirium-dei-doppi-antagonisti-dei-recettori-dellorexina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/vorbeugende-wirkung-von-dualen-orexin-rezeptor-antagonisten-auf-das-delirium/',
  },
  'risk-of-irreversible-postssri-sexual-dysfunction-with-antidepressants-2774-5568': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/risco-de-disfuncao-sexual-irreversivel-pos-isrs-com-antidepressivos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risque-de-dysfonctionnement-sexuel-irreversible-apres-isrs-avec-les-antidepresseurs/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischio-di-disfunzioni-sessuali-irreversibili-post-sri-con-antidepressivi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiko-einer-irreversiblen-sexuellen-funktionsstoerung-nach-der-einnahme-von-ssri-antidepressiva/',
  },
  'the-pharmacologic-treatment-of-obsessive-compulsive-disorder-2774-5567': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/o-tratamento-farmacologico-do-transtorno-obsessivo-compulsivo/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-pharmacologique-des-troubles-obsessionnels-compulsifs/',
    it: 'https://it.psychopharmacologyinstitute.com/section/il-trattamento-farmacologico-del-disturbo-ossessivo-compulsivo/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-pharmakologische-behandlung-der-zwangsstoerung/',
  },
  'tobacco-use-nicotine-dependence-and-cessation-among-adults-with-psychosis-2774-5566': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uso-de-tabaco-dependencia-de-nicotina-e-cessacao-entre-adultos-com-psicose/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/tabagisme-dependance-a-la-nicotine-et-arret-du-tabac-chez-les-adultes-atteints-de-psychose/',
    it: 'https://it.psychopharmacologyinstitute.com/section/uso-di-tabacco-dipendenza-da-nicotina-e-cessazione-tra-gli-adulti-con-psicosi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/tabakkonsum-nikotinabhaengigkeit-und-tabakentwoehnung-bei-erwachsenen-mit-psychosen/',
  },
  'risk-of-malformations-in-infants-after-exposure-to-benzodiazepines-2738-5546': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/risco-de-malformacoes-em-bebes-apos-exposicao-a-benzodiazepinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risque-de-malformations-chez-les-nourrissons-apres-exposition-aux-benzodiazepines/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischio-di-malformazioni-nei-neonati-dopo-lesposizione-alle-benzodiazepine/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiko-von-fehlbildungen-bei-saeuglingen-nach-exposition-gegenueber-benzodiazepinen/',
  },
  'apathy-associated-with-antidepressant-drugs-2738-5545': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/apatia-associada-a-medicamentos-antidepressivos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/apathie-associee-aux-antidepresseurs/',
    it: 'https://it.psychopharmacologyinstitute.com/section/apatia-associata-a-farmaci-antidepressivi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/apathie-in-verbindung-mit-antidepressiva/',
  },
  'zuranolone-for-the-treatment-of-postpartum-depression-2738-5544': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/zuranolona-para-o-tratamento-da-depressao-pos-parto/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/zuranolone-pour-le-traitement-de-la-depression-post-partum/',
    it: 'https://it.psychopharmacologyinstitute.com/section/zuranolone-per-il-trattamento-della-depressione-post-partum/',
    de: 'https://de.psychopharmacologyinstitute.com/section/zuranolon-zur-behandlung-von-postpartalen-depressionen/',
  },
  'victimization-in-relation-to-violence-in-schizophrenia-2738-5543': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/vitimizacao-em-relacao-a-violencia-na-esquizofrenia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-victimisation-en-relation-avec-la-violence-dans-la-schizophrenie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/la-vittimizzazione-in-relazione-alla-violenza-nella-schizofrenia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/viktimisierung-im-zusammenhang-mit-gewalt-bei-schizophrenie/',
  },
  'use-of-nsaids-for-delirium-risk-reduction-2738-5542': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uso-de-aines-para-reducao-do-risco-de-delirium/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-des-ains-pour-reduire-le-risque-de-delirium/',
    it: 'https://it.psychopharmacologyinstitute.com/section/uso-dei-fans-per-la-riduzione-del-rischio-di-delirium/',
    de: 'https://de.psychopharmacologyinstitute.com/section/einsatz-von-nsar-zur-verringerung-des-delirium-risikos/',
  },
  'the-impact-of-colony-stimulating-factor-on-clozapine-rechallenge-outcomes-2737-5507': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/o-impacto-do-fator-estimulador-de-colonias-nos-resultados-da-reintroducao-da-clozapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/limpact-du-facteur-de-stimulation-des-colonies-sur-les-resultats-de-la-reprise-de-la-clozapine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/limpatto-del-fattore-di-stimolazione-delle-colonie-sugli-esiti-del-rechallenge-con-clozapina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/der-einfluss-des-koloniestimulierenden-faktors-auf-die-ergebnisse-der-clozapin-wiederholungsbehandlung/',
  },
  'effect-of-pharmacogenomics-testing-on-clinical-outcomes-in-major-depressive-disorder-2737-5506':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/efeito-dos-testes-farmacogenomicos-nos-resultados-clinicos-do-transtorno-depressivo-maior/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/effet-des-tests-pharmacogenomiques-sur-les-resultats-cliniques-dans-le-trouble-depressif-majeur/',
      it: 'https://it.psychopharmacologyinstitute.com/section/effetto-dei-test-farmacogenomici-sugli-esiti-clinici-nel-disturbo-depressivo-maggiore/',
      de: 'https://de.psychopharmacologyinstitute.com/section/auswirkungen-pharmakogenomischer-tests-auf-die-klinischen-ergebnisse-bei-schweren-depressiven-stoerungen/',
    },
  'tardive-syndromes-clinical-manifestations-and-treatment-strategies-2737-5505': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/sindromes-tardias-manifestacoes-clinicas-e-estrategias-de-tratamento/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/syndromes-tardifs-manifestations-cliniques-et-strategies-de-traitement/',
    it: 'https://it.psychopharmacologyinstitute.com/section/sindromi-tardive-manifestazioni-cliniche-e-strategie-di-trattamento/',
    de: 'https://de.psychopharmacologyinstitute.com/section/tardive-syndrome-klinische-erscheinungsformen-und-behandlungsstrategien/',
  },
  'effect-of-aripiprazole-co-prescription-on-prolactin-levels-in-schizophrenic-patients-with-type-2-diabetes-2737-5504':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/efeito-da-co-prescricao-de-aripiprazol-nos-niveis-de-prolactina-em-pacientes-esquizofrenicos-com-diabetes-tipo-2/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/effet-de-la-co-prescription-daripiprazole-sur-les-niveaux-de-prolactine-chez-les-patients-schizophrenes-atteints-de-diabete-de-type-2/',
      it: 'https://it.psychopharmacologyinstitute.com/section/effetto-della-co-prescrizione-di-aripiprazolo-sui-livelli-di-prolattina-in-pazienti-schizofrenici-con-diabete-di-tipo-2/',
      de: 'https://de.psychopharmacologyinstitute.com/section/wirkung-der-gleichzeitigen-verschreibung-von-aripiprazol-auf-den-prolaktinspiegel-bei-schizophrenen-patienten-mit-typ-2-diabetes/',
    },
  'effect-of-brexanolone-on-postpartum-depressive-symptoms-anxiety-and-insomnia-2737-5503': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/efeito-da-brexanolona-nos-sintomas-depressivos-na-ansiedade-e-na-insonia-pos-parto/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/effet-de-la-brexanolone-sur-les-symptomes-depressifs-lanxiete-et-linsomnie-du-post-partum/',
    it: 'https://it.psychopharmacologyinstitute.com/section/effetto-del-brexanolone-sui-sintomi-depressivi-lansia-e-linsonnia-post-partum/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirkung-von-brexanolon-auf-depressive-symptome-angstzustaende-und-schlaflosigkeit-nach-der-geburt/',
  },
  'canadian-network-for-mood-and-anxiety-treatments-canmat-and-international-society-for-bipolar-disorders-isbd-2018-guidelines-for-the-management-of-patients-with-bipolar-disorder-2731-5502':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/rede-canadense-para-tratamentos-de-humor-e-ansiedade-canmat-e-sociedade-internacional-de-transtornos-bipolares-isbd-diretrizes-de-2018-para-o-gerenciamento-de-pacientes-com-transtorno-bipolar/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/reseau-canadien-pour-le-traitement-de-lhumeur-et-de-lanxiete-canmat-et-societe-internationale-des-troubles-bipolaires-isbd-lignes-directrices-2018-pour-la-prise-en-charge-des-patients-attein/',
      it: 'https://it.psychopharmacologyinstitute.com/section/canadian-network-for-mood-and-anxiety-treatments-canmat-e-international-society-for-bipolar-disorders-isbd-linee-guida-2018-per-la-gestione-dei-pazienti-con-disturbo-bipolare/',
      de: 'https://de.psychopharmacologyinstitute.com/section/kanadisches-netzwerk-fuer-stimmungs-und-angstbehandlungen-canmat-und-internationale-gesellschaft-fuer-bipolare-stoerungen-isbd-2018-leitlinien-fuer-die-behandlung-von-patienten-mit-bipolarer/',
    },
  'distinguishing-relapse-from-antidepressant-withdrawal-clinical-practice-and-antidepressant-discontinuation-studies-2731-5501':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/distinguindo-recaida-de-abstinencia-de-antidepressivos-pratica-clinica-e-estudos-de-descontinuacao-de-antidepressivos/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/distinguer-la-rechute-du-sevrage-dantidepresseurs-pratique-clinique-et-etudes-sur-larret-des-antidepresseurs/',
      it: 'https://it.psychopharmacologyinstitute.com/section/distinguere-la-ricaduta-dallastinenza-da-antidepressivi-pratica-clinica-e-studi-sulla-sospensione-degli-antidepressivi/',
      de: 'https://de.psychopharmacologyinstitute.com/section/unterscheidung-zwischen-rueckfall-und-antidepressiva-entzug-klinische-praxis-und-studien-zum-absetzen-von-antidepressiva/',
    },
  'considering-the-methodological-limitations-in-the-evidence-base-of-antidepressants-for-depression-a-reanalysis-of-a-network-meta-analysis-2731-5500':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/considerando-as-limitacoes-metodologicas-na-base-de-evidencias-de-antidepressivos-para-depressao-uma-reanalise-de-uma-meta-analise-em-rede/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/prise-en-compte-des-limites-methodologiques-de-la-base-de-donnees-probantes-sur-les-antidepresseurs-pour-la-depression-une-nouvelle-analyse-dune-meta-analyse-en-reseau/',
      it: 'https://it.psychopharmacologyinstitute.com/section/considerare-i-limiti-metodologici-della-base-di-evidenza-degli-antidepressivi-per-la-depressione-una-rianalisi-di-una-meta-analisi-di-rete/',
      de: 'https://de.psychopharmacologyinstitute.com/section/beruecksichtigung-der-methodischen-einschraenkungen-bei-der-evidenzbasis-fuer-antidepressiva-bei-depressionen-eine-erneute-analyse-einer-netzwerk-meta-analyse/',
    },
  'canadian-network-for-mood-and-anxiety-treatments-canmat-and-international-society-for-bipolar-disorders-isbd-recommendations-for-the-management-of-patients-with-bipolar-disorder-with-mixed-presentations-2731-5499':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/recomendacoes-da-rede-canadense-para-tratamentos-de-humor-e-ansiedade-canmat-e-da-sociedade-internacional-de-transtornos-bipolares-isbd-para-o-gerenciamento-de-pacientes-com-transtorno-bipolar-com/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/recommandations-du-reseau-canadien-pour-le-traitement-de-lhumeur-et-de-lanxiete-canmat-et-de-la-societe-internationale-des-troubles-bipolaires-isbd-pour-la-prise-en-charge-des-patients-atteint/',
      it: 'https://it.psychopharmacologyinstitute.com/section/raccomandazioni-del-canadian-network-for-mood-and-anxiety-treatments-canmat-e-della-international-society-for-bipolar-disorders-isbd-per-la-gestione-dei-pazienti-con-disturbo-bipolare-a-presen/',
      de: 'https://de.psychopharmacologyinstitute.com/section/empfehlungen-des-kanadischen-netzwerks-fuer-die-behandlung-von-stimmungsschwankungen-und-angstzustaenden-canmat-und-der-internationalen-gesellschaft-fuer-bipolare-stoerungen-isbd-fuer-die-behandlu/',
    },
  'cades-disease-and-beyond-misdiagnosis-antidepressant-use-and-a-proposed-definition-for-bipolar-spectrum-disorder-2731-5498':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/a-doenca-de-cade-e-alem-diagnostico-incorreto-uso-de-antidepressivos-e-uma-proposta-de-definicao-para-o-transtorno-do-espectro-bipolar/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/la-maladie-de-cade-et-au-dela-diagnostic-errone-utilisation-dantidepresseurs-et-proposition-de-definition-du-trouble-du-spectre-bipolaire/',
      it: 'https://it.psychopharmacologyinstitute.com/section/la-malattia-di-cade-e-oltre-diagnosi-errate-uso-di-antidepressivi-e-una-proposta-di-definizione-per-il-disturbo-dello-spettro-bipolare/',
      de: 'https://de.psychopharmacologyinstitute.com/section/die-cade-krankheit-und-darueber-hinaus-fehldiagnosen-verwendung-von-antidepressiva-und-ein-definitionsvorschlag-fuer-eine-bipolare-spektrumsstoerung/',
    },
  'olanzapinesamidorphan-in-young-adults-with-schizophrenia-schizophreniform-disorder-or-bipolar-i-disorder-who-are-early-in-their-illness-results-of-the-randomized-controlled-enlighten-early-study-2726-5481':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/olanzapina-samidorfano-em-adultos-jovens-com-esquizofrenia-transtorno-esquizofreniforme-ou-transtorno-bipolar-i-que-estao-no-inicio-da-doenca-resultados-do-estudo-randomizado-e-controlado-enlighten/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/olanzapine-samidorphan-chez-les-jeunes-adultes-atteints-de-schizophrenie-de-trouble-schizophreniforme-ou-de-trouble-bipolaire-i-au-debut-de-leur-maladie-resultats-de-letude-randomisee-et-controle/',
      it: 'https://it.psychopharmacologyinstitute.com/section/olanzapina-samidorfano-in-giovani-adulti-con-schizofrenia-disturbo-schizofreniforme-o-disturbo-bipolare-i-in-fase-iniziale-di-malattia-risultati-dello-studio-randomizzato-e-controllato-enlighten-ear/',
      de: 'https://de.psychopharmacologyinstitute.com/section/olanzapin-samidorphan-bei-jungen-erwachsenen-mit-schizophrenie-schizophreniformer-stoerung-oder-bipolarer-stoerung-im-fruehstadium-ihrer-erkrankung-ergebnisse-der-randomisierten-kontrollierten-enli/',
    },
  'third-generation-antipsychotics-in-patients-with-schizophrenia-and-nonresponsivity-or-intolerance-to-clozapine-regimen-what-is-the-evidence-2726-5480':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/antipsicoticos-de-terceira-geracao-em-pacientes-com-esquizofrenia-e-nao-responsividade-ou-intolerancia-ao-regime-de-clozapina-quais-sao-as-evidencias/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/antipsychotiques-de-troisieme-generation-chez-les-patients-atteints-de-schizophrenie-et-de-non-reponse-ou-dintolerance-a-la-clozapine-quelles-sont-les-preuves/',
      it: 'https://it.psychopharmacologyinstitute.com/section/antipsicotici-di-terza-generazione-in-pazienti-con-schizofrenia-e-non-responsivita-o-intolleranza-al-regime-di-clozapina-quali-sono-le-evidenze/',
      de: 'https://de.psychopharmacologyinstitute.com/section/antipsychotika-der-dritten-generation-bei-patienten-mit-schizophrenie-die-auf-clozapin-nicht-ansprechen-oder-es-nicht-vertragen-was-ist-die-beweislage/',
    },
  'pharmacologic-treatments-for-cocaine-craving-what-is-the-way-forward-a-systematic-review-2726-5479':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/tratamentos-farmacologicos-para-o-desejo-por-cocaina-qual-e-o-caminho-a-seguir-uma-revisao-sistematica/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/traitements-pharmacologiques-de-lenvie-de-cocaine-quelle-est-la-voie-a-suivre-une-revue-systematique/',
      it: 'https://it.psychopharmacologyinstitute.com/section/trattamenti-farmacologici-per-il-craving-da-cocaina-qual-e-la-strada-da-seguire-una-revisione-sistematica/',
      de: 'https://de.psychopharmacologyinstitute.com/section/pharmakologische-behandlungen-des-kokainverlangens-was-ist-der-richtige-weg-eine-systematische-uebersicht/',
    },
  'dual-orexin-receptor-antagonists-for-the-treatment-of-insomnia-a-systematic-review-and-meta-analysis-on-randomized-double-blind-placebo-controlled-trials-of-suvorexant-and-lemborexant-2726-5478':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/antagonistas-duplos-dos-receptores-de-orexina-para-o-tratamento-da-insonia-uma-revisao-sistematica-e-meta-analise-de-estudos-randomizados-duplo-cegos-e-controlados-por-placebo-de-suvorexant-e-lembor/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/antagonistes-des-recepteurs-de-lorexine-pour-le-traitement-de-linsomnie-revue-systematique-et-meta-analyse-des-essais-randomises-en-double-aveugle-controles-par-placebo-du-suvorexant-et-du-le/',
      it: 'https://it.psychopharmacologyinstitute.com/section/antagonisti-doppi-del-recettore-dellorexina-per-il-trattamento-dellinsonnia-una-revisione-sistematica-e-una-meta-analisi-di-studi-randomizzati-in-doppio-cieco-e-controllati-con-placebo-suvorexan/',
      de: 'https://de.psychopharmacologyinstitute.com/section/duale-orexin-rezeptor-antagonisten-zur-behandlung-von-schlaflosigkeit-eine-systematische-uebersicht-und-meta-analyse-randomisierter-doppelblinder-placebokontrollierter-studien-zu-suvorexant-und-lem/',
    },
  'comparative-efficacy-safety-and-acceptability-of-pimavanserin-and-other-atypical-antipsychotics-for-parkinsons-disease-psychosis-systematic-review-and-network-meta-analysis-2726-5477':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/eficacia-comparativa-seguranca-e-aceitabilidade-da-pimavanserina-e-de-outros-antipsicoticos-atipicos-para-a-psicose-da-doenca-de-parkinson-revisao-sistematica-e-meta-analise-em-rede/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/comparaison-de-lefficacite-de-la-securite-et-de-lacceptabilite-de-la-pimavanserine-et-dautres-antipsychotiques-atypiques-dans-le-traitement-de-la-psychose-liee-a-la-maladie-de-parkinson-revue/',
      it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-sicurezza-e-accettabilita-comparate-di-pimavanserin-e-altri-antipsicotici-atipici-per-la-psicosi-da-malattia-di-parkinson-revisione-sistematica-e-meta-analisi-di-rete/',
      de: 'https://de.psychopharmacologyinstitute.com/section/vergleich-der-wirksamkeit-sicherheit-und-akzeptanz-von-pimavanserin-und-anderen-atypischen-antipsychotika-bei-der-behandlung-der-parkinson-psychose-systematische-ueberpruefung-und-netzwerk-meta-anal/',
    },
  'use-of-hormonal-contraceptives-and-antidepressants-and-risks-of-suicidal-behavior-and-accidents-among-women-with-premenstrual-disorders-a-nationwide-cohort-study-2708-5454':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/uso-de-contraceptivos-hormonais-e-antidepressivos-e-riscos-de-comportamento-suicida-e-acidentes-entre-mulheres-com-disturbios-pre-menstruais-um-estudo-de-coorte-nacional/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-de-contraceptifs-hormonaux-et-dantidepresseurs-et-risques-de-comportement-suicidaire-et-daccidents-chez-les-femmes-souffrant-de-troubles-premenstruels-une-etude-de-cohorte-a-lechel/',
      it: 'https://it.psychopharmacologyinstitute.com/section/uso-di-contraccettivi-ormonali-e-antidepressivi-e-rischi-di-comportamenti-suicidi-e-incidenti-tra-le-donne-con-disturbi-premestruali-uno-studio-di-coorte-a-livello-nazionale/',
      de: 'https://de.psychopharmacologyinstitute.com/section/verwendung-von-hormonellen-verhuetungsmitteln-und-antidepressiva-und-das-risiko-von-suizidalitaet-und-unfaellen-bei-frauen-mit-praemenstruellen-stoerungen-eine-bundesweite-kohortenstudie/',
    },
  'cannabis-connectivity-and-coming-of-age-associations-between-cannabis-use-and-anterior-cingulate-cortex-connectivity-during-the-transition-to-adulthood-2708-5453':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/cannabis-conectividade-e-amadurecimento-associacoes-entre-o-uso-de-cannabis-e-a-conectividade-do-cortex-cingulado-anterior-durante-a-transicao-para-a-vida-adulta/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/cannabis-connectivite-et-passage-a-lage-adulte-associations-entre-la-consommation-de-cannabis-et-la-connectivite-du-cortex-cingulaire-anterieur-pendant-la-transition-vers-lage-adulte/',
      it: 'https://it.psychopharmacologyinstitute.com/section/cannabis-connettivita-e-raggiungimento-della-maggiore-eta-associazioni-tra-luso-di-cannabis-e-la-connettivita-della-corteccia-cingolata-anteriore-durante-il-passaggio-alleta-adulta/',
      de: 'https://de.psychopharmacologyinstitute.com/section/cannabis-konnektivitaet-und-erwachsenwerden-assoziationen-zwischen-cannabiskonsum-und-konnektivitaet-des-anterioren-cingulaeren-kortex-waehrend-des-uebergangs-zum-erwachsensein/',
    },
  'risk-of-cardiovascular-diseases-associated-with-medications-used-in-attention-deficithyperactivity-disorder-a-systematic-review-and-meta-analysis-2708-5452':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/risco-de-doencas-cardiovasculares-associadas-a-medicamentos-usados-no-transtorno-de-deficit-de-atencao-hiperatividade-uma-revisao-sistematica-e-meta-analise/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/risque-de-maladies-cardiovasculaires-associe-aux-medicaments-utilises-dans-le-trouble-du-deficit-de-lattention-hyperactivite-une-revue-systematique-et-une-meta-analyse/',
      it: 'https://it.psychopharmacologyinstitute.com/section/rischio-di-malattie-cardiovascolari-associato-ai-farmaci-utilizzati-nel-disturbo-da-deficit-di-attenzione-iperattivita-una-revisione-sistematica-e-una-meta-analisi/',
      de: 'https://de.psychopharmacologyinstitute.com/section/risiko-von-herz-kreislauf-erkrankungen-im-zusammenhang-mit-medikamenten-die-bei-aufmerksamkeitsdefizit-hyperaktivitaetsstoerungen-eingesetzt-werden-eine-systematische-ueberpruefung-und-meta-analyse/',
    },
  'antidepressant-use-during-pregnancy-and-the-risk-for-gestational-diabetes-a-systematic-review-and-meta-analysis-2708-5451':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/uso-de-antidepressivos-durante-a-gravidez-e-o-risco-de-diabetes-gestacional-uma-revisao-sistematica-e-meta-analise/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-dantidepresseurs-pendant-la-grossesse-et-risque-de-diabete-gestationnel-une-revue-systematique-et-une-meta-analyse/',
      it: 'https://it.psychopharmacologyinstitute.com/section/uso-di-antidepressivi-in-gravidanza-e-rischio-di-diabete-gestazionale-una-revisione-sistematica-e-una-meta-analisi/',
      de: 'https://de.psychopharmacologyinstitute.com/section/die-einnahme-von-antidepressiva-waehrend-der-schwangerschaft-und-das-risiko-fuer-schwangerschaftsdiabetes-eine-systematische-ueberpruefung-und-meta-analyse/',
    },
  'haloperidol-for-the-treatment-of-delirium-in-icu-patients-2708-5450': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/haloperidol-para-o-tratamento-de-delirium-em-pacientes-de-uti/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/lhaloperidol-pour-le-traitement-du-delirium-chez-les-patients-en-soins-intensifs/',
    it: 'https://it.psychopharmacologyinstitute.com/section/aloperidolo-per-il-trattamento-del-delirium-nei-pazienti-in-terapia-intensiva/',
    de: 'https://de.psychopharmacologyinstitute.com/section/haloperidol-zur-behandlung-von-delirium-bei-patienten-auf-der-intensivstation/',
  },
  'effect-of-pharmacogenomic-testing-for-druggene-interactions-on-medication-selection-and-remission-of-symptoms-in-major-depressive-disorder-the-prime-care-randomized-clinical-trial-2707-5433':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/efeito-do-teste-farmacogenomico-para-interacoes-medicamento-gene-na-selecao-de-medicamentos-e-remissao-de-sintomas-no-transtorno-depressivo-maior-o-ensaio-clinico-randomizado-prime-care/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/effet-des-tests-pharmacogenomiques-dinteraction-medicament-gene-sur-le-choix-des-medicaments-et-la-remission-des-symptomes-dans-le-trouble-depressif-majeur-lessai-clinique-randomise-prime-care/',
      it: 'https://it.psychopharmacologyinstitute.com/section/effetto-del-test-farmacogenomico-per-le-interazioni-farmaco-gene-sulla-selezione-dei-farmaci-e-sulla-remissione-dei-sintomi-nel-disturbo-depressivo-maggiore-lo-studio-clinico-randomizzato-prime-care/',
      de: 'https://de.psychopharmacologyinstitute.com/section/auswirkung-pharmakogenomischer-tests-auf-wechselwirkungen-zwischen-medikamenten-und-genen-auf-die-auswahl-der-medikamente-und-die-remission-der-symptome-bei-einer-schweren-depressiven-stoerung-die-ra/',
    },
  'e-cigarette-use-patterns-flavors-and-device-characteristics-associated-with-quitting-smoking-among-a-us-sample-of-adults-using-e-cigarettes-in-a-smoking-cessation-attempt-2707-5432':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/padroes-de-uso-de-cigarros-eletronicos-sabores-e-caracteristicas-do-dispositivo-associados-ao-abandono-do-tabagismo-em-uma-amostra-de-adultos-dos-eua-amostra-de-adultos-que-usam-cigarros-eletronicos/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/modes-dutilisation-de-le-cigarette-aromes-et-caracteristiques-de-lappareil-associes-a-larret-du-tabac-parmi-un-echantillon-dadultes-americains-utilisant-le-cigarette-dans-le-cadre-dune/',
      it: 'https://it.psychopharmacologyinstitute.com/section/modelli-di-utilizzo-della-sigaretta-elettronica-aromi-e-caratteristiche-del-dispositivo-associati-alla-cessazione-del-fumo-in-un-campione-statunitense-degli-stati-uniti-un-campione-di-adulti-che-ut/',
      de: 'https://de.psychopharmacologyinstitute.com/section/e-zigaretten-nutzungsmuster-geschmacksrichtungen-und-geraeteeigenschaften-im-zusammenhang-mit-der-raucherentwoehnung-bei-einer-us-amerikanischen-stichprobe-stichprobe-von-erwachsenen-die-e-zigarett/',
    },
  'effects-of-a-smartphone-based-self-management-intervention-for-individuals-with-bipolar-disorder-on-relapse-symptom-burden-and-quality-of-life-2707-5431':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/efeitos-de-uma-intervencao-de-autogerenciamento-baseada-em-smartphone-para-individuos-com-transtorno-bipolar-sobre-recaida-carga-de-sintomas-e-qualidade-de-vida/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/effets-dune-intervention-dautogestion-basee-sur-un-smartphone-pour-les-personnes-souffrant-de-troubles-bipolaires-sur-la-rechute-le-fardeau-des-symptomes-et-la-qualite-de-vie/',
      it: 'https://it.psychopharmacologyinstitute.com/section/effetti-di-un-intervento-di-autogestione-basato-su-smartphone-per-individui-con-disturbo-bipolare-su-ricadute-carico-sintomatologico-e-qualita-di-vita/',
      de: 'https://de.psychopharmacologyinstitute.com/section/auswirkungen-einer-smartphone-basierten-selbstmanagement-intervention-fuer-menschen-mit-bipolarer-stoerung-auf-rueckfall-symptombelastung-und-lebensqualitaet/',
    },
  'novel-and-emerging-treatments-for-major-depression-2707-5430': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamentos-novos-e-emergentes-para-depressao-maior/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitements-nouveaux-et-emergents-pour-la-depression-majeure/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamenti-nuovi-ed-emergenti-per-la-depressione-maggiore/',
    de: 'https://de.psychopharmacologyinstitute.com/section/neue-und-sich-abzeichnende-behandlungsmoeglichkeiten-fuer-major-depression/',
  },
  'associations-between-antipsychotic-use-substance-use-and-relapse-risk-in-patients-with-schizophrenia-real-world-evidence-from-2-national-cohorts-2707-5429':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/associacoes-entre-o-uso-de-antipsicoticos-o-uso-de-substancias-e-o-risco-de-recaida-em-pacientes-com-esquizofrenia-evidencias-do-mundo-real-de-2-coortes-nacionais/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/associations-entre-la-consommation-dantipsychotiques-la-consommation-de-substances-psychoactives-et-le-risque-de-rechute-chez-les-patients-atteints-de-schizophrenie-donnees-reelles-issues-de-deux/',
      it: 'https://it.psychopharmacologyinstitute.com/section/associazioni-tra-uso-di-antipsicotici-uso-di-sostanze-e-rischio-di-ricaduta-in-pazienti-con-schizofrenia-evidenze-reali-da-2-coorti-nazionali/',
      de: 'https://de.psychopharmacologyinstitute.com/section/zusammenhaenge-zwischen-antipsychotikakonsum-substanzkonsum-und-rueckfallrisiko-bei-patienten-mit-schizophrenie-realitaetsnahe-erkenntnisse-aus-2-nationalen-kohorten/',
    },
  'effect-of-anxiolytic-drug-silexan-on-sleep-a-narrative-review-2706-5401': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/efeito-da-droga-ansiolitica-silexan-no-sono-uma-revisao-narrativa/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/effet-du-medicament-anxiolytique-silexan-sur-le-sommeil-une-revue-narrative/',
    it: 'https://it.psychopharmacologyinstitute.com/section/effetto-del-farmaco-ansiolitico-silexan-sul-sonno-una-revisione-narrativa/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirkung-des-anxiolytikums-silexan-auf-den-schlaf-eine-uebersichtsarbeit/',
  },
  'c-reactive-protein-could-predict-the-efficacy-of-ssris-in-clinical-practice-a-cohort-study-of-large-samples-in-the-real-world-2706-5400':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/a-proteina-c-reativa-pode-prever-a-eficacia-dos-isrs-na-pratica-clinica-um-estudo-de-coorte-de-grandes-amostras-no-mundo-real/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/la-proteine-c-reactive-pourrait-predire-lefficacite-des-isrs-en-pratique-clinique-une-etude-de-cohorte-sur-de-larges-echantillons-dans-le-monde-reel/',
      it: 'https://it.psychopharmacologyinstitute.com/section/la-proteina-c-reattiva-potrebbe-predire-lefficacia-de-gli-ssri-nella-pratica-clinica-uno-studio-di-coorte-su-grandi-campioni-nel-mondo-reale/',
      de: 'https://de.psychopharmacologyinstitute.com/section/c-reaktives-protein-koennte-die-wirksamkeit-von-ssri-in-der-klinischen-praxis-vorhersagen-eine-kohortenstudie-mit-grossen-stichproben-in-der-realen-welt/',
    },
  'augmentation-strategies-for-clozapine-resistance-a-systematic-review-and-meta-analysis-2706-5399':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/estrategias-de-aumento-da-resistencia-a-clozapina-uma-revisao-sistematica-e-meta-analise/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/strategies-daugmentation-de-la-resistance-a-la-clozapine-revue-systematique-et-meta-analyse/',
      it: 'https://it.psychopharmacologyinstitute.com/section/strategie-di-potenziamento-per-la-resistenza-alla-clozapina-revisione-sistematica-e-meta-analisi/',
      de: 'https://de.psychopharmacologyinstitute.com/section/augmentationsstrategien-bei-clozapin-resistenz-eine-systematische-uebersicht-und-meta-analyse/',
    },
  'antidepressants-for-the-treatment-of-adults-with-major-depressive-disorder-in-the-maintenance-phase-a-systematic-review-and-network-meta-analysis-2706-5398':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/antidepressivos-para-o-tratamento-de-adultos-com-transtorno-depressivo-maior-na-fase-de-manutencao-uma-revisao-sistematica-e-meta-analise-de-rede/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/antidepresseurs-pour-le-traitement-des-adultes-souffrant-dun-trouble-depressif-majeur-en-phase-dentretien-revue-systematique-et-meta-analyse-en-reseau/',
      it: 'https://it.psychopharmacologyinstitute.com/section/antidepressivi-per-il-trattamento-di-adulti-con-disturbo-depressivo-maggiore-nella-fase-di-mantenimento-una-revisione-sistematica-e-una-meta-analisi-di-rete/',
      de: 'https://de.psychopharmacologyinstitute.com/section/antidepressiva-fuer-die-behandlung-von-erwachsenen-mit-schweren-depressiven-stoerungen-in-der-erhaltungsphase-eine-systematische-ueberpruefung-und-netzwerk-meta-analyse/',
    },
  'cognitive-function-and-treatment-response-trajectories-in-first-episode-schizophrenia-evidence-from-a-prospective-cohort-study-2706-5397':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/funcao-cognitiva-e-trajetorias-de-resposta-ao-tratamento-na-esquizofrenia-de-primeiro-episodio-evidencias-de-um-estudo-de-coorte-prospectivo/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/fonction-cognitive-et-trajectoires-de-reponse-au-traitement-dans-le-premier-episode-de-schizophrenie-resultats-dune-etude-de-cohorte-prospective/',
      it: 'https://it.psychopharmacologyinstitute.com/section/funzione-cognitiva-e-traiettorie-di-risposta-al-trattamento-nella-schizofrenia-al-primo-episodio-evidenze-da-uno-studio-prospettico-di-coorte/',
      de: 'https://de.psychopharmacologyinstitute.com/section/kognitive-funktion-und-verlauf-des-behandlungserfolgs-bei-schizophrenie-im-ersten-stadium-ergebnisse-einer-prospektiven-kohortenstudie/',
    },
  'pharmacologic-and-nonpharmacologic-interventions-for-persistent-irritability-2746-5392': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/intervencoes-farmacologicas-e-nao-farmacologicas-para-irritabilidade-persistente/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/interventions-pharmacologiques-et-non-pharmacologiques-pour-lirritabilite-persistante/',
    it: 'https://it.psychopharmacologyinstitute.com/section/interventi-farmacologici-e-non-farmacologici-per-lirritabilita-persistente/',
    de: 'https://de.psychopharmacologyinstitute.com/section/pharmakologische-und-nicht-pharmakologische-interventionen-bei-anhaltender-reizbarkeit/',
  },
  'viloxazine-effect-on-learning-and-school-problems-in-youth-with-adhd-2746-5393': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/efeito-da-viloxazina-nos-problemas-de-aprendizagem-e-escolares-em-jovens-com-tdah/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/effet-de-la-viloxazine-sur-lapprentissage-et-les-problemes-scolaires-chez-les-jeunes-atteints-de-tdah/',
    it: 'https://it.psychopharmacologyinstitute.com/section/effetto-della-viloxazina-sullapprendimento-e-sui-problemi-scolastici-nei-giovani-con-adhd/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirkung-von-viloxazin-auf-lern-und-schulprobleme-bei-jugendlichen-mit-adhs/',
  },
  'psychotropic-prescriptions-in-youth-during-covid-19-pandemic-2746-5394': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/prescricoes-de-psicotropicos-em-jovens-durante-a-pandemia-de-covid-19/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/prescriptions-de-psychotropes-chez-les-jeunes-pendant-la-pandemie-de-covid-19/',
    it: 'https://it.psychopharmacologyinstitute.com/section/prescrizioni-di-psicotropi-nei-giovani-durante-la-pandemia-covid-19/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verschreibung-von-psychopharmaka-bei-jugendlichen-waehrend-der-covid-19-pandemie/',
  },
  'efficacy-and-safety-of-lisdexamfetamine-in-preschool-children-with-adhd-2746-5395': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-e-seguranca-da-lisdexamfetamina-em-criancas-pre-escolares-com-tdah/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-et-securite-de-la-lisdexamfetamine-chez-les-enfants-dage-prescolaire-atteints-de-tdah/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-e-sicurezza-della-lisdexamfetamina-in-bambini-in-eta-prescolare-con-adhd/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-und-sicherheit-von-lisdexamfetamin-bei-kindern-mit-adhs-im-vorschulalter/',
  },
  'a-web-based-pediatric-drug-information-system-to-prevent-serious-adverse-drug-reactions-2746-5396':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/um-sistema-de-informacoes-sobre-medicamentos-pediatricos-baseado-na-web-para-prevenir-reacoes-adversas-graves-a-medicamentos/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/un-systeme-dinformation-sur-les-medicaments-pediatriques-base-sur-le-web-pour-prevenir-les-reactions-indesirables-graves-aux-medicaments/',
      it: 'https://it.psychopharmacologyinstitute.com/section/un-sistema-informativo-sui-farmaci-pediatrici-basato-sul-web-per-prevenire-gravi-reazioni-avverse-ai-farmaci/',
      de: 'https://de.psychopharmacologyinstitute.com/section/ein-webbasiertes-paediatrisches-arzneimittelinformationssystem-zur-vermeidung-schwerwiegender-unerwuenschter-arzneimittelwirkungen/',
    },
  'updates-in-psychopharmacology-for-the-core-and-associated-symptoms-of-asd-2747-5402': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/atualizacoes-em-psicofarmacologia-para-os-sintomas-centrais-e-associados-do-tea/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/mises-a-jour-en-psychopharmacologie-pour-les-symptomes-principaux-et-associes-des-tsa/',
    it: 'https://it.psychopharmacologyinstitute.com/section/aggiornamenti-in-psicofarmacologia-per-i-sintomi-principali-e-associati-del-dsa/',
    de: 'https://de.psychopharmacologyinstitute.com/section/aktualisierungen-in-der-psychopharmakologie-bei-kernsymptomen-und-begleitsymptomen-von-ass/',
  },
  'impact-of-adhd-medications-on-anxiety-and-depression-in-youth-2747-5403': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/impacto-dos-medicamentos-para-tdah-na-ansiedade-e-na-depressao-dos-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/impact-des-medicaments-contre-le-tdah-sur-lanxiete-et-la-depression-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/impatto-dei-farmaci-per-ladhd-su-ansia-e-depressione-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/auswirkungen-von-adhs-medikamenten-auf-aengste-und-depressionen-bei-jugendlichen/',
  },
  'efficacy-safety-and-tolerability-of-ziprasidone-in-youth-2747-5404': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-seguranca-e-tolerabilidade-da-ziprasidona-em-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-securite-et-tolerance-de-la-ziprasidone-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-sicurezza-e-tollerabilita-di-ziprasidone-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-sicherheit-und-vertraeglichkeit-von-ziprasidon-bei-jugendlichen/',
  },
  'ketamine-for-adolescent-depression-2747-5405': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/cetamina-para-depressao-em-adolescentes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-ketamine-dans-le-traitement-de-la-depression-chez-ladolescent/',
    it: 'https://it.psychopharmacologyinstitute.com/section/ketamina-per-la-depressione-adolescenziale/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ketamin-bei-depressionen-bei-jugendlichen/',
  },
  'antipsychotic-induced-hyperprolactinemia-in-youth-2747-5406': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/hiperprolactinemia-induzida-por-antipsicoticos-em-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/hyperprolactinemie-induite-par-les-antipsychotiques-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/iperprolattinemia-indotta-da-antipsicotici-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/antipsychotika-induzierte-hyperprolaktinaemie-bei-jugendlichen/',
  },
  'balovaptan-for-social-communication-in-youth-with-asd-2748-5434': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/balovaptan-para-comunicacao-social-em-jovens-com-tea/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/balovaptan-pour-la-communication-sociale-chez-les-jeunes-atteints-de-tsa/',
    it: 'https://it.psychopharmacologyinstitute.com/section/balovaptan-per-la-comunicazione-sociale-nei-giovani-con-dsa/',
    de: 'https://de.psychopharmacologyinstitute.com/section/balovaptan-fuer-soziale-kommunikation-bei-jugendlichen-mit-ass/',
  },
  'risk-of-cardiovascular-diseases-associated-with-medications-used-in-adhd-2748-5435': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/risco-de-doencas-cardiovasculares-associadas-a-medicamentos-usados-no-tdah/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risque-de-maladies-cardiovasculaires-associe-aux-medicaments-utilises-dans-le-tdah/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischio-di-malattie-cardiovascolari-associato-ai-farmaci-utilizzati-per-ladhd/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiko-von-herz-kreislauf-erkrankungen-im-zusammenhang-mit-medikamenten-die-bei-adhs-eingesetzt-werden/',
  },
  'relative-age-and-psychotropic-drug-use-in-preterm-and-term-born-children-2748-5436': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/idade-relativa-e-uso-de-drogas-psicotropicas-em-criancas-nascidas-pre-termo-e-a-termo/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/age-relatif-et-consommation-de-medicaments-psychotropes-chez-les-enfants-nes-avant-terme-et-a-terme/',
    it: 'https://it.psychopharmacologyinstitute.com/section/eta-relativa-e-uso-di-psicofarmaci-nei-bambini-nati-pretermine-e-a-termine/',
    de: 'https://de.psychopharmacologyinstitute.com/section/relatives-alter-und-psychopharmaka-konsum-bei-fruehgeborenen-und-terminkindern/',
  },
  'efficacy-tolerability-and-acceptance-of-long-lasting-antipsychotics-in-youth-2748-5437': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-tolerabilidade-e-aceitacao-de-antipsicoticos-de-longa-duracao-em-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-tolerance-et-acceptation-des-antipsychotiques-de-longue-duree-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-tollerabilita-e-accettazione-degli-antipsicotici-a-lunga-durata-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-vertraeglichkeit-und-akzeptanz-von-antipsychotika-mit-langzeitwirkung-bei-jugendlichen/',
  },
  'disparities-in-pharmacologic-restraint-use-in-pediatric-emergency-departments-2748-5438': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/disparidades-no-uso-de-contencao-farmacologica-em-departamentos-de-emergencia-pediatrica/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/disparites-dans-lutilisation-de-la-contention-pharmacologique-dans-les-services-durgences-pediatriques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/disparita-nelluso-della-contenzione-farmacologica-nei-dipartimenti-di-emergenza-pediatrici/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ungleichheiten-beim-einsatz-pharmakologischer-zwangsmassnahmen-in-paediatrischen-notaufnahmen/',
  },
  'reported-side-effects-associated-with-medication-nonadherence-among-youth-with-adhd-2749-5455': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/efeitos-colaterais-relatados-associados-a-nao-adesao-a-medicacao-entre-jovens-com-tdah/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/effets-secondaires-declares-associes-a-la-non-adhesion-aux-medicaments-chez-les-jeunes-atteints-de-tdah/',
    it: 'https://it.psychopharmacologyinstitute.com/section/effetti-collaterali-riferiti-associati-alla-non-aderenza-ai-farmaci-tra-i-giovani-con-adhd/',
    de: 'https://de.psychopharmacologyinstitute.com/section/berichtetes-auftreten-von-nebenwirkungen-im-zusammenhang-mit-der-nichteinhaltung-von-medikamenten-bei-jugendlichen-mit-adhs/',
  },
  'efficacy-of-pharmacologic-interventions-for-irritability-and-emotional-dysregulation-in-asd-2749-5456':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/eficacia-das-intervencoes-farmacologicas-para-irritabilidade-e-desregulacao-emocional-no-tea/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-des-interventions-pharmacologiques-pour-lirritabilite-et-la-dysregulation-emotionnelle-dans-les-tsa/',
      it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-degli-interventi-farmacologici-per-lirritabilita-e-la-disregolazione-emotiva-nei-dsa/',
      de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-pharmakologischer-interventionen-bei-reizbarkeit-und-emotionaler-dysregulation-bei-ass/',
    },
  'psychopharmacologic-treatment-algorithms-for-bipolar-disorder-in-youth-2749-5457': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/algoritmos-de-tratamento-psicofarmacologico-para-transtorno-bipolar-em-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/algorithmes-de-traitement-psychopharmacologique-du-trouble-bipolaire-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/algoritmi-di-trattamento-psicofarmacologico-per-il-disturbo-bipolare-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/psychopharmakologische-behandlungsalgorithmen-fuer-die-bipolare-stoerung-bei-jugendlichen/',
  },
  'trends-in-antipsychotic-prescribing-in-youth-2749-5458': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tendencias-na-prescricao-de-antipsicoticos-em-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/tendances-de-la-prescription-dantipsychotiques-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/tendenze-nella-prescrizione-di-antipsicotici-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/trends-bei-der-verschreibung-von-antipsychotika-an-jugendliche/',
  },
  'cost-effectiveness-of-treatments-for-adhd-and-asd-in-youth-2749-5459': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/custo-beneficio-dos-tratamentos-para-tdah-e-tea-em-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/rapport-cout-efficacite-des-traitements-du-tdah-et-des-tsa-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/costo-efficacia-dei-trattamenti-per-adhd-e-dsa-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/kostenwirksamkeit-von-behandlungen-fuer-adhs-und-ass-bei-jugendlichen/',
  },
  'melatonin-use-and-the-risk-of-self-harm-and-unintentional-injuries-in-youth-2750-5472': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/o-uso-de-melatonina-e-o-risco-de-lesoes-autoprovocadas-e-nao-intencionais-em-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-de-la-melatonine-et-risque-dautomutilation-et-de-blessures-involontaires-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/uso-di-melatonina-e-rischio-di-autolesionismo-e-lesioni-non-intenzionali-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/melatoninkonsum-und-das-risiko-von-selbstbeschaedigung-und-unbeabsichtigten-verletzungen-bei-jugendlichen/',
  },
  'racial-disparities-in-pediatric-psychotropic-prescriptions-2750-5473': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/disparidades-raciais-nas-prescricoes-de-psicotropicos-pediatricos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/disparites-raciales-dans-les-prescriptions-pediatriques-de-psychotropes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/disparita-razziali-nelle-prescrizioni-di-psicotropi-in-eta-pediatrica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/rassendiskrepanzen-bei-der-verschreibung-paediatrischer-psychopharmaka/',
  },
  'safety-and-tolerability-of-cariprazine-in-pediatric-patients-2750-5474': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/seguranca-e-tolerabilidade-da-cariprazina-em-pacientes-pediatricos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/securite-et-tolerance-de-la-cariprazine-chez-les-enfants/',
    it: 'https://it.psychopharmacologyinstitute.com/section/sicurezza-e-tollerabilita-della-cariprazina-in-pazienti-pediatrici/',
    de: 'https://de.psychopharmacologyinstitute.com/section/sicherheit-und-vertraeglichkeit-von-cariprazin-bei-paediatrischen-patienten/',
  },
  'growth-trajectories-in-stimulant-treated-children-ages-612-2750-5475': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/trajetorias-de-crescimento-em-criancas-de-6-a-12-anos-tratadas-com-estimulantes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/trajectoires-de-croissance-chez-les-enfants-traites-aux-stimulants-ages-de-6-a-12-ans/',
    it: 'https://it.psychopharmacologyinstitute.com/section/traiettorie-di-crescita-in-bambini-trattati-con-stimolanti-di-eta-compresa-tra-i-6-e-i-12-anni/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wachstumskurven-bei-mit-stimulanzien-behandelten-kindern-im-alter-von-6-12-jahren/',
  },
  'efficacy-and-tolerability-of-antipsychotics-for-juvenile-psychotic-disorders-2750-5476': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-e-tolerabilidade-dos-antipsicoticos-para-transtornos-psicoticos-juvenis/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-et-tolerance-des-antipsychotiques-dans-les-troubles-psychotiques-juveniles/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-e-tollerabilita-degli-antipsicotici-per-i-disturbi-psicotici-giovanili/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-und-vertraeglichkeit-von-antipsychotika-bei-jugendlichen-psychotischen-stoerungen/',
  },
  'n-acetylcysteine-in-adolescent-alcohol-use-disorder-2751-5492': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/n-acetilcisteina-no-transtorno-do-uso-de-alcool-em-adolescentes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-n-acetylcysteine-dans-les-troubles-lies-a-la-consommation-dalcool-chez-les-adolescents/',
    it: 'https://it.psychopharmacologyinstitute.com/section/n-acetilcisteina-nel-disturbo-da-uso-di-alcol-negli-adolescenti/',
    de: 'https://de.psychopharmacologyinstitute.com/section/n-acetylcystein-bei-jugendlichem-alkoholkonsum/',
  },
  'metformin-for-weight-gain-in-youth-on-atypical-antipsychotics-2751-5494': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/metformina-para-ganho-de-peso-em-jovens-que-tomam-antipsicoticos-atipicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/metformine-pour-la-prise-de-poids-chez-les-jeunes-sous-antipsychotiques-atypiques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/metformina-per-laumento-di-peso-nei-giovani-in-trattamento-con-antipsicotici-atipici/',
    de: 'https://de.psychopharmacologyinstitute.com/section/metformin-bei-gewichtszunahme-bei-jugendlichen-unter-atypischen-antipsychotika/',
  },
  'prescribing-stimulants-for-youth-with-adhd-and-co-occurring-cannabis-use-2751-5495': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/prescricao-de-estimulantes-para-jovens-com-tdah-e-uso-concomitante-de-cannabis/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/prescrire-des-stimulants-aux-jeunes-souffrant-de-tdah-et-dune-consommation-concomitante-de-cannabis/',
    it: 'https://it.psychopharmacologyinstitute.com/section/prescrizione-di-stimolanti-per-giovani-con-adhd-e-uso-contemporaneo-di-cannabis/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verschreibung-von-stimulanzien-fuer-jugendliche-mit-adhs-und-gleichzeitigem-cannabiskonsum/',
  },
  'effectiveness-of-amphetamine-suspension-in-adhd-day-long-efficacy-2751-5496': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-da-suspensao-de-anfetamina-no-tdah-eficacia-de-um-dia-inteiro/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-de-la-suspension-damphetamine-dans-le-tdah-efficacite-tout-au-long-de-la-journee/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-della-sospensione-di-anfetamina-nelladhd-efficacia-per-tutto-il-giorno/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-von-amphetamin-suspensionen-bei-adhs-wirksamkeit-ueber-den-gesamten-tag/',
  },
  'pediatric-pharmacogenomic-profiling-in-the-emergency-department-2751-5497': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/perfil-farmacogenomico-pediatrico-no-departamento-de-emergencia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/profilage-pharmacogenomique-pediatrique-dans-le-service-des-urgences/',
    it: 'https://it.psychopharmacologyinstitute.com/section/profilazione-farmacogenomica-pediatrica-nel-dipartimento-di-emergenza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/paediatrische-pharmakogenomische-profilerstellung-in-der-notaufnahme/',
  },
  'managing-adverse-effects-of-antidepressants-in-youth-2752-5508': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/gerenciamento-dos-efeitos-adversos-dos-antidepressivos-em-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/gestion-des-effets-indesirables-des-antidepresseurs-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/gestione-degli-effetti-avversi-degli-antidepressivi-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/umgang-mit-unerwuenschten-wirkungen-von-antidepressiva-bei-jugendlichen/',
  },
  'ages-impact-on-antidepressant-response-2752-5509': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/impacto-da-idade-na-resposta-aos-antidepressivos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/impact-de-lage-sur-la-reponse-aux-antidepresseurs/',
    it: 'https://it.psychopharmacologyinstitute.com/section/limpatto-delleta-sulla-risposta-agli-antidepressivi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/einfluss-des-alters-auf-das-ansprechen-auf-antidepressiva/',
  },
  'lamotrigines-efficacy-and-safety-in-pediatric-mood-disorders-2752-5510': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-e-seguranca-da-lamotrigina-em-transtornos-de-humor-pediatricos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-et-securite-de-la-lamotrigine-dans-les-troubles-de-lhumeur-chez-lenfant/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-e-sicurezza-della-lamotrigina-nei-disturbi-dellumore-in-eta-pediatrica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-und-sicherheit-von-lamotrigin-bei-paediatrischen-stimmungsstoerungen/',
  },
  'managing-adhd-and-anxiety-in-children-a-clinical-guide-2752-5511': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/gerenciando-o-tdah-e-a-ansiedade-em-criancas-um-guia-clinico/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/gestion-du-tdah-et-de-lanxiete-chez-lenfant-un-guide-clinique/',
    it: 'https://it.psychopharmacologyinstitute.com/section/gestione-delladhd-e-dellansia-nei-bambini-una-guida-clinica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/umgang-mit-adhs-und-angstzustaenden-bei-kindern-ein-klinischer-leitfaden/',
  },
  'dose-titration-of-oros-methylphenidate-in-pediatric-adhd-2752-5512': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/titulacao-da-dose-de-oros-metilfenidato-no-tdah-pediatrico/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/titrage-de-la-dose-doros-methylphenidate-dans-le-tdah-pediatrique/',
    it: 'https://it.psychopharmacologyinstitute.com/section/titolazione-della-dose-di-oros-metilfenidato-nelladhd-pediatrico/',
    de: 'https://de.psychopharmacologyinstitute.com/section/dosistitration-von-oros-methylphenidat-bei-paediatrischer-adhs/',
  },
  'psychosocial-functioning-in-transgender-youth-after-2-years-of-hormones-2753-5537': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/funcionamento-psicossocial-em-jovens-transgeneros-apos-2-anos-de-uso-de-hormonios/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/fonctionnement-psychosocial-chez-les-jeunes-transgenres-apres-deux-ans-dhormones/',
    it: 'https://it.psychopharmacologyinstitute.com/section/funzionamento-psicosociale-nei-giovani-transgender-dopo-2-anni-di-ormoni/',
    de: 'https://de.psychopharmacologyinstitute.com/section/psychosoziales-funktionieren-bei-transgender-jugendlichen-nach-2-jahren-hormonbehandlung/',
  },
  'risk-of-major-malformations-following-first-trimester-exposure-to-olanzapine-2753-5538': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/risco-de-malformacoes-graves-apos-exposicao-a-olanzapina-no-primeiro-trimestre/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risque-de-malformations-majeures-apres-exposition-a-lolanzapine-pendant-le-premier-trimestre-de-grossesse/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischio-di-malformazioni-maggiori-in-seguito-allesposizione-a-olanzapina-nel-primo-trimestre-di-gravidanza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiko-schwerer-missbildungen-nach-ersttrimester-exposition-mit-olanzapin/',
  },
  'personalizing-atomoxetine-dosing-in-children-with-adhd-2753-5539': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/personalizacao-da-dosagem-de-atomoxetina-em-criancas-com-tdah/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/personnalisation-du-dosage-de-latomoxetine-chez-les-enfants-atteints-de-tdah/',
    it: 'https://it.psychopharmacologyinstitute.com/section/personalizzazione-del-dosaggio-di-atomoxetina-nei-bambini-con-adhd/',
    de: 'https://de.psychopharmacologyinstitute.com/section/personalisierung-der-atomoxetin-dosierung-bei-kindern-mit-adhs/',
  },
  'safety-and-tolerability-of-serdexmethylphenidatedexmethylphenidate-in-children-with-adhd-2753-5540':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/seguranca-e-tolerabilidade-do-serdexmetilfenidato-dexmetilfenidato-em-criancas-com-tdah/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/securite-et-tolerance-du-serdexmethylphenidate-dexmethylphenidate-chez-les-enfants-atteints-de-tdah/',
      it: 'https://it.psychopharmacologyinstitute.com/section/sicurezza-e-tollerabilita-di-serdexmetilfenidato-dexmetilfenidato-in-bambini-con-adhd/',
      de: 'https://de.psychopharmacologyinstitute.com/section/sicherheit-und-vertraeglichkeit-von-serdexmethylphenidat-dexmethylphenidat-bei-kindern-mit-adhs/',
    },
  'use-of-lithium-in-pediatric-bipolar-disorders-and-externalizing-childhood-related-disorders-2753-5541':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/uso-de-litio-em-transtornos-bipolares-pediatricos-e-transtornos-externalizantes-relacionados-a-infancia/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-du-lithium-dans-les-troubles-bipolaires-pediatriques-et-les-troubles-exteriorises-lies-a-lenfance/',
      it: 'https://it.psychopharmacologyinstitute.com/section/uso-del-litio-nei-disturbi-bipolari-pediatrici-e-nei-disturbi-esternalizzanti-dellinfanzia/',
      de: 'https://de.psychopharmacologyinstitute.com/section/einsatz-von-lithium-bei-paediatrischen-bipolaren-stoerungen-und-externalisierenden-stoerungen-im-kindesalter/',
    },
  'relapse-prevention-strategies-for-depression-and-anxiety-in-remitted-youth-2779-5547': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/estrategias-de-prevencao-de-recaida-para-depressao-e-ansiedade-em-jovens-com-remissao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/strategies-de-prevention-des-rechutes-pour-la-depression-et-lanxiete-chez-les-jeunes-en-remission/',
    it: 'https://it.psychopharmacologyinstitute.com/section/strategie-di-prevenzione-delle-ricadute-per-la-depressione-e-lansia-nei-giovani-rimossi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/strategien-zur-rueckfallpraevention-bei-depressionen-und-angstzustaenden-bei-remittierten-jugendlichen/',
  },
  'antipsychotics-in-the-treatment-of-youth-with-anorexia-nervosa-2779-5548': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/antipsicoticos-no-tratamento-de-jovens-com-anorexia-nervosa/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/les-antipsychotiques-dans-le-traitement-des-jeunes-atteints-danorexie-mentale/',
    it: 'https://it.psychopharmacologyinstitute.com/section/antipsicotici-nel-trattamento-di-giovani-con-anoressia-nervosa/',
    de: 'https://de.psychopharmacologyinstitute.com/section/antipsychotika-bei-der-behandlung-von-jugendlichen-mit-anorexia-nervosa/',
  },
  'effects-of-long-term-sertraline-use-on-pediatric-growth-and-development-2779-5549': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/efeitos-do-uso-prolongado-de-sertralina-no-crescimento-e-desenvolvimento-pediatrico/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/effets-de-lutilisation-a-long-terme-de-la-sertraline-sur-la-croissance-et-le-developpement-des-enfants/',
    it: 'https://it.psychopharmacologyinstitute.com/section/effetti-delluso-a-lungo-termine-della-sertralina-sulla-crescita-e-sullo-sviluppo-in-eta-pediatrica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/auswirkungen-der-langzeiteinnahme-von-sertralin-auf-wachstum-und-entwicklung-von-kindern/',
  },
  'dissociative-effects-and-response-to-ketamine-in-youth-with-treatment-resistant-depression-2779-5551':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/efeitos-dissociativos-e-resposta-a-cetamina-em-jovens-com-depressao-resistente-ao-tratamento/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/effets-dissociatifs-et-reponse-a-la-ketamine-chez-les-jeunes-souffrant-de-depression-resistante-au-traitement/',
      it: 'https://it.psychopharmacologyinstitute.com/section/effetti-dissociativi-e-risposta-alla-ketamina-in-giovani-con-depressione-resistente-al-trattamento/',
      de: 'https://de.psychopharmacologyinstitute.com/section/dissoziative-effekte-und-reaktion-auf-ketamin-bei-jugendlichen-mit-behandlungsresistenten-depressionen/',
    },
  'long-acting-injectable-antipsychotics-for-schizophrenia-spectrum-disorders-in-youth-2779-5552': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/antipsicoticos-injetaveis-de-acao-prolongada-para-transtornos-do-espectro-da-esquizofrenia-em-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/antipsychotiques-injectables-a-longue-duree-daction-pour-les-troubles-du-spectre-de-la-schizophrenie-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/antipsicotici-iniettabili-a-lunga-durata-dazione-per-i-disturbi-dello-spettro-schizofrenico-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/langwirksame-injizierbare-antipsychotika-zur-behandlung-von-schizophrenie-spektrum-stoerungen-bei-jugendlichen/',
  },
  'adverse-effects-of-2-adrenergic-agonists-and-stimulants-in-preschool-age-adhd-2780-5576': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/efeitos-adversos-dos-agonistas-%ce%b1-2-adrenergicos-e-estimulantes-no-tdah-em-idade-pre-escolar/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/effets-indesirables-des-agonistes-%ce%b1-2-adrenergiques-et-des-stimulants-dans-le-tdah-a-lage-prescolaire/',
    it: 'https://it.psychopharmacologyinstitute.com/section/effetti-avversi-degli-agonisti-%ce%b1-2-adrenergici-e-degli-stimolanti-nelladhd-in-eta-prescolare/',
    de: 'https://de.psychopharmacologyinstitute.com/section/unerwuenschte-wirkungen-von-%ce%b1-2-adrenergen-agonisten-und-stimulanzien-bei-adhs-im-vorschulalter/',
  },
  'in-utero-exposure-to-adhd-medication-and-long-term-offspring-outcomes-2780-5577': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/exposicao-in-utero-a-medicamentos-para-tdah-e-resultados-de-longo-prazo-da-prole/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/exposition-in-utero-aux-medicaments-contre-le-tdah-et-effets-a-long-terme-sur-la-progeniture/',
    it: 'https://it.psychopharmacologyinstitute.com/section/lesposizione-in-utero-ai-farmaci-per-ladhd-e-gli-esiti-a-lungo-termine-della-prole/',
    de: 'https://de.psychopharmacologyinstitute.com/section/in-utero-exposition-gegenueber-adhs-medikamenten-und-langfristige-ergebnisse-bei-den-nachkommen/',
  },
  'is-fish-oil-effective-in-youth-depression-2780-5578': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/o-oleo-de-peixe-e-eficaz-no-tratamento-da-depressao-juvenil/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/lhuile-de-poisson-est-elle-efficace-contre-la-depression-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/lolio-di-pesce-e-efficace-nella-depressione-giovanile/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ist-fischoel-bei-jugenddepressionen-wirksam/',
  },
  'early-childhood-general-anesthesia-and-risk-of-adhd-2780-5579': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/anestesia-geral-na-primeira-infancia-e-risco-de-tdah/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/anesthesie-generale-dans-la-petite-enfance-et-risque-de-tdah/',
    it: 'https://it.psychopharmacologyinstitute.com/section/anestesia-generale-nella-prima-infanzia-e-rischio-di-adhd/',
    de: 'https://de.psychopharmacologyinstitute.com/section/fruehkindliche-allgemeinanaesthesie-und-risiko-fuer-adhs/',
  },
  'association-of-benzodiazepines-with-drug-overdose-risk-among-young-people-2780-5580': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/associacao-de-benzodiazepinicos-com-o-risco-de-overdose-de-drogas-entre-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/association-entre-les-benzodiazepines-et-le-risque-de-surdose-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/associazione-delle-benzodiazepine-con-il-rischio-di-overdose-tra-i-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/zusammenhang-zwischen-benzodiazepinen-und-dem-risiko-einer-drogenueberdosierung-bei-jungen-menschen/',
  },
  'effect-of-methylphenidate-on-pain-perception-thresholds-in-children-with-adhd-2781-5596': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/efeito-do-metilfenidato-nos-limiares-de-percepcao-da-dor-em-criancas-com-tdah/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/effet-du-methylphenidate-sur-les-seuils-de-perception-de-la-douleur-chez-les-enfants-atteints-de-tdah/',
    it: 'https://it.psychopharmacologyinstitute.com/section/effetto-del-metilfenidato-sulla-soglia-di-percezione-del-dolore-nei-bambini-con-adhd/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirkung-von-methylphenidat-auf-die-schwellenwerte-der-schmerzwahrnehmung-bei-kindern-mit-adhs/',
  },
  'persistence-of-autism-spectrum-disorder-from-early-childhood-through-school-age-2781-5597': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/persistencia-do-transtorno-do-espectro-do-autismo-desde-a-primeira-infancia-ate-a-idade-escolar/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/persistance-des-troubles-du-spectre-autistique-de-la-petite-enfance-a-lage-scolaire/',
    it: 'https://it.psychopharmacologyinstitute.com/section/persistenza-del-disturbo-dello-spettro-autistico-dalla-prima-infanzia-alleta-scolare/',
    de: 'https://de.psychopharmacologyinstitute.com/section/persistenz-der-autismus-spektrum-stoerung-von-der-fruehen-kindheit-bis-zum-schulalter/',
  },
  'adolescent-depression-and-anxiety-symptoms-during-the-covid-19-pandemic-2781-5598': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/sintomas-de-depressao-e-ansiedade-em-adolescentes-durante-a-pandemia-de-covid-19/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/symptomes-de-depression-et-danxiete-chez-les-adolescents-pendant-la-pandemie-de-covid-19/',
    it: 'https://it.psychopharmacologyinstitute.com/section/depressione-e-sintomi-dansia-negli-adolescenti-durante-la-pandemia-covid-19/',
    de: 'https://de.psychopharmacologyinstitute.com/section/depressionen-und-angstsymptome-bei-jugendlichen-waehrend-der-covid-19-pandemie/',
  },
  'adoption-of-vaping-cessation-methods-by-us-adolescents-2781-5599': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/adocao-de-metodos-de-cessacao-do-vaping-por-adolescentes-dos-eua-adolescentes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/adoption-des-methodes-darret-du-vapotage-par-les-adolescents-americains-adolescents-americains/',
    it: 'https://it.psychopharmacologyinstitute.com/section/adozione-di-metodi-di-disassuefazione-dal-vaping-da-parte-di-adolescenti-statunitensi-adolescenti/',
    de: 'https://de.psychopharmacologyinstitute.com/section/annahme-von-methoden-zur-tabakentwoehnung-durch-u-s-heranwachsende/',
  },
  'pediatric-adhd-medication-errors-reported-to-united-states-poison-centers-2781-5600': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/erros-de-medicacao-pediatrica-para-tdah-relatados-aos-centros-de-intoxicacao-dos-estados-unidos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/erreurs-de-medication-pediatrique-pour-le-tdah-signalees-aux-centres-antipoison-des-etats-unis/',
    it: 'https://it.psychopharmacologyinstitute.com/section/errori-di-somministrazione-di-farmaci-per-ladhd-in-eta-pediatrica-segnalati-ai-centri-antiveleni-degli-stati-uniti/',
    de: 'https://de.psychopharmacologyinstitute.com/section/paediatrische-adhs-medikamentenfehler-die-den-giftnotrufzentralen-der-vereinigten-staaten-gemeldet-wurden/',
  },
  'the-role-of-escitalopram-in-treating-generalized-anxiety-disorder-in-youth-2782-5628': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/o-papel-do-escitalopram-no-tratamento-do-transtorno-de-ansiedade-generalizada-em-jovens/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/le-role-de-lescitalopram-dans-le-traitement-du-trouble-anxieux-generalise-chez-les-jeunes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/il-ruolo-di-escitalopram-nel-trattamento-del-disturbo-dansia-generalizzato-nei-giovani/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-rolle-von-escitalopram-bei-der-behandlung-der-generalisierten-angststoerung-bei-jugendlichen/',
  },
  'vortioxetines-impact-on-sleep-architecture-in-depressed-adolescents-2782-5629': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/o-impacto-da-vortioxetina-na-arquitetura-do-sono-em-adolescentes-deprimidos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/impact-de-la-vortioxetine-sur-larchitecture-du-sommeil-chez-les-adolescents-deprimes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/impatto-della-vortioxetina-sullarchitettura-del-sonno-in-adolescenti-depressi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/auswirkungen-von-vortioxetin-auf-die-schlafarchitektur-bei-depressiven-jugendlichen/',
  },
  'exploring-aripiprazole-lauroxil-use-in-adolescent-psychiatry-2782-5630': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/explorando-o-uso-do-aripiprazol-lauroxil-na-psiquiatria-do-adolescente/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/exploration-de-lutilisation-de-laripiprazole-lauroxil-en-psychiatrie-de-ladolescent/',
    it: 'https://it.psychopharmacologyinstitute.com/section/esplorazione-delluso-di-aripiprazolo-lauroxil-nella-psichiatria-degli-adolescenti/',
    de: 'https://de.psychopharmacologyinstitute.com/section/untersuchung-der-verwendung-von-aripiprazol-lauroxil-in-der-jugendpsychiatrie/',
  },
  'esketamine-a-potential-alternative-treatment-for-youth-depression-and-its-cognitive-effects-2782-5631':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/esketamina-um-possivel-tratamento-alternativo-para-a-depressao-juvenil-e-seus-efeitos-cognitivos/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/lesketamine-un-traitement-alternatif-potentiel-de-la-depression-chez-les-jeunes-et-ses-effets-cognitifs/',
      it: 'https://it.psychopharmacologyinstitute.com/section/esketamina-un-potenziale-trattamento-alternativo-per-la-depressione-giovanile-e-i-suoi-effetti-cognitivi/',
      de: 'https://de.psychopharmacologyinstitute.com/section/esketamin-eine-moegliche-alternative-behandlung-von-jugenddepressionen-und-ihre-kognitiven-auswirkungen/',
    },
  'efficacy-of-risperidone-as-treatment-for-anorexia-nervosa-2782-5632': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-da-risperidona-como-tratamento-para-anorexia-nervosa/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-de-la-risperidone-dans-le-traitement-de-lanorexie-mentale/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-del-risperidone-come-trattamento-dellanoressia-nervosa/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-von-risperidon-zur-behandlung-von-anorexia-nervosa/',
  },
  'remission-and-relapse-in-pediatric-obsessive-compulsive-disorder-2783-5657': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/remissao-e-recaida-no-transtorno-obsessivo-compulsivo-pediatrico/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/remission-et-rechute-dans-les-troubles-obsessionnels-compulsifs-pediatriques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/remissione-e-ricaduta-nel-disturbo-ossessivo-compulsivo-pediatrico/',
    de: 'https://de.psychopharmacologyinstitute.com/section/remission-und-rueckfall-bei-paediatrischer-zwangsneurose/',
  },
  'efficacy-of-probiotics-in-treating-adhd-symptoms-2783-5658': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-dos-probioticos-no-tratamento-dos-sintomas-do-tdah/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-des-probiotiques-dans-le-traitement-des-symptomes-du-tdah/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-dei-probiotici-nel-trattamento-dei-sintomi-delladhd/',
    de: 'https://de.psychopharmacologyinstitute.com/section/wirksamkeit-von-probiotika-bei-der-behandlung-von-adhs-symptomen/',
  },
  'electroconvulsive-therapy-for-adolescents-with-severe-depression-and-suicidality-2783-5659': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/terapia-eletroconvulsiva-para-adolescentes-com-depressao-grave-e-suicidio/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/therapie-electroconvulsive-pour-les-adolescents-souffrant-de-depression-severe-et-de-suicidalite/',
    it: 'https://it.psychopharmacologyinstitute.com/section/terapia-elettroconvulsivante-per-adolescenti-con-depressione-grave-e-suicidalita/',
    de: 'https://de.psychopharmacologyinstitute.com/section/elektrokrampftherapie-fuer-jugendliche-mit-schweren-depressionen-und-suizidalitaet/',
  },
  'noninvasive-brain-stimulation-in-children-2783-5660': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/estimulacao-cerebral-nao-invasiva-em-criancas/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/stimulation-cerebrale-non-invasive-chez-lenfant/',
    it: 'https://it.psychopharmacologyinstitute.com/section/stimolazione-cerebrale-non-invasiva-nei-bambini/',
    de: 'https://de.psychopharmacologyinstitute.com/section/nichtinvasive-hirnstimulation-bei-kindern/',
  },
  'long-term-efficacy-of-escitalopram-in-adolescents-with-major-depressive-disorder-2783-5661': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/eficacia-a-longo-prazo-do-escitalopram-em-adolescentes-com-transtorno-depressivo-maior/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/efficacite-a-long-terme-de-lescitalopram-chez-les-adolescents-souffrant-de-troubles-depressifs-majeurs/',
    it: 'https://it.psychopharmacologyinstitute.com/section/efficacia-a-lungo-termine-di-escitalopram-negli-adolescenti-con-disturbo-depressivo-maggiore/',
    de: 'https://de.psychopharmacologyinstitute.com/section/langfristige-wirksamkeit-von-escitalopram-bei-jugendlichen-mit-schwerer-depressiver-stoerung/',
  },
  'the-psychopharmacology-of-agitation-managing-behavioral-emergencies-2786-5642': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/a-psicofarmacologia-da-agitacao-gerenciando-emergencias-comportamentais/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-psychopharmacologie-de-lagitation-gerer-les-urgences-comportementales/',
    it: 'https://it.psychopharmacologyinstitute.com/section/la-psicofarmacologia-dellagitazione-gestione-delle-emergenze-comportamentali/',
    de: 'https://de.psychopharmacologyinstitute.com/section/psychopharmakologie-der-erregung-umgang-mit-verhaltensbedingten-notfaellen/',
  },
  'assessing-agitation-medical-evaluation-rating-scales-and-differential-diagnosis-2786-5643': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/avaliacao-da-agitacao-avaliacao-medica-escalas-de-classificacao-e-diagnostico-diferencial/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/evaluation-de-lagitation-evaluation-medicale-echelles-devaluation-et-diagnostic-differentiel/',
    it: 'https://it.psychopharmacologyinstitute.com/section/valutazione-dellagitazione-valutazione-medica-scale-di-valutazione-e-diagnosi-differenziale/',
    de: 'https://de.psychopharmacologyinstitute.com/section/beurteilung-von-erregungszustaenden-medizinische-bewertung-bewertungsskalen-und-differentialdiagnose/',
  },
  'take-home-messages-2786-5651': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-6/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-6/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-6/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-6/',
  },
  'clinical-vignette-an-alternative-approach-to-agitated-patients-2786-5650': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/vinheta-clinica-uma-abordagem-alternativa-para-pacientes-agitados/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/vignette-clinique-une-approche-alternative-des-patients-agites/',
    it: 'https://it.psychopharmacologyinstitute.com/section/vignetta-clinica-un-approccio-alternativo-ai-pazienti-agitati/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinische-vignette-ein-alternativer-ansatz-fuer-unruhige-patienten/',
  },
  'the-role-of-benzodiazepines-and-dexmedetomidine-in-managing-agitation-2786-5649': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/o-papel-dos-benzodiazepinicos-e-da-dexmedetomidina-no-controle-da-agitacao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/le-role-des-benzodiazepines-et-de-la-dexmedetomidine-dans-la-gestion-de-lagitation/',
    it: 'https://it.psychopharmacologyinstitute.com/section/il-ruolo-delle-benzodiazepine-e-della-dexmedetomidina-nella-gestione-dellagitazione/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-rolle-von-benzodiazepinen-und-dexmedetomidin-bei-der-behandlung-von-erregung/',
  },
  'the-role-of-antipsychotics-in-managing-agitation-2786-5648': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/o-papel-dos-antipsicoticos-no-controle-da-agitacao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/le-role-des-antipsychotiques-dans-la-gestion-de-lagitation/',
    it: 'https://it.psychopharmacologyinstitute.com/section/il-ruolo-degli-antipsicotici-nella-gestione-dellagitazione/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-rolle-von-antipsychotika-bei-der-behandlung-von-unruhezustaenden/',
  },
  'pharmacologic-strategies-for-managing-agitation-general-recommendations-and-treatments-goals-2786-5647':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/estrategias-farmacologicas-para-o-controle-da-agitacao-recomendacoes-gerais-e-metas-de-tratamento/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/strategies-pharmacologiques-pour-la-prise-en-charge-de-lagitation-recommandations-generales-et-objectifs-de-traitement/',
      it: 'https://it.psychopharmacologyinstitute.com/section/strategie-farmacologiche-per-la-gestione-dellagitazione-raccomandazioni-generali-e-obiettivi-di-trattamento/',
      de: 'https://de.psychopharmacologyinstitute.com/section/pharmakologische-strategien-zur-behandlung-von-unruhezustaenden-allgemeine-empfehlungen-und-behandlungsziele/',
    },
  'nonpharmacologic-strategies-for-managing-agitation-verbal-de-escalation-2786-5646': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/estrategias-nao-farmacologicas-para-controlar-a-agitacao-reducao-da-escalada-verbal/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/strategies-non-pharmacologiques-de-gestion-de-lagitation-desescalade-verbale/',
    it: 'https://it.psychopharmacologyinstitute.com/section/strategie-non-farmacologiche-per-la-gestione-dellagitazione-la-de-escalation-verbale/',
    de: 'https://de.psychopharmacologyinstitute.com/section/nicht-pharmakologische-strategien-zur-bewaeltigung-von-erregung-verbale-deeskalation/',
  },
  'other-psychiatric-diagnoses-in-agitated-patients-mood-and-substance-use-disorders-2786-5645': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/outros-diagnosticos-psiquiatricos-em-pacientes-agitados-transtornos-de-humor-e-de-uso-de-substancias/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/autres-diagnostics-psychiatriques-chez-les-patients-agites-troubles-de-lhumeur-et-troubles-lies-a-lutilisation-de-substances-psychoactives/',
    it: 'https://it.psychopharmacologyinstitute.com/section/altre-diagnosi-psichiatriche-in-pazienti-agitati-disturbi-dellumore-e-da-uso-di-sostanze/',
    de: 'https://de.psychopharmacologyinstitute.com/section/andere-psychiatrische-diagnosen-bei-unruhigen-patienten-gemuets-und-drogenkonsumstoerungen/',
  },
  'psychiatric-diagnoses-in-agitated-patients-delirium-dementia-and-psychotic-disorders-2786-5644':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/diagnosticos-psiquiatricos-em-pacientes-agitados-delirium-demencia-e-transtornos-psicoticos/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/diagnostics-psychiatriques-chez-les-patients-agites-delirium-demence-et-troubles-psychotiques/',
      it: 'https://it.psychopharmacologyinstitute.com/section/diagnosi-psichiatriche-in-pazienti-agitati-delirium-demenza-e-disturbi-psicotici/',
      de: 'https://de.psychopharmacologyinstitute.com/section/psychiatrische-diagnosen-bei-unruhigen-patienten-delirium-demenz-und-psychotische-stoerungen/',
    },
  'exploring-lamotrigine-indications-and-off-label-uses-2791-5633': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/explorando-as-indicacoes-e-os-usos-off-label-da-lamotrigina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/exploration-des-indications-de-la-lamotrigine-et-de-ses-utilisations-hors-indications/',
    it: 'https://it.psychopharmacologyinstitute.com/section/esplorazione-delle-indicazioni-e-degli-usi-off-label-della-lamotrigina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/erkundung-von-lamotrigin-indikationen-und-off-label-anwendungen/',
  },
  'take-home-messages-2791-5641': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-7/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-7/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-7/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-7/',
  },
  'a-practical-approach-in-ms-as-case-2791-5640': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uma-abordagem-pratica-no-caso-do-ms-a-s-case/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/une-approche-pratique-dans-le-cas-de-ms-a/',
    it: 'https://it.psychopharmacologyinstitute.com/section/un-approccio-pratico-nel-caso-di-ms-il-caso-di-a/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ein-praktischer-ansatz-in-ms-a-s-fall/',
  },
  'strategies-for-safe-usage-and-proper-monitoring-of-lamotrigine-2791-5639': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/estrategias-para-uso-seguro-e-monitoramento-adequado-da-lamotrigina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/strategies-pour-une-utilisation-sure-et-une-surveillance-adequate-de-la-lamotrigine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/strategie-per-luso-sicuro-e-il-corretto-monitoraggio-della-lamotrigina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/strategien-fuer-die-sichere-anwendung-und-angemessene-ueberwachung-von-lamotrigin/',
  },
  'other-cardiac-complications-associated-with-lamotrigine-use-2791-5638': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/outras-complicacoes-cardiacas-associadas-ao-uso-da-lamotrigina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/autres-complications-cardiaques-associees-a-lutilisation-de-la-lamotrigine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/altre-complicazioni-cardiache-associate-alluso-di-lamotrigina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/andere-kardiale-komplikationen-im-zusammenhang-mit-der-einnahme-von-lamotrigin/',
  },
  'lamotrigine-effect-on-qtc-prolongation-and-qrs-widening-2791-5637': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/efeito-da-lamotrigina-no-prolongamento-do-qtc-e-no-alargamento-do-qrs/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/effet-de-la-lamotrigine-sur-lallongement-du-qtc-et-lelargissement-du-qrs/',
    it: 'https://it.psychopharmacologyinstitute.com/section/effetto-della-lamotrigina-sul-prolungamento-del-qtc-e-sullampliamento-del-qrs/',
    de: 'https://de.psychopharmacologyinstitute.com/section/lamotriginwirkung-auf-qtc-verlaengerung-und-qrs-verbreiterung/',
  },
  'exploring-potential-cardiac-side-effects-of-lamotrigine-and-ms-a-case-2791-5636': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/explorando-os-possiveis-efeitos-colaterais-cardiacos-da-lamotrigina-e-da-ms-um-caso/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/exploration-des-effets-secondaires-cardiaques-potentiels-de-la-lamotrigine-et-de-la-sclerose-en-plaques-un-cas/',
    it: 'https://it.psychopharmacologyinstitute.com/section/esplorazione-dei-potenziali-effetti-collaterali-cardiaci-di-lamotrigina-e-ms-un-caso/',
    de: 'https://de.psychopharmacologyinstitute.com/section/erforschung-moeglicher-kardialer-nebenwirkungen-von-lamotrigin-und-ms-ein-fall/',
  },
  'lamotrigine-side-effects-and-special-considerations-2791-5635': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/lamotrigina-efeitos-colaterais-e-consideracoes-especiais/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/lamotrigine-effets-secondaires-et-considerations-particulieres/',
    it: 'https://it.psychopharmacologyinstitute.com/section/lamotrigina-effetti-collaterali-e-considerazioni-speciali/',
    de: 'https://de.psychopharmacologyinstitute.com/section/lamotrigin-nebenwirkungen-und-besondere-ueberlegungen/',
  },
  'understanding-lamotrigine-mechanisms-and-titration-schedule-2791-5634': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/compreensao-dos-mecanismos-da-lamotrigina-e-do-cronograma-de-titulacao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-les-mecanismes-de-la-lamotrigine-et-le-calendrier-de-titration/',
    it: 'https://it.psychopharmacologyinstitute.com/section/comprensione-dei-meccanismi-della-lamotrigina-e-dello-schema-di-titolazione/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verstaendnis-der-lamotrigin-mechanismen-und-des-titrationsplans/',
  },
  'take-home-messages-2784-5617': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-5/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-5/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-5/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-5/',
  },
  'the-case-of-m-part-2-2784-5616': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/o-caso-de-m-parte-2/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/le-cas-de-m-partie-2/',
    it: 'https://it.psychopharmacologyinstitute.com/section/il-caso-di-m-parte-2/',
    de: 'https://de.psychopharmacologyinstitute.com/section/der-fall-m-teil-2/',
  },
  'the-case-of-m-part-1-2784-5615': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/o-caso-de-m-parte-1/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/le-cas-de-m-partie-1/',
    it: 'https://it.psychopharmacologyinstitute.com/section/il-caso-di-m-parte-1/',
    de: 'https://de.psychopharmacologyinstitute.com/section/der-fall-m-teil-1/',
  },
  'psychotherapy-skills-in-everyday-practice-ordinary-medical-psychotherapy-2784-5614': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/habilidades-de-psicoterapia-na-pratica-cotidiana-psicoterapia-medica-comum/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/competences-psychotherapeutiques-dans-la-pratique-quotidienne-psychotherapie-medicale-ordinaire/',
    it: 'https://it.psychopharmacologyinstitute.com/section/abilita-psicoterapeutiche-nella-pratica-quotidiana-psicoterapia-medica-ordinaria/',
    de: 'https://de.psychopharmacologyinstitute.com/section/psychotherapeutische-faehigkeiten-in-der-taeglichen-praxis-normale-medizinische-psychotherapie/',
  },
  'pharmacotherapy-alliance-treatment-preferences-and-communication-2784-5613': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/alianca-farmacoterapeutica-preferencias-de-tratamento-e-comunicacao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/alliance-pharmacotherapeutique-preferences-therapeutiques-et-communication/',
    it: 'https://it.psychopharmacologyinstitute.com/section/alleanza-farmacoterapeutica-preferenze-terapeutiche-e-comunicazione/',
    de: 'https://de.psychopharmacologyinstitute.com/section/pharmakotherapie-allianz-behandlungspraeferenzen-und-kommunikation/',
  },
  'the-biological-theory-of-illness-and-stigma-2784-5612': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/a-teoria-biologica-da-doenca-e-o-estigma/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-theorie-biologique-de-la-maladie-et-la-stigmatisation/',
    it: 'https://it.psychopharmacologyinstitute.com/section/la-teoria-biologica-della-malattia-e-lo-stigma/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-biologische-theorie-der-krankheit-und-der-stigmatisierung/',
  },
  'the-science-of-the-art-of-psychopharmacotherapy-2784-5611': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/a-ciencia-da-arte-da-psicofarmacoterapia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-science-de-lart-de-la-psychopharmacotherapie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/la-scienza-dellarte-della-psicofarmacoterapia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-wissenschaft-von-der-kunst-der-psychopharmakotherapie/',
  },
  'take-home-messages-2765-5610': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen/',
  },
  'clinical-vignette-mr-b-2765-5609': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/vinheta-clinica-sr-b/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/vignette-clinique-m-b/',
    it: 'https://it.psychopharmacologyinstitute.com/section/vignetta-clinica-il-signor-b/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinische-vignette-herr-b/',
  },
  'role-of-ketamine-and-neurostimulation-treatments-in-insomnia-comorbid-with-mdd-2765-5608': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/papel-dos-tratamentos-com-cetamina-e-neuroestimulacao-na-insonia-comorbida-com-tdm/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/role-de-la-ketamine-et-des-traitements-par-neurostimulation-dans-linsomnie-comorbide-avec-la-tdm/',
    it: 'https://it.psychopharmacologyinstitute.com/section/ruolo-dei-trattamenti-con-ketamina-e-neurostimolazione-nellinsonnia-in-comorbilita-con-ddm/',
    de: 'https://de.psychopharmacologyinstitute.com/section/rolle-von-ketamin-und-neurostimulationsbehandlungen-bei-schlaflosigkeit-in-verbindung-mit-mdd/',
  },
  'treatment-strategies-for-managing-insomnia-in-mdd-2765-5607': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/estrategias-de-tratamento-para-controlar-a-insonia-no-tdm/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/strategies-de-traitement-de-linsomnie-dans-la-tdm/',
    it: 'https://it.psychopharmacologyinstitute.com/section/strategie-di-trattamento-per-la-gestione-dellinsonnia-nel-ddm/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlungsstrategien-zur-behandlung-von-schlaflosigkeit-bei-mdd/',
  },
  'other-pharmacologic-interventions-for-insomnia-2765-5606': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/outras-intervencoes-farmacologicas-para-insonia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/autres-interventions-pharmacologiques-pour-linsomnie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/altri-interventi-farmacologici-per-linsonnia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/andere-pharmakologische-interventionen-bei-schlaflosigkeit/',
  },
  'pharmacologic-interventions-for-insomnia-benzodiazepines-and-non-benzodiazepine-hypnotics-2765-5605':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/intervencoes-farmacologicas-para-insonia-benzodiazepinicos-e-hipnoticos-nao-benzodiazepinicos/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/interventions-pharmacologiques-pour-linsomnie-benzodiazepines-et-hypnotiques-non-benzodiazepiniques/',
      it: 'https://it.psychopharmacologyinstitute.com/section/interventi-farmacologici-per-linsonnia-benzodiazepine-e-ipnotici-non-benzodiazepinici/',
      de: 'https://de.psychopharmacologyinstitute.com/section/pharmakologische-interventionen-bei-schlaflosigkeit-benzodiazepine-und-nicht-benzodiazepinhaltige-hypnotika/',
    },
  'nonpharmacologic-interventions-for-insomnia-2765-5604': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/intervencoes-nao-farmacologicas-para-insonia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/interventions-non-pharmacologiques-pour-linsomnie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/interventi-non-farmacologici-per-linsonnia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/nicht-pharmakologische-interventionen-bei-schlaflosigkeit/',
  },
  'diagnostic-approaches-and-assessment-strategies-for-insomnia-2765-5603': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/abordagens-diagnosticas-e-estrategias-de-avaliacao-para-insonia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/approches-diagnostiques-et-strategies-devaluation-de-linsomnie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/approcci-diagnostici-e-strategie-di-valutazione-dellinsonnia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/diagnostische-ansaetze-und-bewertungsstrategien-fuer-schlaflosigkeit/',
  },
  'understanding-the-relationship-between-insomnia-disorder-and-mdd-2765-5602': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/entendendo-a-relacao-entre-o-transtorno-de-insonia-e-o-tdm/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-la-relation-entre-les-troubles-de-linsomnie-et-la-tdm/',
    it: 'https://it.psychopharmacologyinstitute.com/section/comprendere-la-relazione-tra-disturbo-da-insonnia-e-ddm/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verstaendnis-der-beziehung-zwischen-schlaflosigkeit-und-mdd/',
  },
  'learning-the-basics-of-sleep-2765-5601': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/aprendendo-os-conceitos-basicos-do-sono/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/apprendre-les-bases-du-sommeil/',
    it: 'https://it.psychopharmacologyinstitute.com/section/imparare-le-basi-del-sonno/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-grundlagen-des-schlafs-lernen/',
  },
  'take-home-messages-2768-5590': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-4/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-4/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-4/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-4/',
  },
  'recommendations-for-using-natural-medication-in-psychiatric-disorders-2768-5589': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/recomendacoes-para-o-uso-de-medicamentos-naturais-em-transtornos-psiquiatricos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/recommandations-pour-lutilisation-de-medicaments-naturels-dans-les-troubles-psychiatriques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/raccomandazioni-per-luso-di-farmaci-naturali-nei-disturbi-psichiatrici/',
    de: 'https://de.psychopharmacologyinstitute.com/section/empfehlungen-fuer-den-einsatz-natuerlicher-medikamente-bei-psychiatrischen-stoerungen/',
  },
  'potential-combinations-of-natural-medication-for-psychiatric-disorders-2768-5588': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/possiveis-combinacoes-de-medicamentos-naturais-para-transtornos-psiquiatricos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/combinaisons-potentielles-de-medicaments-naturels-pour-les-troubles-psychiatriques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/potenziali-combinazioni-di-farmaci-naturali-per-i-disturbi-psichiatrici/',
    de: 'https://de.psychopharmacologyinstitute.com/section/moegliche-kombinationen-von-natuerlichen-medikamenten-bei-psychiatrischen-stoerungen/',
  },
  'ashwagandha-asw-potential-indications-and-administration-in-psychiatric-disorders-2768-5587': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/ashwagandha-asw-indicacoes-potenciais-e-administracao-em-transtornos-psiquiatricos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/ashwagandha-asw-indications-potentielles-et-administration-dans-les-troubles-psychiatriques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/ashwagandha-asw-potenziali-indicazioni-e-somministrazione-nei-disturbi-psichiatrici/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ashwagandha-asw-moegliche-indikationen-und-anwendung-bei-psychiatrischen-stoerungen/',
  },
  'use-of-folic-acid-and-l-methylfolate-in-psychiatric-disorders-2768-5586': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uso-de-acido-folico-e-l-metilfolato-em-transtornos-psiquiatricos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-de-lacide-folique-et-du-l-methylfolate-dans-les-troubles-psychiatriques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/uso-di-acido-folico-e-l-metilfolato-nei-disturbi-psichiatrici/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verwendung-von-folsaeure-und-l-methylfolat-bei-psychiatrischen-stoerungen/',
  },
  'cannabidiol-evidence-for-psychiatric-disorders-and-recommendations-for-using-it-2768-5585': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/canabidiol-evidencias-para-transtornos-psiquiatricos-e-recomendacoes-para-seu-uso/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/cannabidiol-preuves-pour-les-troubles-psychiatriques-et-recommandations-pour-son-utilisation/',
    it: 'https://it.psychopharmacologyinstitute.com/section/cannabidiolo-evidenze-per-i-disturbi-psichiatrici-e-raccomandazioni-per-il-suo-utilizzo/',
    de: 'https://de.psychopharmacologyinstitute.com/section/cannabidiol-nachweise-fuer-psychiatrische-stoerungen-und-empfehlungen-fuer-den-einsatz-von-cannabidiol/',
  },
  'inositol-potential-indications-administration-and-a-clinical-vignette-2768-5584': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/inositol-indicacoes-potenciais-administracao-e-uma-vinheta-clinica/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/inositol-indications-potentielles-administration-et-vignette-clinique/',
    it: 'https://it.psychopharmacologyinstitute.com/section/inositolo-indicazioni-potenziali-somministrazione-e-una-vignetta-clinica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/inositol-moegliche-indikationen-verabreichung-und-eine-klinische-vignette/',
  },
  'evidence-for-nac-in-other-psychiatric-disorders-2768-5583': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/evidencia-de-nac-em-outros-transtornos-psiquiatricos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/preuves-de-lutilite-de-la-nac-dans-dautres-troubles-psychiatriques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/prove-di-nac-in-altri-disturbi-psichiatrici/',
    de: 'https://de.psychopharmacologyinstitute.com/section/nachweise-fuer-nac-bei-anderen-psychiatrischen-stoerungen/',
  },
  'exploring-the-evidence-of-n-acetyl-cysteine-nac-for-mood-disorders-2768-5582': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/explorando-as-evidencias-da-n-acetilcisteina-nac-para-transtornos-do-humor/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/exploration-des-preuves-de-lefficacite-de-la-n-acetyl-cysteine-nac-dans-les-troubles-de-lhumeur/',
    it: 'https://it.psychopharmacologyinstitute.com/section/esplorare-levidenza-della-n-acetilcisteina-nac-per-i-disturbi-dellumore/',
    de: 'https://de.psychopharmacologyinstitute.com/section/untersuchung-der-evidenz-von-n-acetyl-cystein-nac-bei-gemuetskrankheiten/',
  },
  'an-introduction-to-the-use-of-natural-medication-for-psychiatric-disorders-2768-5581': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uma-introducao-ao-uso-de-medicamentos-naturais-para-transtornos-psiquiatricos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/une-introduction-a-lutilisation-de-medicaments-naturels-pour-les-troubles-psychiatriques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/unintroduzione-alluso-di-farmaci-naturali-per-i-disturbi-psichiatrici/',
    de: 'https://de.psychopharmacologyinstitute.com/section/eine-einfuehrung-in-die-verwendung-natuerlicher-medikamente-bei-psychiatrischen-stoerungen/',
  },
  'an-introduction-to-psychodynamic-psychopharmacology-2767-5557': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uma-introducao-a-psicofarmacologia-psicodinamica/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/introduction-a-la-psychopharmacologie-psychodynamique/',
    it: 'https://it.psychopharmacologyinstitute.com/section/introduzione-alla-psicofarmacologia-psicodinamica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/eine-einfuehrung-in-die-psychodynamische-psychopharmakologie/',
  },
  'take-home-messages-2767-5565': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-2/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-2/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-2/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-2/',
  },
  'clinical-vignettes-2767-5564': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/vinhetas-clinicas/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/vignettes-cliniques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/vignette-cliniche/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinische-vignetten/',
  },
  'addressing-countertherapeutic-uses-of-medication-and-countertransference-2767-5563': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/abordagem-de-usos-contraterapeuticos-de-medicamentos-e-contratransferencia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/aborder-les-utilisations-contre-therapeutiques-des-medicaments-et-le-contre-transfert/',
    it: 'https://it.psychopharmacologyinstitute.com/section/affrontare-gli-usi-controterapeutici-dei-farmaci-e-del-controtransfert/',
    de: 'https://de.psychopharmacologyinstitute.com/section/auseinandersetzung-mit-dem-gegentherapeutischen-einsatz-von-medikamenten-und-der-gegenuebertragung/',
  },
  'cultivating-a-therapeutic-alliance-2767-5562': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/cultivar-uma-alianca-terapeutica/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/cultiver-une-alliance-therapeutique/',
    it: 'https://it.psychopharmacologyinstitute.com/section/coltivare-unalleanza-terapeutica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/aufbau-eines-therapeutischen-buendnisses/',
  },
  'attending-the-patients-ambivalence-2767-5561': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/atender-a-ambivalencia-do-paciente/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/prendre-en-compte-lambivalence-du-patient/',
    it: 'https://it.psychopharmacologyinstitute.com/section/attenzione-allambivalenza-del-paziente/',
    de: 'https://de.psychopharmacologyinstitute.com/section/auf-die-ambivalenz-des-patienten-eingehen/',
  },
  'knowing-the-patient-2767-5560': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/conhecer-o-paciente/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/connaitre-le-patient/',
    it: 'https://it.psychopharmacologyinstitute.com/section/conoscere-il-paziente/',
    de: 'https://de.psychopharmacologyinstitute.com/section/den-patienten-kennen/',
  },
  'mindbody-integration-in-psychiatric-care-2767-5559': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/integracao-mente-corpo-no-tratamento-psiquiatrico/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/integration-corps-esprit-dans-les-soins-psychiatriques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/integrazione-mente-corpo-nellassistenza-psichiatrica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/integration-von-koerper-und-geist-in-der-psychiatrischen-versorgung/',
  },
  'avoiding-mindbody-split-sources-of-dualistic-thinking-2767-5558': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/evitando-a-divisao-mente-corpo-fontes-do-pensamento-dualista/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/eviter-le-clivage-corps-esprit-les-sources-de-la-pensee-dualiste/',
    it: 'https://it.psychopharmacologyinstitute.com/section/evitare-la-scissione-mente-corpo-le-fonti-del-pensiero-dualistico/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-spaltung-von-koerper-und-geist-vermeiden-quellen-des-dualistischen-denkens/',
  },
  'basic-psychodynamic-concepts-2767-5556': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/conceitos-psicodinamicos-basicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/concepts-psychodynamiques-de-base/',
    it: 'https://it.psychopharmacologyinstitute.com/section/concetti-psicodinamici-di-base/',
    de: 'https://de.psychopharmacologyinstitute.com/section/psychodynamische-grundkonzepte/',
  },
  'taking-an-integrative-perspective-2767-5555': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/adotando-uma-perspectiva-integradora/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/adopter-une-perspective-integrative/',
    it: 'https://it.psychopharmacologyinstitute.com/section/assumere-una-prospettiva-integrativa/',
    de: 'https://de.psychopharmacologyinstitute.com/section/eine-integrative-perspektive-einnehmen/',
  },
  'understanding-the-problem-of-treatment-resistance-2767-5554': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/entendendo-o-problema-da-resistencia-ao-tratamento/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-le-probleme-de-la-resistance-aux-traitements/',
    it: 'https://it.psychopharmacologyinstitute.com/section/comprendere-il-problema-della-resistenza-al-trattamento/',
    de: 'https://de.psychopharmacologyinstitute.com/section/das-problem-der-behandlungsresistenz-verstehen/',
  },
  'prescribing-in-the-era-of-biomedical-psychiatry-an-introduction-2767-5553': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/prescricao-na-era-da-psiquiatria-biomedica-uma-introducao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/prescrire-a-lere-de-la-psychiatrie-biomedicale-une-introduction/',
    it: 'https://it.psychopharmacologyinstitute.com/section/la-prescrizione-nellera-della-psichiatria-biomedica-unintroduzione/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verschreibung-in-der-aera-der-biomedizinischen-psychiatrie-eine-einfuehrung/',
  },
  'risks-of-using-antipsychotics-during-pregnancy-2766-5532': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/riscos-do-uso-de-antipsicoticos-durante-a-gravidez/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risques-lies-a-lutilisation-dantipsychotiques-pendant-la-grossesse/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischi-delluso-di-antipsicotici-durante-la-gravidanza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiken-der-einnahme-von-antipsychotika-waehrend-der-schwangerschaft/',
  },
  'effects-on-cognitive-function-and-neural-tube-defects-associated-with-fetal-exposure-to-aeds-2766-5531':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/efeitos-sobre-a-funcao-cognitiva-e-defeitos-do-tubo-neural-associados-a-exposicao-fetal-a-aeds/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/effets-sur-la-fonction-cognitive-et-les-anomalies-du-tube-neural-associes-a-lexposition-du-foetus-aux-aeds/',
      it: 'https://it.psychopharmacologyinstitute.com/section/effetti-sulla-funzione-cognitiva-e-sui-difetti-del-tubo-neurale-associati-allesposizione-fetale-agli-aed/',
      de: 'https://de.psychopharmacologyinstitute.com/section/auswirkungen-auf-die-kognitiven-funktionen-und-neuralrohrdefekte-im-zusammenhang-mit-der-fetalen-exposition-gegenueber-aeds/',
    },
  'risks-of-using-carbamazepine-and-lamotrigine-in-pregnancy-2766-5530': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/riscos-do-uso-de-carbamazepina-e-lamotrigina-na-gravidez/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risques-lies-a-lutilisation-de-la-carbamazepine-et-de-la-lamotrigine-pendant-la-grossesse/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischi-delluso-di-carbamazepina-e-lamotrigina-in-gravidanza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiken-der-anwendung-von-carbamazepin-und-lamotrigin-in-der-schwangerschaft/',
  },
  'risks-of-using-valproate-in-pregnancy-2766-5529': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/riscos-do-uso-do-valproato-na-gravidez/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risques-lies-a-lutilisation-du-valproate-pendant-la-grossesse/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischi-delluso-del-valproato-in-gravidanza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiken-der-anwendung-von-valproat-in-der-schwangerschaft/',
  },
  'bipolar-disorder-during-pregnancy-management-of-lithium-2766-5528': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/transtorno-bipolar-durante-a-gravidez-controle-do-litio/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/troubles-bipolaires-pendant-la-grossesse-gestion-du-lithium/',
    it: 'https://it.psychopharmacologyinstitute.com/section/disturbo-bipolare-in-gravidanza-gestione-del-litio/',
    de: 'https://de.psychopharmacologyinstitute.com/section/bipolare-stoerung-waehrend-der-schwangerschaft-behandlung-mit-lithium/',
  },
  'risks-of-using-lithium-in-pregnancy-2766-5527': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/riscos-do-uso-de-litio-na-gravidez/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risques-lies-a-lutilisation-du-lithium-pendant-la-grossesse/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischi-delluso-del-litio-in-gravidanza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiken-bei-der-verwendung-von-lithium-in-der-schwangerschaft/',
  },
  'impact-of-discontinuing-mood-stabilizers-in-pregnancy-2766-5526': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/impacto-da-interrupcao-dos-estabilizadores-de-humor-na-gravidez/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/impact-de-larret-des-stabilisateurs-de-lhumeur-pendant-la-grossesse/',
    it: 'https://it.psychopharmacologyinstitute.com/section/impatto-della-sospensione-degli-stabilizzatori-dellumore-in-gravidanza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/auswirkungen-des-absetzens-von-stimmungsstabilisatoren-in-der-schwangerschaft/',
  },
  'management-of-bipolar-disorder-in-pregnancy-treatment-dilemmas-2766-5525': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/controle-do-transtorno-bipolar-na-gravidez-dilemas-do-tratamento/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/prise-en-charge-du-trouble-bipolaire-pendant-la-grossesse-dilemmes-therapeutiques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/gestione-del-disturbo-bipolare-in-gravidanza-dilemmi-di-trattamento/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-der-bipolaren-stoerung-in-der-schwangerschaft-behandlungsdilemmata/',
  },
  'risks-of-using-gabapentin-and-topiramate-in-pregnancy-2766-5533': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/riscos-do-uso-de-gabapentina-e-topiramato-na-gravidez/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risques-lies-a-lutilisation-de-la-gabapentine-et-du-topiramate-pendant-la-grossesse/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischi-delluso-di-gabapentin-e-topiramato-in-gravidanza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiken-bei-der-verwendung-von-gabapentin-und-topiramat-in-der-schwangerschaft/',
  },
  'take-home-messages-2766-5536': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-3/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-3/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-3/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-3/',
  },
  'clinical-vignette-a-woman-with-bipolar-disorder-who-wishes-to-become-pregnant-2766-5535': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/vinheta-clinica-uma-mulher-com-transtorno-bipolar-que-deseja-engravidar/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/vignette-clinique-une-femme-souffrant-de-troubles-bipolaires-et-souhaitant-une-grossesse/',
    it: 'https://it.psychopharmacologyinstitute.com/section/vignetta-clinica-una-donna-con-disturbo-bipolare-che-desidera-una-gravidanza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinische-vignette-eine-frau-mit-bipolarer-stoerung-die-schwanger-werden-moechte/',
  },
  'guiding-principles-for-treating-bipolar-disorder-during-pregnancy-2766-5534': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/principios-orientadores-para-o-tratamento-do-transtorno-bipolar-durante-a-gravidez/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/principes-directeurs-pour-le-traitement-des-troubles-bipolaires-pendant-la-grossesse/',
    it: 'https://it.psychopharmacologyinstitute.com/section/principi-guida-per-il-trattamento-del-disturbo-bipolare-in-gravidanza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/leitprinzipien-fuer-die-behandlung-bipolarer-stoerungen-waehrend-der-schwangerschaft/',
  },
  'motivational-interviewing-strategies-for-benzodiazepine-users-2798-5705': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/estrategias-de-entrevista-motivacional-para-usuarios-de-benzodiazepinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/strategies-dentretien-motivationnel-pour-les-consommateurs-de-benzodiazepines/',
    it: 'https://it.psychopharmacologyinstitute.com/section/strategie-di-colloquio-motivazionale-per-i-consumatori-di-benzodiazepine/',
    de: 'https://de.psychopharmacologyinstitute.com/section/motivierende-gespraechsstrategien-fuer-benzodiazepin-konsumenten/',
  },
  'benzodiazepine-deprescription-indications-benefits-and-risks-2798-5706': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/desprescricao-de-benzodiazepinicos-indicacoes-beneficios-e-riscos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-deprescription-des-benzodiazepines-indications-benefices-et-risques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/deprescrizione-di-benzodiazepine-indicazioni-benefici-e-rischi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/absetzen-von-benzodiazepinen-indikationen-nutzen-und-risiken/',
  },
  'understanding-the-principles-of-benzodiazepine-tapering-2798-5707': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/compreensao-dos-principios-da-reducao-gradual-do-uso-de-benzodiazepinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-les-principes-de-larret-progressif-des-benzodiazepines/',
    it: 'https://it.psychopharmacologyinstitute.com/section/comprendere-i-principi-del-tapering-delle-benzodiazepine/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verstehen-der-prinzipien-des-benzodiazepin-tapings/',
  },
  'techniques-for-successful-benzodiazepine-tapering-2798-5708': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tecnicas-para-uma-reducao-bem-sucedida-do-uso-de-benzodiazepinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/techniques-pour-un-sevrage-reussi-des-benzodiazepines/',
    it: 'https://it.psychopharmacologyinstitute.com/section/tecniche-per-il-successo-del-tapering-delle-benzodiazepine/',
    de: 'https://de.psychopharmacologyinstitute.com/section/techniken-zum-erfolgreichen-absetzen-von-benzodiazepinen/',
  },
  'understanding-and-managing-benzodiazepine-withdrawal-symptoms-2798-5709': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/compreensao-e-controle-dos-sintomas-de-abstinencia-de-benzodiazepinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-et-gerer-les-symptomes-de-sevrage-des-benzodiazepines/',
    it: 'https://it.psychopharmacologyinstitute.com/section/comprendere-e-gestire-i-sintomi-di-astinenza-da-benzodiazepine/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verstaendnis-und-umgang-mit-benzodiazepin-entzugssymptomen/',
  },
  'exploring-the-complexities-of-benzodiazepine-induced-neurological-dysfunction-bind-2798-5710': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/explorando-as-complexidades-da-disfuncao-neurologica-induzida-por-benzodiazepinicos-bind/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/exploration-des-complexites-du-dysfonctionnement-neurologique-induit-par-les-benzodiazepines-bind/',
    it: 'https://it.psychopharmacologyinstitute.com/section/esplorare-le-complessita-della-disfunzione-neurologica-indotta-dalle-benzodiazepine-bind/',
    de: 'https://de.psychopharmacologyinstitute.com/section/erforschung-der-komplexitaet-von-benzodiazepin-induzierter-neurologischer-dysfunktion-bind/',
  },
  'beyond-benzodiazepine-discontinuation-understanding-life-effects-and-support-strategies-2798-5711':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/alem-da-interrupcao-do-uso-de-benzodiazepinicos-compreensao-dos-efeitos-na-vida-e-estrategias-de-apoio/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/au-dela-de-larret-des-benzodiazepines-comprendre-les-effets-de-la-vie-et-les-strategies-de-soutien/',
      it: 'https://it.psychopharmacologyinstitute.com/section/oltre-la-sospensione-delle-benzodiazepine-comprensione-degli-effetti-sulla-vita-e-strategie-di-supporto/',
      de: 'https://de.psychopharmacologyinstitute.com/section/nach-dem-absetzen-von-benzodiazepinen-verstaendnis-der-auswirkungen-auf-das-leben-und-strategien-zur-unterstuetzung/',
    },
  'clinical-case-2798-5712': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/caso-clinico-2/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/cas-clinique-2/',
    it: 'https://it.psychopharmacologyinstitute.com/section/caso-clinico-2/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinischer-fall-2/',
  },
  'take-home-messages-2798-5713': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-17/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-17/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-17/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-17/',
  },
  'benzodiazepines-tracing-their-history-2797-5678': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/benzodiazepinicos-tracando-sua-historia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/les-benzodiazepines-leur-histoire/',
    it: 'https://it.psychopharmacologyinstitute.com/section/le-benzodiazepine-la-loro-storia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/benzodiazepine-eine-spurensuche-in-ihrer-geschichte/',
  },
  'benzodiazepines-neuropharmacology-and-tolerance-development-2797-5679': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/benzodiazepinicos-neurofarmacologia-e-desenvolvimento-de-tolerancia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/benzodiazepines-neuropharmacologie-et-developpement-de-la-tolerance/',
    it: 'https://it.psychopharmacologyinstitute.com/section/benzodiazepine-neurofarmacologia-e-sviluppo-della-tolleranza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/benzodiazepine-neuropharmakologie-und-toleranzentwicklung/',
  },
  'appropriate-benzodiazepine-use-indications-and-optimal-selection-2797-5680': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uso-adequado-de-benzodiazepinicos-indicacoes-e-selecao-ideal/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-appropriee-des-benzodiazepines-indications-et-choix-optimal/',
    it: 'https://it.psychopharmacologyinstitute.com/section/uso-appropriato-delle-benzodiazepine-indicazioni-e-selezione-ottimale/',
    de: 'https://de.psychopharmacologyinstitute.com/section/angemessener-einsatz-von-benzodiazepinen-indikationen-und-optimale-auswahl/',
  },
  'risks-of-benzodiazepines-contraindications-and-side-effects-2797-5681': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/riscos-dos-benzodiazepinicos-contraindicacoes-e-efeitos-colaterais/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/risques-des-benzodiazepines-contre-indications-et-effets-secondaires/',
    it: 'https://it.psychopharmacologyinstitute.com/section/rischi-delle-benzodiazepine-controindicazioni-ed-effetti-collaterali/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risiken-von-benzodiazepinen-kontraindikationen-und-nebenwirkungen/',
  },
  'understanding-the-major-risks-of-benzodiazepine-use-2797-5682': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/entendendo-os-principais-riscos-do-uso-de-benzodiazepinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-les-principaux-risques-lies-a-lutilisation-des-benzodiazepines/',
    it: 'https://it.psychopharmacologyinstitute.com/section/comprendere-i-principali-rischi-delluso-di-benzodiazepine/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verstaendnis-der-hauptrisiken-des-benzodiazepin-konsums/',
  },
  'the-benzodiazepine-crisis-overprescription-physical-dependence-misuse-and-addiction-2797-5683': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/a-crise-dos-benzodiazepinicos-prescricao-excessiva-dependencia-fisica-uso-indevido-e-vicio/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-crise-des-benzodiazepines-surprescription-dependance-physique-mesusage-et-addiction/',
    it: 'https://it.psychopharmacologyinstitute.com/section/la-crisi-delle-benzodiazepine-sovraprescrizione-dipendenza-fisica-abuso-e-dipendenza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-benzodiazepin-krise-ueberverschreibung-koerperliche-abhaengigkeit-missbrauch-und-sucht/',
  },
  'benzodiazepine-use-disorder-2797-5684': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/transtorno-por-uso-de-benzodiazepinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/troubles-lies-a-lutilisation-de-benzodiazepines/',
    it: 'https://it.psychopharmacologyinstitute.com/section/disturbo-da-uso-di-benzodiazepine/',
    de: 'https://de.psychopharmacologyinstitute.com/section/benzodiazepin-konsumstoerung/',
  },
  'consequences-of-benzodiazepine-use-in-high-risk-populations-2797-5685': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/consequencias-do-uso-de-benzodiazepinicos-em-populacoes-de-alto-risco/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/consequences-de-lutilisation-de-benzodiazepines-dans-les-populations-a-haut-risque/',
    it: 'https://it.psychopharmacologyinstitute.com/section/conseguenze-delluso-di-benzodiazepine-in-popolazioni-ad-alto-rischio/',
    de: 'https://de.psychopharmacologyinstitute.com/section/folgen-des-benzodiazepin-konsums-in-hochrisikopopulationen/',
  },
  'consequences-of-benzodiazepine-use-in-pregnancy-breastfeeding-and-adolescence-2797-5686': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/consequencias-do-uso-de-benzodiazepinicos-na-gravidez-na-amamentacao-e-na-adolescencia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/consequences-de-la-consommation-de-benzodiazepines-pendant-la-grossesse-lallaitement-et-ladolescence/',
    it: 'https://it.psychopharmacologyinstitute.com/section/conseguenze-delluso-di-benzodiazepine-in-gravidanza-allattamento-e-adolescenza/',
    de: 'https://de.psychopharmacologyinstitute.com/section/folgen-des-benzodiazepin-konsums-in-der-schwangerschaft-stillzeit-und-adoleszenz/',
  },
  'clinical-vignette-strategies-for-safer-use-of-benzodiazepines-2797-5687': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/vinheta-clinica-estrategias-para-o-uso-mais-seguro-de-benzodiazepinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/vignette-clinique-strategies-pour-une-utilisation-plus-sure-des-benzodiazepines/',
    it: 'https://it.psychopharmacologyinstitute.com/section/vignetta-clinica-strategie-per-un-uso-piu-sicuro-delle-benzodiazepine/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinische-vignette-strategien-fuer-einen-sichereren-gebrauch-von-benzodiazepinen/',
  },
  'take-home-messages-2797-5688': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-16/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-16/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-16/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-16/',
  },
  'understanding-the-basics-of-tms-2792-5667': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/entendendo-os-conceitos-basicos-do-emt/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-les-bases-du-stm/',
    it: 'https://it.psychopharmacologyinstitute.com/section/comprendere-le-basi-della-tms/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-grundlagen-von-tms-verstehen/',
  },
  'fda-approved-tms-devices-and-coils-2792-5668': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/dispositivos-e-bobinas-de-emt-aprovados-pela-fda/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/dispositifs-et-bobines-de-stm-approuves-par-la-fda/',
    it: 'https://it.psychopharmacologyinstitute.com/section/dispositivi-e-bobine-tms-approvati-dalla-fda/',
    de: 'https://de.psychopharmacologyinstitute.com/section/fda-zugelassene-tms-geraete-und-spulen/',
  },
  'the-science-behind-tms-brain-circuits-2792-5669': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/a-ciencia-por-tras-da-emt-circuitos-cerebrais/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-science-derriere-la-stm-les-circuits-du-cerveau/',
    it: 'https://it.psychopharmacologyinstitute.com/section/la-scienza-dietro-la-tms-i-circuiti-cerebrali/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-wissenschaft-hinter-tms-schaltkreise-im-brain/',
  },
  'assessing-the-potential-side-effects-of-tms-2792-5670': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/avaliacao-dos-possiveis-efeitos-colaterais-da-emt/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/evaluer-les-effets-secondaires-potentiels-de-la-stm/',
    it: 'https://it.psychopharmacologyinstitute.com/section/valutazione-dei-potenziali-effetti-collaterali-della-tms/',
    de: 'https://de.psychopharmacologyinstitute.com/section/bewertung-der-moeglichen-nebenwirkungen-von-tms/',
  },
  'exploring-the-evidence-of-tms-for-depression-2792-5671': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/explorando-as-evidencias-da-emt-para-depressao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/exploration-des-preuves-de-lefficacite-de-la-stm-dans-le-traitement-de-la-depression/',
    it: 'https://it.psychopharmacologyinstitute.com/section/esplorare-le-prove-della-tms-per-la-depressione/',
    de: 'https://de.psychopharmacologyinstitute.com/section/erforschung-der-evidenz-von-tms-bei-depressionen/',
  },
  'exploring-the-evidence-of-tms-for-ocd-2792-5672': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/explorando-as-evidencias-da-emt-para-o-toc/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/exploration-des-preuves-de-lefficacite-de-la-stm-dans-le-traitement-des-toc/',
    it: 'https://it.psychopharmacologyinstitute.com/section/esplorare-le-prove-della-tms-per-il-doc/',
    de: 'https://de.psychopharmacologyinstitute.com/section/erforschung-der-beweise-fuer-tms-bei-ocd/',
  },
  'recent-innovations-in-tms-research-and-practice-2792-5673': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/inovacoes-recentes-na-pesquisa-e-na-pratica-da-emt/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/innovations-recentes-dans-la-recherche-et-la-pratique-de-la-stm/',
    it: 'https://it.psychopharmacologyinstitute.com/section/recenti-innovazioni-nella-ricerca-e-nella-pratica-della-tms/',
    de: 'https://de.psychopharmacologyinstitute.com/section/juengste-innovationen-in-der-tms-forschung-und-praxis/',
  },
  'optimizing-patient-selection-for-tms-treatment-2792-5674': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/otimizacao-da-selecao-de-pacientes-para-tratamento-com-emt/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/optimiser-la-selection-des-patients-pour-le-traitement-par-stm/',
    it: 'https://it.psychopharmacologyinstitute.com/section/ottimizzazione-della-selezione-dei-pazienti-per-il-trattamento-tms/',
    de: 'https://de.psychopharmacologyinstitute.com/section/optimierung-der-patientenauswahl-fuer-die-tms-behandlung/',
  },
  'exploring-other-uses-of-tms-2792-5675': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/explorando-outros-usos-da-emt/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/explorer-dautres-utilisations-de-la-stm/',
    it: 'https://it.psychopharmacologyinstitute.com/section/esplorare-altri-usi-della-tms/',
    de: 'https://de.psychopharmacologyinstitute.com/section/erkundung-anderer-einsatzmoeglichkeiten-von-tms/',
  },
  'clinical-case-2792-5676': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/caso-clinico/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/cas-clinique/',
    it: 'https://it.psychopharmacologyinstitute.com/section/caso-clinico/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinischer-fall/',
  },
  'take-home-messages-2792-5677': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-15/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-15/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-15/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-15/',
  },
  'an-introduction-to-using-bsm-in-the-clinical-practice-2729-5513': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uma-introducao-ao-uso-do-bsm-na-pratica-clinica/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/une-introduction-a-lutilisation-de-bsm-dans-la-clinical-practice/',
    it: 'https://it.psychopharmacologyinstitute.com/section/unintroduzione-alluso-della-bsm-nella-pratica-clinica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/eine-einfuehrung-in-die-verwendung-von-bsm-in-der-klinischen-praxis/',
  },
  'bsms-ingredients-and-doses-2729-5514': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/bsms-ingredientes-e-doses/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/bsms-ingredients-et-doses/',
    it: 'https://it.psychopharmacologyinstitute.com/section/bsms-ingredienti-e-dosi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/bsms-inhaltsstoffe-und-dosierung/',
  },
  'bsms-safety-and-tolerability-2729-5515': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/bsms-seguranca-e-tolerabilidade/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/bsms-securite-et-tolerance/',
    it: 'https://it.psychopharmacologyinstitute.com/section/bsms-sicurezza-e-tollerabilita/',
    de: 'https://de.psychopharmacologyinstitute.com/section/bsms-sicherheit-und-vertraeglichkeit/',
  },
  'bsms-and-potentiation-of-psychotropic-medications-2729-5516': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/bsms-e-potencializacao-de-medicamentos-psicotropicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/bsms-et-potentialisation-des-medicaments-psychotropes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/bsms-e-potenziamento-dei-farmaci-psicotropi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/bsms-und-potenzierung-von-psychotropen-medikamenten/',
  },
  'strict-and-relative-contraindications-for-using-bsms-2729-5517': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/contraindicacoes-estritas-e-relativas-para-o-uso-de-bsms/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/contre-indications-strictes-et-relatives-a-lutilisation-des-bsms/',
    it: 'https://it.psychopharmacologyinstitute.com/section/controindicazioni-rigorose-e-relative-alluso-di-bsms/',
    de: 'https://de.psychopharmacologyinstitute.com/section/strenge-und-relative-kontraindikationen-fuer-die-verwendung-von-bsms/',
  },
  'bsms-and-drug-interactions-2729-5518': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/bsms-e-interacoes-medicamentosas/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/bsms-et-interactions-medicamenteuses/',
    it: 'https://it.psychopharmacologyinstitute.com/section/bsms-e-interazioni-tra-farmaci/',
    de: 'https://de.psychopharmacologyinstitute.com/section/bsms-und-wechselwirkungen-von-medikamenten/',
  },
  'practical-tips-for-using-bsms-in-the-clinical-practice-2729-5519': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/dicas-praticas-para-usar-o-bsms-na-pratica-clinica/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/conseils-pratiques-pour-lutilisation-de-bsms-dans-la-pratique-clinique/',
    it: 'https://it.psychopharmacologyinstitute.com/section/consigli-pratici-per-lutilizzo-dei-sistemi-di-gestione-del-rischio-nella-pratica-clinica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/praktische-tipps-fuer-den-einsatz-von-bsms-in-der-klinischen-praxis/',
  },
  'dosing-empowerplus-advanced-and-daily-essential-nutrients-2729-5520': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/dosagem-empowerplus-avancada-e-nutrientes-essenciais-diarios/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/dosage-empowerplus-advanced-et-daily-essential-nutrients/',
    it: 'https://it.psychopharmacologyinstitute.com/section/dosaggio-empowerplus-avanzato-e-nutrienti-essenziali-giornalieri/',
    de: 'https://de.psychopharmacologyinstitute.com/section/dosierung-empowerplus-advanced-und-taeglich-essentielle-naehrstoffe/',
  },
  'starting-bsms-in-your-clinical-practice-a-step-wise-process-2729-5521': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/iniciando-o-bsms-em-sua-pratica-clinica-um-processo-passo-a-passo/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/mettre-en-place-un-systeme-de-gestion-des-risques-biologiques-dans-votre-clinical-practice-un-processus-par-etapes/',
    it: 'https://it.psychopharmacologyinstitute.com/section/avviare-il-sistema-di-gestione-delle-malattie-nella-pratica-clinica-un-processo-a-tappe/',
    de: 'https://de.psychopharmacologyinstitute.com/section/einfuehrung-von-bsms-in-ihrer-klinischen-praxis-ein-schrittweiser-prozess/',
  },
  'other-considerations-when-using-bsms-2729-5522': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/outras-consideracoes-ao-usar-o-bsms/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/autres-considerations-relatives-a-lutilisation-des-bsms/',
    it: 'https://it.psychopharmacologyinstitute.com/section/altre-considerazioni-sullutilizzo-di-bsms/',
    de: 'https://de.psychopharmacologyinstitute.com/section/andere-ueberlegungen-bei-der-verwendung-von-bsms/',
  },
  'clinical-cases-2729-5523': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/casos-clinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/cas-cliniques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/casi-clinici/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinische-faelle/',
  },
  'take-home-messages-2729-5524': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-12/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-12/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-12/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-12/',
  },
  'understanding-pain-in-osteoarthritis-and-fibromyalgia-2734-5482': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/entendendo-a-dor-na-osteoartrite-e-na-fibromialgia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-la-douleur-dans-larthrose-et-la-fibromyalgie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/comprendere-il-dolore-nellosteoartrite-e-nella-fibromialgia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/schmerzen-bei-osteoarthritis-und-fibromyalgie-verstehen/',
  },
  'mechanistic-characterization-of-pain-2734-5483': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/caracterizacao-mecanicista-da-dor/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/caracterisation-mecaniste-de-la-douleur/',
    it: 'https://it.psychopharmacologyinstitute.com/section/caratterizzazione-meccanicistica-del-dolore/',
    de: 'https://de.psychopharmacologyinstitute.com/section/mechanistische-charakterisierung-von-schmerz/',
  },
  'diagnosing-fibromyalgia-2734-5484': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/diagnostico-da-fibromialgia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/diagnostiquer-la-fibromyalgie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/diagnosticare-la-fibromialgia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/diagnose-der-fibromyalgie/',
  },
  'centralized-pain-in-chronic-pain-conditions-2734-5485': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/dor-centralizada-em-condicoes-de-dor-cronica/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/douleur-centralisee-dans-les-affections-douloureuses-chroniques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/dolore-centralizzato-in-condizioni-di-dolore-cronico/',
    de: 'https://de.psychopharmacologyinstitute.com/section/zentraler-schmerz-bei-chronischen-schmerzzustaenden/',
  },
  'pathophysiology-and-neuroimaging-of-centralized-pain-states-2734-5486': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/fisiopatologia-e-neuroimagem-de-estados-de-dor-centralizada/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/physiopathologie-et-neuro-imagerie-des-etats-douloureux-centralises/',
    it: 'https://it.psychopharmacologyinstitute.com/section/fisiopatologia-e-neuroimaging-degli-stati-di-dolore-centralizzato/',
    de: 'https://de.psychopharmacologyinstitute.com/section/pathophysiologie-und-neuroimaging-von-zentralisierten-schmerzzustaenden/',
  },
  'pharmacologic-therapies-for-fibromyalgia-2734-5487': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/terapias-farmacologicas-para-fibromialgia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/therapies-pharmacologiques-de-la-fibromyalgie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/terapie-farmacologiche-per-la-fibromialgia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/pharmakologische-therapien-bei-fibromyalgie/',
  },
  'fibromyalgia-and-medical-cannabis-2734-5488': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/fibromialgia-e-cannabis-medicinal/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/fibromyalgie-et-cannabis-medical/',
    it: 'https://it.psychopharmacologyinstitute.com/section/fibromialgia-e-cannabis-terapeutica/',
    de: 'https://de.psychopharmacologyinstitute.com/section/fibromyalgie-und-medizinisches-cannabis/',
  },
  'pain-treatments-tailored-to-underlying-mechanisms-2734-5489': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamentos-de-dor-adaptados-aos-mecanismos-subjacentes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitements-de-la-douleur-adaptes-aux-mecanismes-sous-jacents/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamenti-del-dolore-personalizzati-in-base-ai-meccanismi-sottostanti/',
    de: 'https://de.psychopharmacologyinstitute.com/section/auf-die-zugrunde-liegenden-mechanismen-zugeschnittene-schmerzbehandlungen/',
  },
  'nonpharmacologic-therapies-for-fibromyalgia-2734-5490': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/terapias-nao-farmacologicas-para-fibromialgia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/therapies-non-pharmacologiques-pour-la-fibromyalgie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/terapie-non-farmacologiche-per-la-fibromialgia/',
    de: 'https://de.psychopharmacologyinstitute.com/section/nicht-pharmakologische-therapien-bei-fibromyalgie/',
  },
  'take-home-messages-2734-5491': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-14/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-14/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-14/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-14/',
  },
  'introduction-to-clozapine-indications-and-common-adverse-effects-2733-5460': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/introducao-a-clozapina-indicacoes-e-efeitos-adversos-comuns/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/introduction-a-la-clozapine-indications-et-effets-indesirables-courants/',
    it: 'https://it.psychopharmacologyinstitute.com/section/introduzione-alla-clozapina-indicazioni-ed-effetti-avversi-comuni/',
    de: 'https://de.psychopharmacologyinstitute.com/section/einfuehrung-in-clozapin-indikationen-und-haeufige-unerwuenschte-wirkungen/',
  },
  'using-clozapine-for-treatment-resistant-psychosis-and-mood-disorders-2733-5461': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uso-da-clozapina-para-psicose-resistente-ao-tratamento-e-transtornos-de-humor/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-de-la-clozapine-pour-traiter-les-psychoses-et-les-troubles-de-lhumeur-resistants-au-traitement/',
    it: 'https://it.psychopharmacologyinstitute.com/section/utilizzo-di-clozapina-per-psicosi-e-disturbi-dellumore-resistenti-al-trattamento/',
    de: 'https://de.psychopharmacologyinstitute.com/section/einsatz-von-clozapin-bei-behandlungsresistenten-psychosen-und-affektiven-stoerungen/',
  },
  'using-clozapine-for-suicide-risk-and-aggression-2733-5462': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uso-da-clozapina-para-risco-de-suicidio-e-agressao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/utilisation-de-la-clozapine-pour-le-risque-de-suicide-et-lagressivite/',
    it: 'https://it.psychopharmacologyinstitute.com/section/utilizzo-della-clozapina-per-il-rischio-di-suicidio-e-laggressivita/',
    de: 'https://de.psychopharmacologyinstitute.com/section/einsatz-von-clozapin-bei-selbstmordrisiko-und-aggression/',
  },
  'off-label-indications-for-clozapine-2733-5463': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/indicacoes-de-uso-off-label-da-clozapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/indications-hors-indications-de-la-clozapine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/indicazioni-fuori-indicazione-per-la-clozapina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/off-label-indikationen-fuer-clozapin/',
  },
  'pretreatment-evaluation-and-consent-for-using-clozapine-2733-5464': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/avaliacao-do-pre-tratamento-e-consentimento-para-o-uso-da-clozapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/evaluation-prealable-et-consentement-a-lutilisation-de-la-clozapine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/valutazione-pre-trattamento-e-consenso-alluso-della-clozapina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/bewertung-der-vorbehandlung-und-zustimmung-zum-einsatz-von-clozapin/',
  },
  'initiating-and-optimizing-clozapine-2733-5465': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/inicio-e-otimizacao-da-clozapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/initiation-et-optimisation-de-la-clozapine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/avvio-e-ottimizzazione-della-clozapina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/einleitung-und-optimierung-von-clozapin/',
  },
  'clozapine-monitoring-rems-system-2733-5466': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/monitoramento-da-clozapina-sistema-rems/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/surveillance-de-la-clozapine-systeme-rems/',
    it: 'https://it.psychopharmacologyinstitute.com/section/monitoraggio-della-clozapina-sistema-rems/',
    de: 'https://de.psychopharmacologyinstitute.com/section/clozapin-ueberwachung-rems-system/',
  },
  'managing-fever-and-hematologic-adverse-effects-of-clozapine-2733-5467': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/controle-da-febre-e-dos-efeitos-adversos-hematologicos-da-clozapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/gestion-de-la-fievre-et-des-effets-indesirables-hematologiques-de-la-clozapine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/gestione-della-febbre-e-degli-effetti-avversi-ematologici-della-clozapina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/umgang-mit-fieber-und-haematologischen-nebenwirkungen-von-clozapin/',
  },
  'managing-cardiac-and-metabolic-adverse-effects-of-clozapine-2733-5468': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/gerenciamento-dos-efeitos-adversos-cardiacos-e-metabolicos-da-clozapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/gestion-des-effets-indesirables-cardiaques-et-metaboliques-de-la-clozapine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/gestione-degli-effetti-avversi-cardiaci-e-metabolici-della-clozapina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/umgang-mit-kardialen-und-metabolischen-nebenwirkungen-von-clozapin/',
  },
  'managing-other-common-adverse-effects-of-clozapine-2733-5469': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/controle-de-outros-efeitos-adversos-comuns-da-clozapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/prise-en-charge-dautres-effets-indesirables-courants-de-la-clozapine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/gestione-di-altri-effetti-avversi-comuni-della-clozapina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/umgang-mit-anderen-haeufigen-unerwuenschten-wirkungen-von-clozapin/',
  },
  'clinical-vignette-managing-side-effects-during-clozapine-titration-2733-5470': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/vinheta-clinica-controle-dos-efeitos-colaterais-durante-a-titulacao-da-clozapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/vignette-clinique-gestion-des-effets-secondaires-lors-de-la-titration-de-la-clozapine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/vignetta-clinica-gestione-degli-effetti-collaterali-durante-la-titolazione-di-clozapina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinische-vignette-umgang-mit-nebenwirkungen-waehrend-der-clozapin-titration/',
  },
  'take-home-messages-2733-5471': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-13/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-13/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-13/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-13/',
  },
  'understanding-the-psychological-response-to-cancer-2724-5439': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/entendendo-a-resposta-psicologica-ao-cancer/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-la-reaction-psychologique-au-cancer/',
    it: 'https://it.psychopharmacologyinstitute.com/section/comprendere-la-risposta-psicologica-al-cancro/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-psychologische-reaktion-auf-krebs-verstehen/',
  },
  'depressive-disorders-and-risk-of-suicide-in-patients-with-cancer-2724-5440': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/transtornos-depressivos-e-risco-de-suicidio-em-pacientes-com-cancer/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/troubles-depressifs-et-risque-de-suicide-chez-les-patients-atteints-de-cancer/',
    it: 'https://it.psychopharmacologyinstitute.com/section/disturbi-depressivi-e-rischio-di-suicidio-nei-pazienti-con-cancro/',
    de: 'https://de.psychopharmacologyinstitute.com/section/depressive-stoerungen-und-selbstmordrisiko-bei-krebspatienten/',
  },
  'making-the-right-diagnosis-depression-vs-grief-and-demoralization-2724-5441': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/fazendo-o-diagnostico-correto-depressao-versus-tristeza-e-desmoralizacao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/poser-le-bon-diagnostic-la-depression-contre-le-chagrin-et-la-demoralisation/',
    it: 'https://it.psychopharmacologyinstitute.com/section/fare-la-diagnosi-giusta-depressione-vs-dolore-e-demoralizzazione/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-richtige-diagnose-stellen-depression-vs-trauer-und-demoralisierung/',
  },
  'treating-depressive-disorders-in-patients-with-cancer-2724-5442': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-de-transtornos-depressivos-em-pacientes-com-cancer/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traiter-les-troubles-depressifs-chez-les-patients-atteints-de-cancer/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-dei-disturbi-depressivi-nei-pazienti-con-cancro/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-depressiven-stoerungen-bei-krebspatienten/',
  },
  'anxiety-disorders-in-patients-with-cancer-2724-5443': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/transtornos-de-ansiedade-em-pacientes-com-cancer/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/troubles-anxieux-chez-les-patients-atteints-de-cancer/',
    it: 'https://it.psychopharmacologyinstitute.com/section/disturbi-dansia-nei-pazienti-con-cancro/',
    de: 'https://de.psychopharmacologyinstitute.com/section/angstzustaende-bei-patienten-mit-krebs/',
  },
  'treating-anxiety-disorders-in-patients-with-cancer-2724-5444': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-de-transtornos-de-ansiedade-em-pacientes-com-cancer/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traiter-les-troubles-anxieux-chez-les-patients-atteints-de-cancer/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-dei-disturbi-dansia-nei-pazienti-con-cancro/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-angststoerungen-bei-patienten-mit-krebs/',
  },
  'delirium-a-neuropsychiatric-complication-in-patients-with-cancer-2724-5445': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/delirium-uma-complicacao-neuropsiquiatrica-em-pacientes-com-cancer/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/le-delirium-une-complication-neuropsychiatrique-chez-les-patients-atteints-de-cancer/',
    it: 'https://it.psychopharmacologyinstitute.com/section/delirium-una-complicanza-neuropsichiatrica-nei-pazienti-con-cancro/',
    de: 'https://de.psychopharmacologyinstitute.com/section/delirium-eine-neuropsychiatrische-komplikation-bei-krebspatienten/',
  },
  'delirium-in-patients-with-cancer-diagnosis-and-implications-2724-5446': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/delirium-em-pacientes-com-cancer-diagnostico-e-implicacoes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/le-delirium-chez-les-patients-atteints-de-cancer-diagnostic-et-implications/',
    it: 'https://it.psychopharmacologyinstitute.com/section/il-delirium-nei-pazienti-con-cancro-diagnosi-e-implicazioni/',
    de: 'https://de.psychopharmacologyinstitute.com/section/delirium-bei-krebspatienten-diagnose-und-auswirkungen/',
  },
  'assessment-of-delirium-in-patients-with-cancer-2724-5447': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/avaliacao-do-delirium-em-pacientes-com-cancer/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/evaluation-du-delirium-chez-les-patients-atteints-de-cancer/',
    it: 'https://it.psychopharmacologyinstitute.com/section/valutazione-del-delirium-nei-pazienti-affetti-da-cancro/',
    de: 'https://de.psychopharmacologyinstitute.com/section/bewertung-des-deliriums-bei-krebspatienten/',
  },
  'management-of-delirium-in-patients-with-cancer-2724-5448': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/gerenciamento-do-delirium-em-pacientes-com-cancer/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/prise-en-charge-du-delirium-chez-les-patients-atteints-de-cancer/',
    it: 'https://it.psychopharmacologyinstitute.com/section/gestione-del-delirium-nei-pazienti-con-cancro/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-delirium-bei-krebspatienten/',
  },
  'take-home-messages-2724-5449': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-11/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-11/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-11/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-11/',
  },
  'a-clinical-case-dsm-5-criteria-epidemiology-and-course-of-tic-disorders-2700-5416': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/um-caso-clinico-criterios-do-dsm-5-epidemiologia-e-curso-dos-transtornos-de-tique/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/un-cas-clinique-les-criteres-du-dsm-5-lepidemiologie-et-levolution-des-troubles-tic/',
    it: 'https://it.psychopharmacologyinstitute.com/section/un-caso-clinico-i-criteri-del-dsm-5-lepidemiologia-e-il-decorso-dei-disturbi-da-tic/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ein-klinischer-fall-dsm-5-kriterien-epidemiologie-und-verlauf-von-ticstoerungen/',
  },
  'understanding-tic-disorders-2700-5417': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/compreensao-dos-transtornos-de-tique/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-les-troubles-du-tic/',
    it: 'https://it.psychopharmacologyinstitute.com/section/comprendere-i-disturbi-da-tic/',
    de: 'https://de.psychopharmacologyinstitute.com/section/tic-stoerungen-verstehen/',
  },
  'assessing-and-screening-for-tic-symptoms-2700-5418': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/avaliacao-e-triagem-de-sintomas-de-tique/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/evaluation-et-depistage-des-symptomes-de-tic/',
    it: 'https://it.psychopharmacologyinstitute.com/section/valutazione-e-screening-dei-sintomi-di-tic/',
    de: 'https://de.psychopharmacologyinstitute.com/section/bewertung-und-screening-von-tic-symptomen/',
  },
  'differential-diagnosis-for-tic-disorders-2700-5419': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/diagnostico-diferencial-para-transtornos-de-tique/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/diagnostic-differentiel-pour-les-troubles-du-tic/',
    it: 'https://it.psychopharmacologyinstitute.com/section/diagnosi-differenziale-per-i-disturbi-da-tic/',
    de: 'https://de.psychopharmacologyinstitute.com/section/differentialdiagnose-fuer-tic-stoerungen/',
  },
  'tic-disorders-and-psychiatric-comorbidities-2700-5420': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/transtornos-de-tique-e-comorbidades-psiquiatricas/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/troubles-du-tic-et-comorbidites-psychiatriques/',
    it: 'https://it.psychopharmacologyinstitute.com/section/disturbi-da-tic-e-comorbidita-psichiatriche/',
    de: 'https://de.psychopharmacologyinstitute.com/section/tic-stoerungen-und-psychiatrische-komorbiditaeten/',
  },
  'overview-of-treatment-for-tic-disorders-2700-5421': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/visao-geral-do-tratamento-para-transtornos-de-tique/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/apercu-du-traitement-des-troubles-du-tic/',
    it: 'https://it.psychopharmacologyinstitute.com/section/panoramica-del-trattamento-dei-disturbi-da-tic/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ueberblick-ueber-die-behandlung-von-tic-stoerungen/',
  },
  'primary-pharmacologic-treatments-for-tics-2700-5422': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamentos-farmacologicos-primarios-para-tiques/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitements-pharmacologiques-primaires-des-tics/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamenti-farmacologici-primari-per-i-tic/',
    de: 'https://de.psychopharmacologyinstitute.com/section/primaere-pharmakologische-behandlungen-fuer-tics/',
  },
  'secondary-pharmacologic-treatments-2700-5423': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamentos-farmacologicos-secundarios/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitements-pharmacologiques-secondaires/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamenti-farmacologici-secondari/',
    de: 'https://de.psychopharmacologyinstitute.com/section/sekundaere-pharmakologische-behandlungen/',
  },
  'tertiary-pharmacologic-treatment-for-tics-2700-5424': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-farmacologico-terciario-para-tiques/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-pharmacologique-tertiaire-des-tics/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-farmacologico-terziario-per-i-tic/',
    de: 'https://de.psychopharmacologyinstitute.com/section/tertiaere-pharmakologische-behandlung-von-tics/',
  },
  'treatment-optimization-for-tic-disorders-2700-5425': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/otimizacao-do-tratamento-para-transtornos-de-tique/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/optimisation-du-traitement-des-troubles-du-tic/',
    it: 'https://it.psychopharmacologyinstitute.com/section/ottimizzazione-del-trattamento-dei-disturbi-da-tic/',
    de: 'https://de.psychopharmacologyinstitute.com/section/optimierung-der-behandlung-von-tic-stoerungen/',
  },
  'alternative-and-experimental-treatments-for-tic-disorders-2700-5426': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamentos-alternativos-e-experimentais-para-transtornos-de-tiques/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitements-alternatifs-et-experimentaux-pour-les-troubles-du-tic/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamenti-alternativi-e-sperimentali-per-i-disturbi-da-tic/',
    de: 'https://de.psychopharmacologyinstitute.com/section/alternative-und-experimentelle-behandlungen-fuer-tic-stoerungen/',
  },
  'solving-the-clinical-case-and-tips-for-clinicians-treating-tic-disorders-2700-5427': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/solucao-do-caso-clinico-e-dicas-para-medicos-que-tratam-de-transtornos-de-tique/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/resolution-du-cas-clinique-et-conseils-pour-les-cliniciens-qui-traitent-les-troubles-du-tic/',
    it: 'https://it.psychopharmacologyinstitute.com/section/risoluzione-del-caso-clinico-e-suggerimenti-per-i-clinici-che-trattano-i-disturbi-da-tic/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-loesung-des-klinischen-falls-und-tipps-fuer-kliniker-die-tic-stoerungen-behandeln/',
  },
  'take-home-messages-2700-5428': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-10/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-10/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-10/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-10/',
  },
  'a-background-on-parasomnias-2698-5407': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/um-historico-sobre-parassonias/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/historique-des-parasomnies/',
    it: 'https://it.psychopharmacologyinstitute.com/section/un-background-sulle-parasonnie/',
    de: 'https://de.psychopharmacologyinstitute.com/section/hintergrundinformationen-zu-parasomnien/',
  },
  'diagnosing-sleep-related-eating-disorder-sred-2698-5408': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/diagnostico-de-transtorno-alimentar-relacionado-ao-sono-sred/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/diagnostiquer-les-troubles-alimentaires-lies-au-sommeil-tals/',
    it: 'https://it.psychopharmacologyinstitute.com/section/diagnosi-del-disturbo-alimentare-legato-al-sonno-sred/',
    de: 'https://de.psychopharmacologyinstitute.com/section/diagnose-der-schlafbezogenen-essstoerung-sred/',
  },
  'medication-induced-sred-sleepwalking-and-sleepdriving-2698-5409': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/sred-induzido-por-medicamentos-sonambulismo-e-direcao-com-sono/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/sred-somnambulisme-et-conduite-en-etat-de-somnolence-induits-par-des-medicaments/',
    it: 'https://it.psychopharmacologyinstitute.com/section/sred-sonnambulismo-e-guida-in-stato-di-ebbrezza-indotti-da-farmaci/',
    de: 'https://de.psychopharmacologyinstitute.com/section/medikamenteninduzierte-sred-schlafwandeln-und-schlafwandeln-am-steuer/',
  },
  'zolpidem-and-amnestic-complex-behaviors-during-sleep-2698-5410': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/zolpidem-e-comportamentos-complexos-amnesticos-durante-o-sono/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/zolpidem-et-comportements-complexes-amnesiques-pendant-le-sommeil/',
    it: 'https://it.psychopharmacologyinstitute.com/section/zolpidem-e-comportamenti-amnestici-complessi-durante-il-sonno/',
    de: 'https://de.psychopharmacologyinstitute.com/section/zolpidem-und-amnestisches-komplexes-verhalten-im-schlaf/',
  },
  'zolpidem-induced-sleepwalking-and-sred-2698-5411': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/sonambulismo-induzido-por-zolpidem-e-sred/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/somnambulisme-induit-par-le-zolpidem-et-sred/',
    it: 'https://it.psychopharmacologyinstitute.com/section/sonnambulismo-e-sred-indotti-da-zolpidem/',
    de: 'https://de.psychopharmacologyinstitute.com/section/zolpidem-induziertes-schlafwandeln-und-sred/',
  },
  'risk-predictors-for-hypnosedative-related-complex-sleep-behaviors-2698-5412': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/preditores-de-risco-para-comportamentos-complexos-de-sono-relacionados-a-hipnose/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/facteurs-de-risque-pour-les-comportements-de-sommeil-complexes-lies-a-lhypnosedation/',
    it: 'https://it.psychopharmacologyinstitute.com/section/predittori-di-rischio-per-i-comportamenti-complessi-del-sonno-legati-allipnosi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/risikopraediktoren-fuer-hypnosebedingtes-komplexes-schlafverhalten/',
  },
  'parasomnias-among-psychiatric-outpatients-findings-on-sred-2698-5413': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/parassonias-em-pacientes-psiquiatricos-ambulatoriais-resultados-do-sred/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/parasomnies-chez-les-patients-psychiatriques-ambulatoires-resultats-du-sred/',
    it: 'https://it.psychopharmacologyinstitute.com/section/parasonnie-tra-i-pazienti-psichiatrici-ambulatoriali-risultati-della-sred/',
    de: 'https://de.psychopharmacologyinstitute.com/section/parasomnien-bei-ambulanten-psychiatriepatienten-ergebnisse-von-sred/',
  },
  'clinical-cases-and-videos-2698-5414': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/casos-clinicos-e-videos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/cas-cliniques-et-videos/',
    it: 'https://it.psychopharmacologyinstitute.com/section/casi-clinici-e-video/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinische-faelle-und-videos/',
  },
  'take-home-messages-2698-5415': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-9/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-9/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-9/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-9/',
  },
  'overview-of-autism-spectrum-disorder-2695-5376': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/visao-geral-do-transtorno-do-espectro-do-autismo/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/apercu-des-troubles-du-spectre-autistique/',
    it: 'https://it.psychopharmacologyinstitute.com/section/panoramica-del-disturbo-dello-spettro-autistico/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ueberblick-ueber-autismus-spektrum-stoerungen/',
  },
  'treatment-of-motor-hyperactivity-and-inattention-in-asd-stimulants-2695-5377': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-da-hiperatividade-motora-e-da-desatencao-no-tea-estimulantes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-de-lhyperactivite-motrice-et-de-linattention-dans-les-tsa-les-stimulants/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-delliperattivita-motoria-e-della-disattenzione-nei-dsa-stimolanti/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-motorischer-hyperaktivitaet-und-unaufmerksamkeit-bei-ass-stimulanzien/',
  },
  'treatment-of-motor-hyperactivity-and-inattention-in-asd-nonstimulants-2695-5378': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-da-hiperatividade-motora-e-da-desatencao-no-tea-nao-estimulantes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-de-lhyperactivite-motrice-et-de-linattention-dans-les-tsa-les-non-stimulants/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-delliperattivita-motoria-e-della-disattenzione-nei-dsa-non-stimolanti/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-motorischer-hyperaktivitaet-und-unaufmerksamkeit-bei-ass-nicht-stimulierende-medikamente/',
  },
  'treatment-of-repetitive-behaviors-in-asd-antidepressants-2695-5379': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-de-comportamentos-repetitivos-no-tea-antidepressivos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-des-comportements-repetitifs-dans-les-tsa-antidepresseurs/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-dei-comportamenti-ripetitivi-nei-dsa-antidepressivi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-repetitiven-verhaltensweisen-bei-ass-antidepressiva/',
  },
  'treatment-of-depression-and-anxiety-in-asd-antidepressants-and-other-strategies-2695-5380': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-da-depressao-e-da-ansiedade-no-tea-antidepressivos-e-outras-estrategias/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-de-la-depression-et-de-lanxiete-dans-les-tsa-antidepresseurs-et-autres-strategies/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-della-depressione-e-dellansia-nei-dsa-antidepressivi-e-altre-strategie/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-depressionen-und-angstzustaenden-bei-ass-antidepressiva-und-andere-strategien/',
  },
  'treatment-of-irritability-in-asd-risperidone-and-aripiprazole-2695-5381': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-da-irritabilidade-no-tea-risperidona-e-aripiprazol/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-de-lirritabilite-dans-les-tsa-risperidone-et-aripiprazole/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-dellirritabilita-nei-dsa-risperidone-e-aripiprazolo/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-reizbarkeit-bei-ass-risperidon-und-aripiprazol/',
  },
  'treatment-of-irritability-in-asd-other-antipsychotic-medications-and-parent-training-2695-5382':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/section/tratamento-da-irritabilidade-no-tea-outros-medicamentos-antipsicoticos-e-treinamento-dos-pais/',
      fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-de-lirritabilite-dans-les-tsa-autres-antipsychotiques-et-formation-des-parents/',
      it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-dellirritabilita-nei-dsa-altri-farmaci-antipsicotici-e-parent-training/',
      de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-reizbarkeit-bei-ass-andere-antipsychotische-medikamente-und-elterntraining/',
    },
  'treatment-of-irritability-in-asd-mood-stabilizers-and-other-strategies-2695-5383': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-da-irritabilidade-no-tea-estabilizadores-de-humor-e-outras-estrategias/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-de-lirritabilite-dans-les-tsa-stabilisateurs-de-lhumeur-et-autres-strategies/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-dellirritabilita-nei-dsa-stabilizzatori-dellumore-e-altre-strategie/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-reizbarkeit-bei-ass-stimmungsstabilisatoren-und-andere-strategien/',
  },
  'treatment-of-sleep-disturbances-in-asd-melatonin-and-other-strategies-2695-5384': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-dos-disturbios-do-sono-no-tea-melatonina-e-outras-estrategias/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-des-troubles-du-sommeil-dans-les-tsa-melatonine-et-autres-strategies/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-dei-disturbi-del-sonno-nel-dsa-melatonina-e-altre-strategie/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-von-schlafstoerungen-bei-ass-melatonin-und-andere-strategien/',
  },
  'treatment-of-impaired-social-relatedness-in-asd-2695-5385': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/tratamento-do-comprometimento-da-relacao-social-no-tea/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/traitement-de-lalteration-des-relations-sociales-dans-les-tsa/',
    it: 'https://it.psychopharmacologyinstitute.com/section/trattamento-della-compromissione-della-relazione-sociale-nel-dsa/',
    de: 'https://de.psychopharmacologyinstitute.com/section/behandlung-der-gestoerten-sozialen-beziehungen-bei-ass/',
  },
  'take-home-messages-2695-5386': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-8/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-8/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-8/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-8/',
  },
  'an-overview-of-substance-use-and-opioid-misuse-2807-5727': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/uma-visao-geral-do-uso-de-substancias-e-do-uso-indevido-de-opioides/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/une-vue-densemble-de-lusage-de-substances-et-du-mesusage-dopioides/',
    it: 'https://it.psychopharmacologyinstitute.com/section/una-panoramica-sulluso-di-sostanze-e-sullabuso-di-oppioidi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ein-ueberblick-ueber-drogenkonsum-und-opioidmissbrauch/',
  },
  'understanding-opioid-intoxication-and-withdrawal-2807-5728': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/entendendo-a-intoxicacao-e-a-abstinencia-de-opioides/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/comprendre-lintoxication-et-le-sevrage-aux-opioides/',
    it: 'https://it.psychopharmacologyinstitute.com/section/capire-lintossicazione-e-lastinenza-da-oppioidi/',
    de: 'https://de.psychopharmacologyinstitute.com/section/verstaendnis-von-opioidintoxikation-und-entzug/',
  },
  'a-comprehensive-guide-to-oud-treatment-2807-5729': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/um-guia-abrangente-para-o-tratamento-de-oud/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/un-guide-complet-sur-le-traitement-de-la-toxicomanie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/una-guida-completa-al-trattamento-delloud/',
    de: 'https://de.psychopharmacologyinstitute.com/section/ein-umfassender-leitfaden-zur-oud-behandlung/',
  },
  'tailored-patient-assessment-a-key-to-effective-oud-treatment-2807-5730': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/avaliacao-personalizada-do-paciente-uma-chave-para-o-tratamento-eficaz-do-oud/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/levaluation-personnalisee-du-patient-la-cle-dun-traitement-efficace-de-la-toxicomanie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/valutazione-personalizzata-del-paziente-una-chiave-per-un-trattamento-efficace-delloud/',
    de: 'https://de.psychopharmacologyinstitute.com/section/massgeschneiderte-patientenbeurteilung-ein-schluessel-zur-wirksamen-oud-behandlung/',
  },
  'methadone-for-managing-oud-2807-5731': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/metadona-para-o-tratamento-de-oud/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-methadone-pour-la-prise-en-charge-de-la-toxicomanie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/metadone-per-la-gestione-delloud/',
    de: 'https://de.psychopharmacologyinstitute.com/section/methadon-zur-behandlung-von-oud/',
  },
  'buprenorphine-for-managing-oud-2807-5732': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/buprenorfina-para-o-tratamento-de-oud/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/la-buprenorphine-pour-la-prise-en-charge-de-la-toxicomanie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/buprenorfina-per-la-gestione-delloud/',
    de: 'https://de.psychopharmacologyinstitute.com/section/buprenorphin-zur-behandlung-von-oud/',
  },
  'examining-the-efficacy-of-extended-release-naltrexone-oud-2807-5733': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/examinando-a-eficacia-da-naltrexona-de-liberacao-prolongada-para-o-uso-de-drogas/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/examen-de-lefficacite-de-la-naltrexone-a-liberation-prolongee-dans-loud/',
    it: 'https://it.psychopharmacologyinstitute.com/section/esame-dellefficacia-del-naltrexone-a-rilascio-prolungato-oud/',
    de: 'https://de.psychopharmacologyinstitute.com/section/untersuchung-der-wirksamkeit-von-naltrexon-mit-verlaengerter-wirkstofffreisetzung-oud/',
  },
  'the-role-of-naloxone-in-managing-oud-2807-5734': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/o-papel-da-naloxona-no-controle-do-oud/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/le-role-de-la-naloxone-dans-la-prise-en-charge-de-la-toxicomanie/',
    it: 'https://it.psychopharmacologyinstitute.com/section/il-ruolo-del-naloxone-nella-gestione-delloud/',
    de: 'https://de.psychopharmacologyinstitute.com/section/die-rolle-von-naloxon-bei-der-behandlung-von-oud/',
  },
  'clinical-case-initiating-buprenorphine-treatment-2807-5735': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/caso-clinico-inicio-do-tratamento-com-buprenorfina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/cas-clinique-initier-un-traitement-a-la-buprenorphine/',
    it: 'https://it.psychopharmacologyinstitute.com/section/caso-clinico-inizio-del-trattamento-con-buprenorfina/',
    de: 'https://de.psychopharmacologyinstitute.com/section/klinischer-fall-beginn-einer-buprenorphin-behandlung/',
  },
  'take-home-messages-2807-5736': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/section/mensagens-para-levar-para-casa-18/',
    fr: 'https://fr.psychopharmacologyinstitute.com/section/messages-a-emporter-18/',
    it: 'https://it.psychopharmacologyinstitute.com/section/messaggi-utili-18/',
    de: 'https://de.psychopharmacologyinstitute.com/section/botschaften-zum-mitnehmen-18/',
  },
};

export const publicationUrls = {
  'quick-take-vol-60-2788': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-60/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-60/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-60/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-60/',
  },
  'quick-take-vol-59-2778': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-59/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-59/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-59/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-59/',
  },
  'quick-take-vol-58-2777': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-58/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-58/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-58/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-58/',
  },
  'quick-take-vol-57-2776': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-57/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-57/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-57/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-57/',
  },
  'quick-take-vol-56-2775': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-56/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-56/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-56/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-56/',
  },
  'quick-take-vol-55-2774': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-55/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-55/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-55/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-55/',
  },
  'quick-take-vol-54-2738': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-54/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-54/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-54/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-54/',
  },
  'quick-take-vol-53-2737': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-53/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-53/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-53/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-53/',
  },
  'quick-take-vol-52-2731': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-52/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-52/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-52/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-52/',
  },
  'quick-take-vol-51-2726': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-51/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-51/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-51/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-51/',
  },
  'quick-take-vol-50-2708': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-50/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-50/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-50/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-50/',
  },
  'quick-take-vol-49-2707': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-49/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-49/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-49/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-49/',
  },
  'quick-take-vol-48-2706': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quick-takes-vol-48/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/quick-takes-vol-48/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quick-takes-vol-48/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quick-takes-vol-48/',
  },
  'cap-smart-takes-vol-19-2783': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-19/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-19/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-19/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-19/',
  },
  'cap-smart-takes-vol-18-2782': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-18/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-18/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-18/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-18/',
  },
  'cap-smart-takes-vol-17-2781': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-17/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-17/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-17/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-17/',
  },
  'cap-smart-takes-vol-16-2780': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-16/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-16/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-16/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-16/',
  },
  'cap-smart-takes-vol-15-2779': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-15/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-15/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-15/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-15/',
  },
  'cap-smart-takes-vol-14-2753': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-14/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-14/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-14/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-14/',
  },
  'cap-smart-takes-vol-13-2752': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-13/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-13/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-13/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-13/',
  },
  'cap-smart-takes-vol-12-2751': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-12/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-12/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-12/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-12/',
  },
  'cap-smart-takes-vol-11-2750': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-11/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-11/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-11/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-11/',
  },
  'cap-smart-takes-vol-10-2749': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-10/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-10/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-10/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-10/',
  },
  'cap-smart-takes-vol-09-2748': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-09/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-09/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-09/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-09/',
  },
  'cap-smart-takes-vol-08-2747': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-08/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-08/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-08/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-08/',
  },
  'cap-smart-takes-vol-07-2746': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-07/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-07/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-07/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/cap-smart-takes-vol-07/',
  },
  'lamotrigine-from-current-indications-to-cardiac-side-effects-2791': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/lamotrigina-das-indicacoes-atuais-aos-efeitos-colaterais-cardiacos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/lamotrigine-des-indications-actuelles-aux-effets-secondaires-cardiaques/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/lamotrigina-dalle-attuali-indicazioni-agli-effetti-collaterali-cardiaci/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/lamotrigin-von-aktuellen-indikationen-bis-zu-kardialen-nebenwirkungen/',
  },
  'psychodynamic-psychopharmacology-alliance-communication-and-psychotherapy-skills-2784': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/psicofarmacologia-psicodinamica-habilidades-de-alianca-comunicacao-e-psicoterapia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/psychopharmacologie-psychodynamique-alliance-communication-et-competences-en-psychotherapie/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/psicofarmacologia-psicodinamica-competenze-di-alleanza-comunicazione-e-psicoterapia/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/psychodynamische-psychopharmakologie-buendnis-kommunikations-und-psychotherapie-faehigkeiten/',
  },
  'navigating-insomnia-in-major-depressive-disorder-advances-in-management-2765': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/como-lidar-com-a-insonia-no-transtorno-depressivo-maior-avancos-no-gerenciamento/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/linsomnie-dans-le-trouble-depressif-majeur-progres-dans-la-prise-en-charge/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/come-gestire-linsonnia-nel-disturbo-depressivo-maggiore-progressi-nella-gestione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/umgang-mit-schlaflosigkeit-bei-schweren-depressiven-stoerungen-fortschritte-im-management/',
  },
  'natural-medication-in-psychiatry-focus-on-versatile-agents-and-combination-strategies-2768': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/medicacao-natural-em-psiquiatria-foco-em-agentes-versateis-e-estrategias-de-combinacao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/les-medicaments-naturels-en-psychiatrie-les-agents-polyvalents-et-les-strategies-dassociation/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/farmaci-naturali-in-psichiatria-focus-su-agenti-versatili-e-strategie-di-combinazione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/natuerliche-arzneimittel-in-der-psychiatrie-schwerpunkt-auf-vielseitigen-wirkstoffen-und-kombinationsstrategien/',
  },
  'psychodynamic-psychopharmacology-from-biomedical-approaches-to-patient-relationships-2767': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/psicofarmacologia-psicodinamica-das-abordagens-biomedicas-as-relacoes-com-os-pacientes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/psychopharmacologie-psychodynamique-des-approches-biomedicales-aux-relations-avec-les-patients/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/psicofarmacologia-psicodinamica-dagli-approcci-biomedici-alla-relazione-con-il-paziente/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/psychodynamische-psychopharmakologie-von-biomedizinischen-ansaetzen-zu-patientenbeziehungen/',
  },
  'perinatal-treatment-of-bipolar-disorder-2766': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/tratamento-perinatal-do-transtorno-bipolar/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/traitement-perinatal-du-trouble-bipolaire/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/trattamento-perinatale-del-disturbo-bipolare/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/perinatale-behandlung-der-bipolaren-stoerung/',
  },
  'the-psychopharmacology-of-agitation-managing-behavioral-emergencies-2786': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/the-psychopharmacology-of-agitation-psicofarmacologia-da-agitacao-gerenciando-emergencias-comportamentais/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/la-psychopharmacologie-de-lagitation-gerer-les-urgences-comportementales/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/psicofarmacologia-dellagitazione-gestione-delle-emergenze-comportamentali/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/die-psychopharmakologie-der-agitation-management-verhaltensbedingter-notfaelle/',
  },
  'fluvoxamine-practical-guide-pharmacology-indications-dosing-guidelines-and-adverse-effects-2823':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/publication/guia-pratico-de-fluvoxamina-farmacologia-indicacoes-diretrizes-de-dosagem-e-efeitos-adversos/',
      fr: 'https://fr.psychopharmacologyinstitute.com/publication/guide-pratique-de-la-fluvoxamine-pharmacologie-indications-posologie-et-effets-indesirables/',
      it: 'https://it.psychopharmacologyinstitute.com/publication/guida-pratica-alla-fluvoxamina-farmacologia-indicazioni-linee-guida-per-il-dosaggio-ed-effetti-collaterali/',
      de: 'https://de.psychopharmacologyinstitute.com/publication/fluvoxamin-praktischer-leitfaden-pharmakologie-indikationen-dosierungsrichtlinien-und-unerwuenschte-wirkungen/',
    },
  'fluoxetine-practical-guide-pharmacology-indications-dosing-guidelines-and-adverse-effects-2822':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/publication/guia-pratico-da-fluoxetina-farmacologia-indicacoes-diretrizes-de-dosagem-e-efeitos-adversos/',
      fr: 'https://fr.psychopharmacologyinstitute.com/publication/guide-pratique-de-la-fluoxetine-pharmacologie-indications-posologie-et-effets-indesirables/',
      it: 'https://it.psychopharmacologyinstitute.com/publication/guida-pratica-alla-fluoxetina-farmacologia-indicazioni-linee-guida-di-dosaggio-ed-effetti-avversi/',
      de: 'https://de.psychopharmacologyinstitute.com/publication/fluoxetin-praktischer-leitfaden-pharmakologie-indikationen-dosierungsrichtlinien-und-unerwuenschte-wirkungen/',
    },
  'citalopram-and-escitalopram-a-summary-of-key-differences-and-similarities-2179': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/citalopram-e-escitalopram-um-resumo-das-principais-diferencas-e-semelhancas/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/citalopram-et-escitalopram-resume-des-principales-differences-et-similitudes/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/citalopram-ed-escitalopram-sintesi-delle-principali-differenze-e-somiglianze/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/citalopram-und-escitalopram-eine-zusammenfassung-der-wichtigsten-unterschiede-und-gemeinsamkeiten/',
  },
  '2019-in-review-new-drugs-indications-and-formulations-2543': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/revisao-de-2019-novos-medicamentos-indicacoes-e-formulacoes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/bilan-de-lannee-2019-nouveaux-medicaments-nouvelles-indications-et-nouvelles-formulations/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/il-2019-in-rassegna-nuovi-farmaci-indicazioni-e-formulazioni/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/rueckblick-auf-2019-neue-medikamente-indikationen-und-formulierungen/',
  },
  'psychopharmacology-journals-and-resources-2171': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/revistas-e-recursos-de-psicofarmacologia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/revues-et-ressources-en-psychopharmacologie/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/riviste-e-risorse-di-psicofarmacologia/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/psychopharmakologie-zeitschriften-und-ressourcen/',
  },
  'adverse-effects-of-ssris-2169': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/efeitos-adversos-dos-isrs/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/effets-indesirables-des-isrs/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/effetti-avversi-degli-ssri/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/unerwuenschte-wirkungen-von-ssri/',
  },
  'pharmacokinetics-of-aripiprazole-clinical-summary-2118': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/farmacocinetica-do-aripiprazol-resumo-clinico/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/pharmacocinetique-de-laripiprazole-resume-clinique/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/farmacocinetica-dellaripiprazolo-riassunto-clinico/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/pharmakokinetik-von-aripiprazol-klinische-zusammenfassung/',
  },
  '2018-apa-alcohol-use-disorder-guideline-summary-of-recommendations-2238': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/diretriz-de-2018-da-apa-sobre-transtorno-por-uso-de-alcool-resumo-das-recomendacoes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/2018-apa-alcohol-use-disorder-guideline-lignes-directrices-de-lapa-sur-les-troubles-lies-a-la-consommation-dalcool-resume-des-recommandations/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/linea-guida-2018-dellapa-sul-disturbo-da-uso-di-alcol-sintesi-delle-raccomandazioni/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/apa-leitlinie-2018-zum-thema-alkoholkonsumstoerung-zusammenfassung-der-empfehlungen/',
  },
  'antidepressants-and-weight-change-an-evidence-based-tool-2176': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/antidepressivos-e-mudanca-de-peso-uma-ferramenta-baseada-em-evidencias/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/antidepresseurs-et-changement-de-poids-un-outil-fonde-sur-des-donnees-probantes/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/antidepressivi-e-cambiamento-di-peso-uno-strumento-basato-sullevidenza/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/antidepressiva-und-gewichtsveraenderung-ein-evidenzbasiertes-instrument/',
  },
  'bipolar-disorder-treatment-guidelines-a-2018-update-2206': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/diretrizes-de-tratamento-do-transtorno-bipolar-uma-atualizacao-de-2018/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/lignes-directrices-pour-le-traitement-du-trouble-bipolaire-mise-a-jour-2018/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/linee-guida-per-il-trattamento-del-disturbo-bipolare-aggiornamento-2018/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/leitlinien-zur-behandlung-der-bipolaren-stoerung-ein-update-2018/',
  },
  'psychopharmacology-highlights-from-the-2018-apa-annual-meeting-2249': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/destaques-de-psicofarmacologia-da-reuniao-anual-da-apa-de-2018/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/psychopharmacologie-les-points-forts-de-la-reunion-annuelle-2018-de-lapa/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/punti-salienti-di-psicofarmacologia-dalla-riunione-annuale-dellapa-del-2018/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/highlights-aus-der-psychopharmakologie-auf-der-apa-jahrestagung-2018/',
  },
  'antipsychotics-news-cariprazine-risperidone-and-new-drug-applications-2233': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/noticias-sobre-antipsicoticos-cariprazina-risperidona-e-pedidos-de-registro-de-novos-medicamentos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/nouvelles-sur-les-antipsychotiques-cariprazine-risperidone-et-nouvelles-demandes-dautorisation-de-mise-sur-le-marche/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/notizie-sugli-antipsicotici-cariprazina-risperidone-e-nuove-richieste-di-farmaci/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/nachrichten-ueber-antipsychotika-cariprazin-risperidon-und-neue-arzneimittelantraege/',
  },
  'quetiapine-for-depression-2130': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quetiapina-para-depressao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/la-quetiapine-pour-la-depression/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quetiapina-per-la-depressione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quetiapin-bei-depressionen/',
  },
  '2017-in-review-new-drugs-formulations-and-clinical-guidelines-2237': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/revisao-de-2017-novos-medicamentos-formulacoes-e-diretrizes-clinicas/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/bilan-de-lannee-2017-nouveaux-medicaments-nouvelles-formulations-et-nouvelles-directives-cliniques/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/il-2017-in-rassegna-nuovi-farmaci-formulazioni-e-linee-guida-cliniche/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/2017-im-rueckblick-neue-medikamente-formulierungen-und-klinische-leitlinien/',
  },
  'cannabis-and-mental-health-2017-report-of-the-national-academy-of-sciences-engineering-and-medicine-2218':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/publication/cannabis-e-saude-mental-relatorio-de-2017-da-academia-nacional-de-ciencias-engenharia-e-medicina/',
      fr: 'https://fr.psychopharmacologyinstitute.com/publication/cannabis-et-sante-mentale-rapport-2017-de-lacademie-nationale-des-sciences-de-lingenierie-et-de-la-medecine/',
      it: 'https://it.psychopharmacologyinstitute.com/publication/cannabis-e-salute-mentale-rapporto-2017-dellaccademia-nazionale-di-scienze-ingegneria-e-medicina/',
      de: 'https://de.psychopharmacologyinstitute.com/publication/cannabis-und-psychische-gesundheit-bericht-2017-der-national-academy-of-sciences-engineering-and-medicine/',
    },
  'comments-on-the-2017-vadod-practice-guideline-for-the-management-of-ptsd-2230': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/comentarios-sobre-a-diretriz-pratica-do-va-dod-de-2017-para-o-tratamento-do-tept/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/commentaires-sur-les-lignes-directrices-de-pratique-2017-de-la-va-dod-pour-la-prise-en-charge-du-tspt/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/commenti-sulla-linea-guida-di-pratica-2017-va-dod-per-la-gestione-del-dpts/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/kommentare-zur-va-dod-praxisleitlinie-2017-fuer-die-behandlung-von-ptbs/',
  },
  'antipsychotic-agents-2107': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/agentes-antipsicoticos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/agents-antipsychotiques/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/agenti-antipsicotici/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/antipsychotische-wirkstoffe/',
  },
  'long-acting-injectable-antipsychotics-a-practical-guide-for-prescribers-2201': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/antipsicoticos-injetaveis-de-acao-prolongada-um-guia-pratico-para-prescritores/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/antipsychotiques-injectables-a-action-prolongee-guide-pratique-a-lintention-des-prescripteurs/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/antipsicotici-iniettabili-a-lunga-durata-dazione-guida-pratica-per-i-prescrittori/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/langwirksame-injizierbare-antipsychotika-ein-praktischer-leitfaden-fuer-verordner/',
  },
  'new-paliperidone-formulation-approved-invega-trinza-2192': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/nova-formulacao-de-paliperidona-aprovada-invega-trinza/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/approbation-dune-nouvelle-formulation-de-la-paliperidone-invega-trinza/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/approvata-una-nuova-formulazione-di-paliperidone-invega-trinza/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/neue-paliperidon-formulierung-zugelassen-invega-trinza/',
  },
  'pharmacotherapy-of-treatment-resistant-ocd-augmentation-strategies-2191': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/farmacoterapia-do-toc-resistente-ao-tratamento-estrategias-de-aumento/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/pharmacotherapie-des-toc-resistants-au-traitement-strategies-daugmentation/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/farmacoterapia-del-doc-resistente-al-trattamento-strategie-di-potenziamento/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/pharmakotherapie-der-therapieresistenten-ocd-strategien-zur-ergaenzung/',
  },
  'mirtazapine-essentials-moa-indications-adverse-effects-pharmacokinetics-and-dosing-2222': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/aspectos-essenciais-da-mirtazapina-moa-indicacoes-efeitos-adversos-farmacocinetica-e-dosagem/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/lessentiel-sur-la-mirtazapine-mode-daction-indications-effets-indesirables-pharmacocinetique-et-posologie/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/elementi-essenziali-di-mirtazapina-moa-indicazioni-effetti-avversi-farmacocinetica-e-dosaggio/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/mirtazapin-grundlagen-wirkungsweise-indikationen-unerwuenschte-wirkungen-pharmakokinetik-und-dosierung/',
  },
  'sertraline-essentials-mechanism-of-action-indications-pharmacokinetics-and-dosing-2216': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/fundamentos-da-sertralina-mecanismo-de-acao-indicacoes-farmacocinetica-e-dosagem/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/lessentiel-sur-la-sertraline-mecanisme-daction-indications-pharmacocinetique-et-posologie/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/elementi-essenziali-della-sertralina-meccanismo-dazione-indicazioni-farmacocinetica-e-dosaggio/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/sertralin-grundlagen-wirkmechanismus-indikationen-pharmakokinetik-und-dosierung/',
  },
  'the-psychopharmacology-of-fluoxetine-mechanism-of-action-indications-pharmacokinetics-and-dosing-2210':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/publication/a-psicofarmacologia-da-fluoxetina-mecanismo-de-acao-indicacoes-farmacocinetica-e-dosagem/',
      fr: 'https://fr.psychopharmacologyinstitute.com/publication/la-psychopharmacologie-de-la-fluoxetine-mecanisme-daction-indications-pharmacocinetique-et-dosage/',
      it: 'https://it.psychopharmacologyinstitute.com/publication/psicofarmacologia-della-fluoxetina-meccanismo-dazione-indicazioni-farmacocinetica-e-dosaggio/',
      de: 'https://de.psychopharmacologyinstitute.com/publication/die-psychopharmakologie-von-fluoxetin-wirkmechanismen-indikationen-pharmakokinetik-und-dosierung/',
    },
  '2016-in-review-a-psychopharmacology-update-for-clinicians-2214': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/2016-em-revista-uma-atualizacao-em-psicofarmacologia-para-medicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/revue-de-lannee-2016-une-mise-a-jour-de-la-psychopharmacologie-pour-les-cliniciens/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/il-2016-in-rassegna-un-aggiornamento-di-psicofarmacologia-per-i-clinici/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/rueckblick-auf-2016-ein-psychopharmakologie-update-fuer-kliniker/',
  },
  'antidepressant-discontinuation-syndrome-diagnosis-prevention-and-management-2213': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/sindrome-de-descontinuacao-de-antidepressivos-diagnostico-prevencao-e-tratamento/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/syndrome-darret-des-antidepresseurs-diagnostic-prevention-et-prise-en-charge/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/sindrome-da-interruzione-degli-antidepressivi-diagnosi-prevenzione-e-gestione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/absetzsyndrom-bei-antidepressiva-diagnose-praevention-und-management/',
  },
  'lithiums-mechanism-of-action-an-illustrated-review-2212': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/mecanismo-de-acao-do-litio-uma-revisao-ilustrada/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/mecanisme-daction-du-lithium-une-revue-illustree/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/il-meccanismo-dazione-del-litio-una-rassegna-illustrata/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/der-wirkmechanismus-von-lithium-ein-illustrierter-ueberblick/',
  },
  'quetiapine-for-mania-2132': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/quetiapina-para-mania/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/la-quetiapine-dans-la-manie/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quetiapina-per-la-mania/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quetiapin-bei-manie/',
  },
  'quetiapine-for-bipolar-depression-2131': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/quetiapina-para-depressao-bipolar/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/la-quetiapine-dans-la-depression-bipolaire/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/quetiapina-per-la-depressione-bipolare/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quetiapin-bei-bipolaren-depressionen/',
  },
  'quetiapine-indications-fda-approved-and-off-label-uses-2112': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/indicacoes-da-quetiapina-usos-aprovados-pela-fda-e-usos-off-label/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/indications-de-la-quetiapine-utilisations-approuvees-par-la-fda-et-utilisations-hors-indication/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/indicazioni-della-quetiapina-usi-approvati-dalla-fda-e-fuori-indicazione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quetiapin-indikationen-von-der-fda-zugelassene-und-off-label-verwendungen/',
  },
  'quetiapine-pharmacokinetics-2111': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/farmacocinetica-da-quetiapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/pharmacocinetique-de-la-quetiapine/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/farmacocinetica-della-quetiapina/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quetiapin-pharmakokinetik/',
  },
  'mechanism-of-action-of-quetiapine-2109': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/mecanismo-de-acao-da-quetiapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/mecanisme-daction-de-la-quetiapine/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/meccanismo-dazione-della-quetiapina/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/der-wirkmechanismus-von-quetiapin/',
  },
  'quetiapine-prescribing-information-2108': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/informacoes-sobre-a-prescricao-de-quetiapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/informations-sur-la-prescription-de-la-quetiapine/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/informazioni-sulla-prescrizione-di-quetiapina/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/quetiapin-verschreibungspflichtige-informationen/',
  },
  'venlafaxine-and-desvenlafaxine-differences-and-similarities-2178': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/venlafaxina-e-desvenlafaxina-diferencas-e-semelhancas/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/venlafaxine-et-desvenlafaxine-differences-et-similitudes/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/venlafaxina-e-desvenlafaxina-differenze-e-analogie/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/venlafaxin-und-desvenlafaxin-unterschiede-und-gemeinsamkeiten/',
  },
  'the-psychopharmacology-of-bupropion-an-illustrated-overview-2174': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/the-psychopharmacology-of-bupropion-uma-visao-geral-ilustrada/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/la-psychopharmacologie-du-bupropion-un-apercu-illustre/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/la-psicofarmacologia-del-bupropione-una-panoramica-illustrata/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/die-psychopharmakologie-von-bupropion-ein-illustrierter-ueberblick/',
  },
  'pharmacokinetics-of-risperidone-clinical-summary-2125': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/farmacocinetica-da-risperidona-resumo-clinico/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/pharmacocinetique-de-la-risperidone-resume-clinique/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/farmacocinetica-del-risperidone-riassunto-clinico/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/pharmakokinetik-von-risperidon-klinische-zusammenfassung/',
  },
  'risperidone-indications-fda-approved-and-off-label-uses-2124': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/indicacoes-da-risperidona-usos-aprovados-pela-fda-e-usos-off-label/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/indications-de-la-risperidone-utilisations-approuvees-par-la-fda-et-utilisations-hors-indication/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/indicazioni-del-risperidone-usi-approvati-dalla-fda-e-fuori-indicazione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/risperidon-indikationen-von-der-fda-zugelassene-und-off-label-verwendungen/',
  },
  'd2-receptors-in-psychopharmacology-2116': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/receptores-d2-em-psicofarmacologia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/les-recepteurs-d2-en-psychopharmacologie/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/recettori-d2-in-psicofarmacologia/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/d2-rezeptoren-in-der-psychopharmakologie/',
  },
  'mechanism-of-action-of-ssris-2135': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/mecanismo-de-acao-dos-isrs/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/mecanisme-daction-des-isrs/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/meccanismo-dazione-degli-ssri/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/der-wirkmechanismus-von-ssri/',
  },
  'aripiprazole-for-schizophrenia-2129': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/aripiprazol-para-esquizofrenia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/aripiprazole-pour-la-schizophrenie/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/aripiprazolo-per-la-schizofrenia/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/aripiprazol-bei-schizophrenie/',
  },
  'aripiprazole-for-depression-2128': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/aripiprazol-para-depressao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/aripiprazole-pour-la-depression/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/aripiprazolo-per-la-depressione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/aripiprazol-bei-depressionen/',
  },
  'aripiprazole-for-mania-2127': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/aripiprazol-para-mania/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/aripiprazole-pour-la-manie/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/aripiprazolo-per-la-mania/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/aripiprazol-bei-manie/',
  },
  'aripiprazole-indications-fda-approved-and-off-label-uses-2120': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/indicacoes-do-aripiprazol-usos-aprovados-pela-fda-e-usos-off-label/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/indications-de-laripiprazole-utilisations-approuvees-par-la-fda-et-utilisations-hors-indication/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/indicazioni-dellaripiprazolo-usi-approvati-dalla-fda-e-fuori-indicazione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/aripiprazol-indikationen-fda-zugelassene-und-off-label-anwendungen/',
  },
  'mechanism-of-action-of-aripiprazole-2119': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/mecanismo-de-acao-do-aripiprazol/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/mecanisme-daction-de-laripiprazole/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/meccanismo-dazione-dellaripiprazolo/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/der-wirkmechanismus-von-aripiprazol/',
  },
  'the-four-dopamine-pathways-relevant-to-antipsychotics-pharmacology-2096': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/as-quatro-vias-da-dopamina-relevantes-para-a-farmacologia-dos-antipsicoticos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/les-quatre-voies-de-la-dopamine-pertinentes-pour-la-pharmacologie-des-antipsychotiques/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/le-quattro-vie-della-dopamina-rilevanti-per-la-farmacologia-degli-antipsicotici/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/die-vier-dopaminwege-die-fuer-die-pharmakologie-von-antipsychotika-relevant-sind/',
  },
  'first-vs-second-generation-antipsychotics-2082': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/antipsicoticos-de-primeira-e-segunda-geracao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/antipsychotiques-de-premiere-ou-de-deuxieme-generation/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/antipsicotici-di-prima-e-seconda-generazione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/antipsychotika-der-ersten-und-zweiten-generation/',
  },
  'the-psychopharmacology-of-paroxetine-mechanism-of-action-indications-pharmacokinetics-and-dosing-2204':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/publication/a-psicofarmacologia-da-paroxetina-mecanismo-de-acao-indicacoes-farmacocinetica-e-dosagem/',
      fr: 'https://fr.psychopharmacologyinstitute.com/publication/la-psychopharmacologie-de-la-paroxetine-mecanisme-daction-indications-pharmacocinetique-et-dosage/',
      it: 'https://it.psychopharmacologyinstitute.com/publication/psicofarmacologia-della-paroxetina-meccanismo-di-azione-indicazioni-farmacocinetica-e-dosaggio/',
      de: 'https://de.psychopharmacologyinstitute.com/publication/die-psychopharmakologie-von-paroxetin-wirkmechanismen-indikationen-pharmakokinetik-und-dosierung/',
    },
  'summary-of-newly-approved-antipsychotics-2200': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/resumo-dos-antipsicoticos-recem-aprovados/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/resume-des-antipsychotiques-recemment-approuves/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/riassunto-degli-antipsicotici-di-nuova-approvazione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/zusammenfassung-der-neu-zugelassenen-antipsychotika/',
  },
  'asenapine-saphris-pharmacokinetics-2140': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/farmacocinetica-da-asenapina-saphris/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/asenapine-saphris-pharmacocinetique/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/farmacocinetica-dellasenapina-saphris/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/asenapin-saphris-pharmakokinetik/',
  },
  'neurological-side-effects-of-antipsychotics-akathisia-dystonia-and-parkinsonism-2095': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/efeitos-colaterais-neurologicos-dos-antipsicoticos-acatisia-distonia-e-parkinsonismo/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/effets-secondaires-neurologiques-des-antipsychotiques-akathisie-dystonie-et-parkinsonisme/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/effetti-collaterali-neurologici-degli-antipsicotici-acatisia-distonia-e-parkinsonismo/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/neurologische-nebenwirkungen-von-antipsychotika-akathisie-dystonie-und-parkinsonismus/',
  },
  'mechanism-of-action-of-antipsychotic-agents-2094': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/mecanismo-de-acao-dos-agentes-antipsicoticos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/mecanisme-daction-des-agents-antipsychotiques/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/meccanismo-dazione-degli-agenti-antipsicotici/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/wirkmechanismus-von-antipsychotika/',
  },
  'antipsychotics-for-schizophrenia-a-clinical-overview-2093': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/antipsicoticos-para-esquizofrenia-uma-visao-geral-clinica/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/les-antipsychotiques-dans-la-schizophrenie-apercu-clinique/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/antipsicotici-per-la-schizofrenia-una-panoramica-clinica/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/antipsychotika-bei-schizophrenie-ein-klinischer-ueberblick/',
  },
  'lurasidone-latuda-interactions-2150': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/interacoes-da-lurasidona-latuda/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/interactions-avec-la-lurasidone-latuda/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/interazioni-con-il-lurasidone-latuda/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/lurasidon-latuda-wechselwirkungen/',
  },
  'lurasidone-indications-fda-approved-uses-2148': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/indicacoes-da-lurasidona-usos-aprovados-pelo-fda/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/indications-de-la-lurasidone-utilisations-approuvees-par-la-fda/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/indicazioni-del-lurasidone-usi-approvati-dalla-fda/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/lurasidon-indikationen-von-der-fda-zugelassene-anwendungen/',
  },
  'lurasidone-adverse-effects-2147': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/lurasidona-efeitos-adversos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/lurasidone-effets-indesirables/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/lurasidone-effetti-avversi/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/lurasidon-unerwuenschte-wirkungen/',
  },
  'opioid-receptors-2182': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/receptores-opioides/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/recepteurs-opioides/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/recettori-oppioidi/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/opioid-rezeptoren/',
  },
  'valproate-in-psychiatry-approved-indications-and-off-label-uses-2188': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/valproato-em-psiquiatria-indicacoes-aprovadas-e-usos-off-label/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/le-valproate-en-psychiatrie-indications-approuvees-et-utilisations-hors-indications/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/il-valproato-in-psichiatria-indicazioni-approvate-e-usi-fuori-indicazione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/valproat-in-der-psychiatrie-zugelassene-indikationen-und-off-label-verwendung/',
  },
  'lisdexamfetamine-approved-for-binge-eating-disorder-2190': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/lisdexamfetamina-aprovada-para-o-transtorno-da-compulsao-alimentar/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/la-lisdexamfetamine-approuvee-pour-les-troubles-de-lhyperphagie-boulimique/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/lisdexamfetamina-approvata-per-il-disturbo-da-alimentazione-incontrollata/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/lisdexamfetamin-fuer-binge-eating-stoerung-zugelassen/',
  },
  'methylphenidate-for-adhd-mechanism-of-action-and-formulations-2194': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/metilfenidato-para-tdah-mecanismo-de-acao-e-formulacoes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/le-methylphenidate-dans-le-tdah-mecanisme-daction-et-formulations/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/metilfenidato-per-ladhd-meccanismo-dazione-e-formulazioni/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/methylphenidat-bei-adhs-wirkungsmechanismen-und-formulierungen/',
  },
  'lurasidone-pharmacokinetics-2145': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/farmacocinetica-da-lurasidona/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/pharmacocinetique-de-la-lurasidone/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/farmacocinetica-del-lurasidone/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/lurasidon-pharmakokinetik/',
  },
  'fda-warning-ziprasidone-associated-with-dress-syndrome-2184': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/aviso-da-fda-ziprasidona-associada-a-sindrome-dress/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/avertissement-de-la-fda-la-ziprasidone-associee-au-syndrome-dress/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/avviso-della-fda-ziprasidone-associato-alla-sindrome-dress/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/fda-warnung-ziprasidon-in-verbindung-mit-dem-dress-syndrom/',
  },
  'ziprasidone-adverse-effects-2167': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/efeitos-adversos-da-ziprasidona/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/effets-indesirables-de-la-ziprasidone/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/effetti-avversi-di-ziprasidone/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/ziprasidon-unerwuenschte-wirkungen/',
  },
  'ziprasidone-geodon-pharmacokinetics-2166': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/farmacocinetica-da-ziprasidona-geodon/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/pharmacocinetique-de-la-ziprasidone-geodon/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/farmacocinetica-dello-ziprasidone-geodon/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/ziprasidon-geodon-pharmakokinetik/',
  },
  'mechanism-of-action-and-pharmacodynamics-of-ziprasidone-2165': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/mecanismo-de-acao-e-farmacodinamica-da-ziprasidona/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/mecanisme-daction-et-pharmacodynamique-de-la-ziprasidone/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/meccanismo-dazione-e-farmacodinamica-di-ziprasidone/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/wirkungsmechanismen-und-pharmakodynamik-von-ziprasidon/',
  },
  'ziprasidone-geodon-indications-fda-approved-uses-2164': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/indicacoes-da-ziprasidona-geodon-usos-aprovados-pelo-fda/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/indications-de-la-ziprasidone-geodon-utilisations-approuvees-par-la-fda/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/ziprasidone-geodon-indicazioni-usi-approvati-dalla-fda/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/ziprasidon-geodon-indikationen-von-der-fda-zugelassene-anwendungen/',
  },
  'olanzapine-interactions-2163': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/interacoes-da-olanzapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/interactions-avec-lolanzapine/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/interazioni-con-lolanzapina/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/olanzapin-wechselwirkungen/',
  },
  'olanzapine-adverse-effects-2162': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/efeitos-adversos-da-olanzapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/effets-indesirables-de-lolanzapine/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/effetti-avversi-di-olanzapina/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/unerwuenschte-wirkungen-von-olanzapin/',
  },
  'mechanism-of-action-and-pharmacodynamics-of-olanzapine-2161': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/mecanismo-de-acao-e-farmacodinamica-da-olanzapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/mecanisme-daction-et-pharmacodynamie-de-lolanzapine/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/meccanismo-dazione-e-farmacodinamica-di-olanzapina/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/wirkungsmechanismen-und-pharmakodynamik-von-olanzapin/',
  },
  'olanzapine-indications-fda-approved-uses-2160': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/indicacoes-da-olanzapina-usos-aprovados-pelo-fda/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/indications-de-lolanzapine-utilisations-approuvees-par-la-fda/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/indicazioni-dellolanzapina-usi-approvati-dalla-fda/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/olanzapin-indikationen-von-der-fda-zugelassene-anwendungen/',
  },
  'olanzapine-pharmacokinetics-2159': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/farmacocinetica-da-olanzapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/pharmacocinetique-de-lolanzapine/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/farmacocinetica-dellolanzapina/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/olanzapin-pharmakokinetik/',
  },
  'iloperidone-fanapt-indications-fda-approved-uses-2156': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/indicacoes-da-iloperidona-fanapt-usos-aprovados-pelo-fda/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/indications-de-liloperidone-fanapt-utilisations-approuvees-par-la-fda/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/indicazioni-delliloperidone-fanapt-usi-approvati-dalla-fda/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/iloperidon-fanapt-indikationen-von-der-fda-zugelassene-anwendungen/',
  },
  'iloperidone-fanapt-drug-interactions-2155': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/interacoes-medicamentosas-da-iloperidona-fanapt/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/interactions-medicamenteuses-de-liloperidone-fanapt/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/interazioni-con-iloperidone-fanapt/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/iloperidon-fanapt-wechselwirkungen-mit-anderen-medikamenten/',
  },
  'iloperidone-fanapt-adverse-effects-and-warnings-2154': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/iloperidona-fanapt-efeitos-adversos-e-advertencias/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/iloperidone-fanapt-effets-indesirables-et-mises-en-garde/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/iloperidone-fanapt-effetti-avversi-e-avvertenze/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/iloperidon-fanapt-unerwuenschte-wirkungen-und-warnhinweise/',
  },
  'mechanism-of-action-and-pharmacodynamics-of-iloperidone-fanapt-2153': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/mecanismo-de-acao-e-farmacodinamica-da-iloperidona-fanapt/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/mecanisme-daction-et-pharmacodynamique-de-liloperidone-fanapt/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/meccanismo-dazione-e-farmacodinamica-di-iloperidone-fanapt/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/wirkmechanismus-und-pharmakodynamik-von-iloperidon-fanapt/',
  },
  'iloperidone-pharmacokinetics-2152': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/farmacocinetica-da-iloperidona/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/pharmacocinetique-de-liloperidone/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/farmacocinetica-delliloperidone/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/pharmakokinetik-von-iloperidon/',
  },
  'asenapine-indications-fda-approved-uses-2146': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/indicacoes-da-asenapina-usos-aprovados-pelo-fda/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/indications-de-lasenapine-utilisations-approuvees-par-la-fda/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/indicazioni-dellasenapina-usi-approvati-dalla-fda/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/asenapin-indikationen-von-der-fda-zugelassene-verwendungen/',
  },
  'asenapine-interactions-2143': {
    'pt-br': 'https://pt.psychopharmacologyinstitute.com/publication/interacoes-da-asenapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/interactions-avec-lasenapine/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/interazioni-con-lasenapina/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/asenapin-wechselwirkungen/',
  },
  'asenapine-adverse-effects-2142': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/efeitos-adversos-da-asenapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/effets-indesirables-de-lasenapine/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/effetti-avversi-dellasenapina/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/asenapin-unerwuenschte-wirkungen/',
  },
  'mechanism-of-action-and-pharmacodynamics-of-asenapine-2141': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/mecanismo-de-acao-e-farmacodinamica-da-asenapina/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/mecanisme-daction-et-pharmacodynamie-de-lasenapine/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/meccanismo-dazione-e-farmacodinamica-dellasenapina/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/wirkungsmechanismen-und-pharmakodynamik-von-asenapin/',
  },
  'first-generation-antipsychotics-an-introduction-2110': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/antipsicoticos-de-primeira-geracao-uma-introducao/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/antipsychotiques-de-premiere-generation-introduction/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/antipsicotici-di-prima-generazione-introduzione/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/antipsychotika-der-ersten-generation-eine-einfuehrung/',
  },
  '2014-in-review-new-guidelines-and-drug-approvals-2185': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/revisao-de-2014-novas-diretrizes-e-aprovacoes-de-medicamentos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/bilan-de-lannee-2014-nouvelles-lignes-directrices-et-approbations-de-medicaments/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/il-2014-in-rassegna-nuove-linee-guida-e-approvazioni-di-farmaci/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/das-jahr-2014-im-rueckblick-neue-leitlinien-und-medikamentenzulassungen/',
  },
  'mechanism-of-action-of-risperidone-2126': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/mecanismo-de-acao-da-risperidona/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/mecanisme-daction-de-la-risperidone/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/meccanismo-dazione-di-risperidone/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/der-wirkmechanismus-von-risperidon/',
  },
  '5-ht1a-receptors-in-psychopharmacology-2123': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/receptores-5-ht1a-em-psicofarmacologia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/les-recepteurs-5-ht1a-en-psychopharmacologie/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/recettori-5-ht1a-in-psicofarmacologia/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/5-ht1a-rezeptoren-in-der-psychopharmakologie/',
  },
  'first-and-second-generation-antipsychotics-for-the-treatment-of-bipolar-disorder-2084': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/antipsicoticos-de-primeira-e-segunda-geracao-para-o-tratamento-do-transtorno-bipolar/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/antipsychotiques-de-premiere-et-de-deuxieme-generation-pour-le-traitement-du-trouble-bipolaire/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/antipsicotici-di-prima-e-seconda-generazione-per-il-trattamento-del-disturbo-bipolare/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/antipsychotika-der-ersten-und-zweiten-generation-zur-behandlung-der-bipolaren-stoerung/',
  },
  'strategies-for-successful-benzodiazepine-deprescribing-2798': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/estrategias-para-a-desprescricao-bem-sucedida-de-benzodiazepinicos/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/strategies-pour-reussir-la-desaccoutumance-aux-benzodiazepines/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/strategie-per-il-successo-della-deprescrizione-delle-benzodiazepine/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/strategien-fuer-eine-erfolgreiche-benzodiazepin-entziehungskur/',
  },
  'understanding-benzodiazepine-prescribing-a-clinicians-guide-2797': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/understanding-benzodiazepine-prescribing-entendendo-a-prescricao-de-benzodiazepinicos-guia-do-clinico/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/comprendre-la-prescription-des-benzodiazepines-guide-du-clinicien/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/capire-la-prescrizione-delle-benzodiazepine-guida-per-il-medico/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/die-verschreibung-von-benzodiazepinen-verstehen-ein-leitfaden-fuer-kliniker/',
  },
  'tms-in-psychiatry-innovations-and-best-practices-2792': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/emt-em-psiquiatria-inovacoes-e-melhores-praticas/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/la-stm-en-psychiatrie-innovations-et-meilleures-pratiques/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/tms-in-psichiatria-innovazioni-e-migliori-pratiche/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/tms-in-der-psychiatrie-innovationen-und-bewaehrte-praktiken/',
  },
  'broad-spectrum-micronutrients-bsms-for-mental-health-what-you-need-to-know-for-clinical-use-2729':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/publication/micronutrientes-de-amplo-espectro-bsms-para-a-saude-mental-o-que-voce-precisa-saber-para-uso-clinico/',
      fr: 'https://fr.psychopharmacologyinstitute.com/publication/micronutriments-a-large-spectre-bsm-pour-la-sante-mentale-ce-quil-faut-savoir-pour-une-utilisation-clinique/',
      it: 'https://it.psychopharmacologyinstitute.com/publication/micronutrienti-ad-ampio-spettro-bsm-per-la-salute-mentale-cosa-ce-da-sapere-per-luso-clinico/',
      de: 'https://de.psychopharmacologyinstitute.com/publication/breitspektrum-mikronaehrstoffe-bsm-fuer-die-psychische-gesundheit-was-sie-fuer-den-klinischen-einsatz-wissen-muessen/',
    },
  'nociplastic-pain-syndromes-focus-on-fibromyalgia-2734': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/sindromes-dolorosas-nociplasticas-foco-na-fibromialgia/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/syndromes-douloureux-nociplinaires-focus-sur-la-fibromyalgie/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/sindromi-dolorose-nociplastiche-focus-sulla-fibromialgia/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/nociplastische-schmerzsyndrome-fokus-auf-fibromyalgie/',
  },
  'clozapine-management-and-challenges-2733': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/clozapina-gerenciamento-e-desafios/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/clozapine-gestion-et-defis/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/clozapina-gestione-e-sfide/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/clozapin-management-und-herausforderungen/',
  },
  'depression-anxiety-and-delirium-in-patients-with-cancer-2724': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/depressao-ansiedade-e-delirium-em-pacientes-com-cancer/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/depression-anxiete-et-delirium-chez-les-patients-atteints-de-cancer/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/depressione-ansia-e-delirium-nei-pazienti-affetti-da-cancro/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/depressionen-angstzustaende-und-delirium-bei-krebspatienten/',
  },
  'tic-disorders-in-children-and-adolescents-2700': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/transtornos-de-tique-em-criancas-e-adolescentes/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/les-troubles-tic-chez-lenfant-et-ladolescent/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/disturbi-da-tic-nei-bambini-e-negli-adolescenti/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/tic-stoerungen-bei-kindern-und-heranwachsenden/',
  },
  'parasomnias-triggered-by-sedative-hypnotics-sleepwalking-and-sleep-related-eating-disorder-2698':
    {
      'pt-br':
        'https://pt.psychopharmacologyinstitute.com/publication/parassonias-desencadeadas-por-sedativos-hipnoticos-sonambulismo-e-transtorno-alimentar-relacionado-ao-sono/',
      fr: 'https://fr.psychopharmacologyinstitute.com/publication/parasomnies-declenchees-par-les-sedatifs-hypnotiques-somnambulisme-et-troubles-de-lalimentation-lies-au-sommeil/',
      it: 'https://it.psychopharmacologyinstitute.com/publication/parasonnie-provocate-da-sedativi-ipnotici-sonnambulismo-e-disturbo-alimentare-correlato-al-sonno/',
      de: 'https://de.psychopharmacologyinstitute.com/publication/durch-sedativa-hypnotika-ausgeloeste-parasomnien-schlafwandeln-und-schlafbezogene-essstoerung/',
    },
  'the-role-of-psychopharmacology-in-autism-spectrum-disorder-2695': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/o-papel-da-psicofarmacologia-no-transtorno-do-espectro-do-autismo/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/le-role-de-la-psychopharmacologie-dans-les-troubles-du-spectre-autistique/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/il-ruolo-della-psicofarmacologia-nel-disturbo-dello-spettro-autistico/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/die-rolle-der-psychopharmakologie-bei-autismus-spektrum-stoerungen/',
  },
  'pharmacologic-management-of-opioid-use-disorder-2807': {
    'pt-br':
      'https://pt.psychopharmacologyinstitute.com/publication/tratamento-farmacologico-do-transtorno-por-uso-de-opioides/',
    fr: 'https://fr.psychopharmacologyinstitute.com/publication/prise-en-charge-pharmacologique-du-trouble-lie-a-lutilisation-dopioides/',
    it: 'https://it.psychopharmacologyinstitute.com/publication/gestione-farmacologica-del-disturbo-da-uso-di-oppioidi/',
    de: 'https://de.psychopharmacologyinstitute.com/publication/pharmakologisches-management-von-opioidkonsumstoerungen/',
  },
};
